import React from 'react';
import useObjectives from '../hooks/useObjectives';
import { Objective } from '../types/objectiveTypes';
import { useStyles } from './FavoritesComponent.styles';
import { Item } from '../types/commonObjIncTypes';
import ItemComponent from '../common/ItemComponent';
import useHandleObjectiveUpdate from '../hooks/useHandleObjectiveUpdate';

export const FavoriteObjectivesComponent: React.FC = () => {
	const classes = useStyles();

	const { favoriteObjectives } = useObjectives();

	const { addObjective, deleteObjective, editObjective } =
		useHandleObjectiveUpdate();

	const handleAdd = async (newData: Omit<Item, '_id'>) => {
		return await addObjective(newData as Omit<Objective, '_id'>);
	};

	const handleEdit = (id: string, updated: Partial<Item>) => {
		editObjective(id, updated as Partial<Objective>);
	};

	const handleDelete = (id: string) => {
		deleteObjective(id);
	};

	const handleFavorite = (id: string) => {
		const item = favoriteObjectives.find((it) => it._id === id);
		if (item) {
			editObjective(id, {
				...item,
				favorite: !item.favorite,
			});
		}
	};

	const handleDuplicate = (id: string) => {
		const item = favoriteObjectives.find((it) => it._id === id);

		if (item) {
			const newName = `${item.name} copy`;
			const { _id: id, customId, users: originalUsers, ...restOfObject } = item;
			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
			}));
			handleAdd({
				...restOfObject,
				name: newName,
				users: initializedUsers,
			});
		}
	};

	return (
		<div className={classes.container}>
			<div className={classes.listWrapper}>
				<ItemComponent
					items={favoriteObjectives}
					itemType="objective"
					showAddButton={false}
					onAdd={handleAdd}
					onEdit={handleEdit}
					onDelete={handleDelete}
					onFavorite={handleFavorite}
					onDuplicate={handleDuplicate}
				/>
			</div>
		</div>
	);
};
