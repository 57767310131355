import { Item } from "../../usersManagement/types/commonObjIncTypes";

/**
 * Converts a status number to a corresponding percentage text.
 * @param {number} status - The status number to convert.
 * @returns {string} - The percentage text corresponding to the status.
 */
export const getStatusDescription = (status: number): string => {
	switch (status) {
		case 0:
			return '0%'; // Assuming 0 represents 0%
		case 1:
			return '25%'; // Assuming 1 represents 25%
		case 2:
			return '50%'; // Assuming 2 represents 50%
		case 3:
			return '75%'; // Assuming 3 represents 75%
		case 4:
			return '100%'; // Assuming 4 represents 100%
		default:
			return 'Unknown'; // For any unknown status
	}
};

export const formatDate = (dateString: string): string => {
	const options: Intl.DateTimeFormatOptions = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		timeZone: 'UTC',
	};

	const date = new Date(dateString);
	return new Intl.DateTimeFormat('en-US', options).format(date);
};


