import { useEffect, useState } from 'react';
import useQuery, { IGeneralQuery } from '../../../hooks/useQuery';
import { objectivesStoreZustand } from '../store/objectivesStore';

const useReportObjectives = () => {
    const { query } = useQuery<IGeneralQuery>();
    const queryWholesalerId = query.wholesalerId;

    const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
    const [secondaryPageSize, setSecondaryPageSize] = useState(10);

    const { objectives, secondaryCurrentPage, setSecondaryCurrentPage } =
        objectivesStoreZustand();

    useEffect(() => {
        if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
            setWholesalerId(queryWholesalerId);
        }
    }, [queryWholesalerId, wholesalerId]);

    useEffect(() => {
        objectivesStoreZustand.getState().fetchObjectivesReportTable({
            wholesalerId,
            page: secondaryCurrentPage,
            limit: secondaryPageSize,
        })
    }, [wholesalerId, secondaryCurrentPage, secondaryPageSize]);

    return {
        objectives,
        secondaryCurrentPage,
        setSecondaryCurrentPage,
        secondaryPageSize,
        setSecondaryPageSize,
        totalPages: objectivesStoreZustand((state) => state.totalPages),
        loading: objectivesStoreZustand((state) => state.loading),
        setLoading: objectivesStoreZustand((state) => state.setLoading)
    };
};

export default useReportObjectives;