export const incentiveReportColumns = [
	{ id: 'userName', label: 'User Name' },
	{ id: 'route', label: 'Route' },
	{ id: 'userType', label: 'User Type' },
	{ id: 'incentive', label: 'Incentive' },
	{ id: 'startDate', label: 'Start Date' },
	{ id: 'endDate', label: 'End Date' },
	{ id: 'subIncentive', label: 'Sub Incentive' },
	{ id: 'type', label: 'Type' },
	{ id: 'quantity', label: 'Quantity' },
	{ id: 'status', label: 'Status' },
	{ id: 'target', label: 'Target' },
	{ id: 'payoutMTD', label: 'Payout MTD' },
	{ id: 'supplier', label: 'Supplier' },
	{ id: 'image', label: 'Image' },
	{ id: 'accounts', label: 'Accounts' },
	{ id: 'products', label: 'Products' },
	{ id: 'geocoding', label: 'Geocoding?' },
];

export const objectiveColumns = [
	{ id: 'userName', label: 'User Name' },
	{ id: 'route', label: 'Route' },
	{ id: 'userType', label: 'User Type' },
	{ id: 'objective', label: 'Objective' },
	{ id: 'startDate', label: 'Start Date' },
	{ id: 'endDate', label: 'End Date' },
	{ id: 'subIncentive', label: 'Sub Incentive' },
	{ id: 'type', label: 'Type' },
	{ id: 'quantity', label: 'Quantity' },
	{ id: 'status', label: 'Status' },
	{ id: 'target', label: 'Target' },
	{ id: 'payoutMTD', label: 'Payout MTD' },
	{ id: 'image', label: 'Image' },
	{ id: 'accounts', label: 'Accounts' },
	{ id: 'products', label: 'Products' },
];

export const objColumns = [
	'User Name',
	'Route',
	'User Type',
	'Objective',
	'Start Date',
	'End Date',
	'Sub Objective',
	'Type',
	'Quantity',
	'Status',
	'Target',
	'Payout',
	'Payout MTD',
	'Image',
	'Accounts',
	'Products',
];

export const incColumns = [
	'User Name',
	'Route',
	'User Type',
	'Incentive',
	'Start Date',
	'End Date',
	'Sub Incentive',
	'Type',
	'Quantity',
	'Status',
	'Target',
	'Payout',
	'Payout MTD',
	'Supplier',
	'Image',
	'Accounts',
	'Products',
];

export interface ITableCell {
	content?: string;
	value?: any;
	tooltip?: string;
}

export type ITableRows = ITableCell[][];

export type ISortDirection = 'asc' | 'desc';
