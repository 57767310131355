import { useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Typography, makeStyles } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import useAccountsItem from '../Item/useAccountsItem';

interface AccountsListProps {
    itemId:string;
    userId:string;
    isObjective:boolean;
}

const useStyles = makeStyles(() => ({
    center: {
        textAlign: 'center',
    },
    list: {
        width: '100%',
    },
    listItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: 10, 
        marginBottom: 10,
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,
    }
}));

export default function AccountsList({ itemId, userId, isObjective }:AccountsListProps) {
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const {
        accounts,
        secondaryLoading,
        currentPage,
        setCurrentPage,
        secondaryTotalPages,
        fetchAccounts,
    } = useAccountsItem(itemId, userId, isObjective);

    const handleClickOpen = () => {
        setOpen(true);
        fetchAccounts();  
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentPage(1);
    };

    const handleChangePage = (_:React.ChangeEvent<unknown>, value:number) => {
        setCurrentPage(value);  
    };

    useEffect(() => {
        if (open) { 
            fetchAccounts();
        }
    }, [currentPage]);
    
    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                className={classes.center}
            >
                See All
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle className={classes.center}>All Accounts</DialogTitle>
                <DialogContent>
                    {secondaryLoading ? (
                        <Typography className={classes.center}>Loading...</Typography>
                    ) : (
                        <>
                            <List className={classes.list}>
                                {accounts.map((buyer) => (
                                    <ListItem key={buyer._id} className={classes.listItem}>
                                        <ListItemText primary={`Name: ${buyer.name}`} />
                                        <ListItemText primary={`Sales Rep: ${buyer.salesRep?.name}`} />
                                    </ListItem>
                                ))}
                            </List>
                            <Pagination
                                className={classes.pagination}
                                count={secondaryTotalPages}
                                page={currentPage}
                                onChange={handleChangePage}
                            />
                        </>
                    )}
                </DialogContent>
                <DialogActions className={classes.center}>
                    <Button onClick={handleClose} color="primary">Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

