import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((_) => ({

    tables: {
        width:'93%',
        display: 'flex',
        flexDirection: 'row',
        gap: '3rem',
    },
    container: {
        width: '100%',
        maxWidth: '300px',
        maxHeight: '250px',
        overflowY: 'auto',
        border: '1px solid #ccc',
        borderRadius: '5px',
    },
    tableHead: {
        backgroundColor: '#D3D3D3'
    },
    tableHeadCell: {
        padding: '0.5rem'
    },
    tableCell: {
        padding: 0
    },
    arrowContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        justifyContent: 'center'
    },
    arrow: {
        cursor: 'pointer'
    }
}));