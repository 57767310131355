import {
	Checkbox,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { useStyles } from './GroupedTable.styles';
import { ObjectiveTypeEnum } from '../../../types/objectiveTypes';
import { useEffect, useState } from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IncentiveTypeEnum } from '../../../types/incentivesTypes';
import { Item } from '../../../types/commonObjIncTypes';

interface GroupedTableProps<T extends Item> {
	itemType: 'objective' | 'incentive';
	items: T[];
	groupedItems?: T[] | null | undefined;
	handleChangeGroupedItems: (updatedGroupedItems: T[]) => void;
}

export const GroupedTable = <T extends Item>({
	itemType,
	items,
	groupedItems,
	handleChangeGroupedItems,
}: GroupedTableProps<T>) => {
	const classes = useStyles();

	const [availableItems, setAvailableItems] = useState<T[]>([]);
	const [selectedLeftItems, setSelectedLeftItems] = useState<string[]>([]);
	const [selectedRightItems, setSelectedRightItems] = useState<string[]>([]);

	useEffect(() => {
		const filteredAvailableItems = items.filter((item) => {
			if (itemType === 'objective') {
				return (
					item.type !== ObjectiveTypeEnum.Grouped &&
					item.type !== ObjectiveTypeEnum.Multi
				);
			} else if (itemType === 'incentive') {
				return (
					item.type !== IncentiveTypeEnum.Grouped &&
					item.type !== IncentiveTypeEnum.Multi
				);
			}
			return false;
		});
		setAvailableItems(filteredAvailableItems);
	}, [items, itemType]);

	const handleLeftCheckboxChange = (itemId: string) => {
		setSelectedLeftItems((prevSelected) => {
			if (prevSelected.includes(itemId)) {
				return prevSelected.filter((id) => id !== itemId);
			} else {
				return [...prevSelected, itemId];
			}
		});
	};

	const handleRightCheckboxChange = (itemId: string) => {
		setSelectedRightItems((prevSelected) => {
			if (prevSelected.includes(itemId)) {
				return prevSelected.filter((id) => id !== itemId);
			} else {
				return [...prevSelected, itemId];
			}
		});
	};

	const handleAddToGroupedItems = () => {
		const selected = availableItems.filter((item) =>
			selectedLeftItems.includes(item._id)
		);
		const updatedSelected = selected.map((item) => ({
			...item,
			isGrouped: true,
		}));
		handleChangeGroupedItems([...(groupedItems || []), ...updatedSelected]);
		setSelectedLeftItems([]);
		const remaining = availableItems.filter(
			(item) => !selectedLeftItems.includes(item._id)
		);
		setAvailableItems(remaining);
	};

	const handleRemoveFromGroupedItems = () => {
		const selectedToRemove = groupedItems.filter((item) =>
			selectedRightItems.includes(item._id)
		);
		const updatedGroupedItems = groupedItems.filter(
			(item) => !selectedRightItems.includes(item._id)
		);
		handleChangeGroupedItems(updatedGroupedItems);
		setSelectedRightItems([]);
		const updatedAvailableItems = availableItems.concat(selectedToRemove);
		setAvailableItems(updatedAvailableItems);
	};

	return (
		<div className={classes.tables}>
			<div className={classes.container}>
				<Table>
					<TableHead className={classes.tableHead}>
						<TableRow>
							<TableCell className={classes.tableHeadCell}></TableCell>
							<TableCell className={classes.tableHeadCell}>
								Available{' '}
								{itemType === 'objective' ? 'objectives' : 'incentives'}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{availableItems.map((item) => (
							<TableRow key={item._id}>
								<TableCell className={classes.tableCell}>
									<Checkbox
										checked={selectedLeftItems.includes(item._id)}
										onChange={() => handleLeftCheckboxChange(item._id)}
									/>
								</TableCell>
								<TableCell className={classes.tableCell}>{item.name}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
			<div className={classes.arrowContainer}>
				<ArrowForwardIcon
					onClick={handleAddToGroupedItems}
					className={classes.arrow}
				/>
				<ArrowBackIcon
					onClick={handleRemoveFromGroupedItems}
					className={classes.arrow}
				/>
			</div>
			<div className={classes.container}>
				<Table>
					<TableHead className={classes.tableHead}>
						<TableRow>
							<TableCell className={classes.tableHeadCell}></TableCell>
							<TableCell className={classes.tableHeadCell}>
								Grouped {itemType === 'objective' ? 'objective' : 'incentive'}
							</TableCell>
							<TableCell className={classes.tableHeadCell}>Weight %</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{groupedItems &&
							groupedItems.map((item) => (
								<TableRow key={item._id}>
									<TableCell className={classes.tableCell}>
										<Checkbox
											checked={selectedRightItems.includes(item._id)}
											onChange={() => handleRightCheckboxChange(item._id)}
										/>
									</TableCell>
									<TableCell className={classes.tableCell}>
										{item.name}
									</TableCell>
									<TableCell className={classes.tableCell}>
										{item.weight}
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</div>
		</div>
	);
};
