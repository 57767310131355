import {
	FormControlLabel,
	FormLabel,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	TextField,
} from "@material-ui/core";
import { useStyles } from "./PayoutComponent.styles";
import { PayoutDetails } from "../utils";
import { useFormContext } from "react-hook-form";

interface PayoutComponentProps {
	payoutDetails: PayoutDetails;
	setPayoutDetails: React.Dispatch<React.SetStateAction<PayoutDetails>>;
}
export const PayoutComponent = ({
	payoutDetails,
	setPayoutDetails,
}: PayoutComponentProps) => {
	const classes = useStyles();
	const { register, errors } = useFormContext();
	const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newPayoutType = event.target.value;
		setPayoutDetails((prev) => ({
			...prev,
			payoutType: newPayoutType,
		}));
	};

	const handleInputChange =
		(field: keyof PayoutDetails | "thresholdValue" | "capValue") =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = parseInt(event.target.value) || 0;

			if (field === "thresholdValue") {
				setPayoutDetails((prev) => ({
					...prev,
					threshold: { ...prev.threshold!, value },
				}));
			} else if (field === "capValue") {
				setPayoutDetails((prev) => ({
					...prev,
					cap: { ...prev.cap!, value },
				}));
			} else {
				setPayoutDetails((prev) => ({
					...prev,
					[field]: value,
				}));
			}
		};

	const handleSelectChange =
		(field: "threshold" | "cap", subField: "units" | "value") =>
		(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
			const value = event.target.value as string;
			setPayoutDetails((prev) => ({
				...prev,
				[field]: { ...prev[field]!, [subField]: value },
			}));
		};
	return (
		<div>
			<FormLabel component="legend" style={{ marginRight: 10, marginTop: 10 }}>
				Payout type:
			</FormLabel>
			<div className={classes.payoutContainer}>
				<div className={classes.left}>
					<RadioGroup
						value={payoutDetails.payoutType}
						onChange={handleRadioChange}
						className={classes.radioGroup}
					>
						<Grid item>
							<FormControlLabel
								value="allOrNothing"
								control={<Radio />}
								label="All Or Nothing"
							/>
						</Grid>
						<Grid item>
							<FormControlLabel
								value="perAction"
								control={<Radio />}
								label="Per Action Amount"
							/>
						</Grid>
						<Grid item>
							<FormControlLabel
								value="atRisk"
								control={<Radio />}
								label="At Risk Amount"
							/>
						</Grid>
					</RadioGroup>
					<div className={classes.textFields}>
						<TextField
							margin="dense"
							variant="outlined"
							id="allOrNothingAmount"
							name="allOrNothingAmount"
							type="number"
							defaultValue={payoutDetails.allOrNothingAmount}
							error={!!errors.allOrNothingAmount}
							helperText={
								errors.allOrNothingAmount && errors.allOrNothingAmount.message
							}
							disabled={payoutDetails.payoutType !== "allOrNothing"}
							onChange={handleInputChange("allOrNothingAmount")}
							inputRef={register({
								validate: {
									positiveNumber: (value) => {
										if (payoutDetails.payoutType === "allOrNothing") {
											return (
												parseInt(value) > 0 || "Value must be greater than 0"
											);
										}
										return true;
									},
								},
							})}
						/>
						<TextField
							id="perActionAmount"
							name="perActionAmount"
							margin="dense"
							variant="outlined"
							type="number"
							defaultValue={payoutDetails.perActionAmount}
							error={!!errors.perActionAmount}
							helperText={
								errors.perActionAmount && errors.perActionAmount.message
							}
							onChange={handleInputChange("perActionAmount")}
							disabled={payoutDetails.payoutType !== "perAction"}
							inputRef={register({
								validate: {
									positiveNumber: (value) => {
										if (payoutDetails.payoutType === "perAction") {
											return (
												parseInt(value) > 0 || "Value must be greater than 0"
											);
										}
										return true;
									},
								},
							})}
						/>
						<TextField
							id="atRiskAmount"
							name="atRiskAmount"
							margin="dense"
							variant="outlined"
							type="number"
							defaultValue={payoutDetails.atRiskAmount}
							error={!!errors.atRiskAmount}
							helperText={errors.atRiskAmount && errors.atRiskAmount.message}
							onChange={handleInputChange("atRiskAmount")}
							disabled={payoutDetails.payoutType !== "atRisk"}
							inputRef={register({
								validate: {
									positiveNumber: (value) => {
										if (payoutDetails.payoutType === "atRisk") {
											return (
												parseInt(value) > 0 || "Value must be greater than 0"
											);
										}
										return true;
									},
								},
							})}
						/>
					</div>
				</div>
				<div className={classes.right}>
					<div className={classes.threshold}>
						<FormLabel component="legend">Treshold:</FormLabel>
						<TextField
							margin="dense"
							id="thresholdValue"
							name="thresholdValue"
							error={!!errors.thresholdValue}
							helperText={
								errors.thresholdValue && errors.thresholdValue.message
							}
							className={classes.inputThreshold}
							variant="outlined"
							type="number"
							disabled={payoutDetails.payoutType === "allOrNothing"}
							defaultValue={payoutDetails.threshold?.value}
							onChange={handleInputChange("thresholdValue")}
							inputRef={register({
								validate: {
									positiveNumber: (value) => {
										if (payoutDetails.payoutType !== "allOrNothing") {
											if (payoutDetails.threshold.units === "percentage") {
												return (
													(parseInt(value) >= 0 && parseInt(value) <= 100) ||
													"Value must be between 0 and 100"
												);
											}
											return (
												parseInt(value) >= 0 ||
												"Value must be greater than or equal to 0"
											);
										}
										return true;
									},
								},
							})}
						/>
						<Select
							value={payoutDetails.threshold?.units}
							onChange={handleSelectChange("threshold", "units")}
							disabled={payoutDetails.payoutType === "allOrNothing"}
							className={classes.select}
						>
							<MenuItem value="raw">Raw</MenuItem>
							<MenuItem value="percentage">%</MenuItem>
						</Select>
					</div>

					<div className={classes.cap}>
						<FormLabel component="legend">Cap:</FormLabel>
						<TextField
							margin="dense"
							id="capValue"
							variant="outlined"
							type="number"
							name="capValue"
							error={!!errors.capValue}
							helperText={errors.capValue && errors.capValue.message}
							className={classes.inputCap}
							defaultValue={payoutDetails.cap?.value}
							onChange={handleInputChange("capValue")}
							disabled={payoutDetails.payoutType === "allOrNothing"}
							inputRef={register({
								validate: {
									positiveNumber: (value) => {
										if (payoutDetails.payoutType !== "allOrNothing") {
											if (payoutDetails.cap.units === "percentage") {
												return (
													(parseInt(value) >= 0 && parseInt(value) <= 100) ||
													"Value must be between 0 and 100"
												);
											}
											return (
												parseInt(value) >= 0 ||
												"Value must be greater than or equal to 0"
											);
										}
										return true;
									},
								},
							})}
						/>
						<Select
							value={payoutDetails.cap?.units}
							onChange={handleSelectChange("cap", "units")}
							disabled={payoutDetails.payoutType === "allOrNothing"}
							className={classes.select}
						>
							<MenuItem value="raw">Raw</MenuItem>
							<MenuItem value="percentage">%</MenuItem>
						</Select>
					</div>
				</div>
			</div>
		</div>
	);
};
