import {
	Item,
	MeasureState,
	measureFields,
} from '../../types/commonObjIncTypes';
import { Incentive } from '../../types/incentivesTypes';
import { Objective } from '../../types/objectiveTypes';

export interface PayoutDetails {
	payout: number;
	payoutType: string;
	allOrNothingAmount: number;
	perActionAmount: number;
	atRiskAmount: number;
	threshold: { units: string; value: number };
	cap: { units: string; value: number };
}

export const getPayoutTypeValue = (
	payoutType: string,
	allOrNothingAmount: number,
	perActionAmount: number,
	atRiskAmount: number
) => {
	switch (payoutType) {
		case 'allOrNothing':
			return allOrNothingAmount;
		case 'perAction':
			return perActionAmount;
		case 'atRisk':
			return atRiskAmount;
		default:
			return 0;
	}
};

export const payoutBasedOnType = (item: Incentive | Objective) => {
	switch (item.payoutType) {
		case 'allOrNothing': {
			return item?.quantityPayout || 0;
		}
		case 'perAction':
			return (item?.quantityPayout || 1) * item.payout;
		case 'atRisk':
			return (item?.quantityPayout || 1) * item.payout;
		default:
			return 0;
	}
};

export const updatedUsers = (selectedUsers: string[], item: Item) => {
	const users = selectedUsers.map((selectedUserId) => {
		// Find the user in the existing users array
		const existingUser = item?.users?.find(
			({ user }) => user === selectedUserId
		);

		// If the user already exists, return their current data
		if (existingUser) {
			return existingUser;
		}

		// If the user is new, return a new user object with default values
		return {
			user: selectedUserId,
			images: [],
			quantity: null,
			validation: null,
			completed: false,
			subObjectives: null,
			goal: null,
		};
	});

	return users;
};

export const initializeMeasures = (
	receivedMeasure: MeasureState | undefined
) => {
	const availableFields = measureFields.map((field) => field.label);
	const measuresInitialState = availableFields.map((field) => {
		const foundMeasure =
			receivedMeasure && receivedMeasure.label === field
				? receivedMeasure
				: null;

		return (
			foundMeasure || {
				label: field,
				startDate: new Date().toISOString(),
				endDate: new Date().toISOString(),
				trackingPeriodStart: new Date().toISOString(),
				trackingPeriodEnd: new Date().toISOString(),
				checked: false,
				symbol:
					field !== undefined && measureFields !== undefined
						? measureFields.find((measure) => measure?.label === field)?.symbol
						: '$',
			}
		);
	});

	return measuresInitialState;
};

export function lowercaseFirstLetter(str: string) {
	return str.charAt(0).toLowerCase() + str.slice(1);
}
