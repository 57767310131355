import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';
import { incentivesStoreZustand } from '../store/incentivesStore';
import { useState } from 'react';
import { Incentive } from '../../usersManagement/types/incentivesTypes';
import useUsers from '../../usersManagement/hooks/useUsers';
import useReportIncentives from './useReportIncentives';
import useArchivedReportIncentives from './useArchivedReportIncentives';
import { ItemsList } from '../Item/ItemsList';
import ItemsManagementReportTable from '../Item/ItemManagementReportTable';

const useStyles = makeStyles((_) => ({
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		overflow: 'hidden',
		maxHeight: '96%',
	},
}));

export const IncentivesManagementSummary = () => {
	const classes = useStyles();
	const { rows, columns, setRows } = incentivesStoreZustand();

	const [innerLoading, setInnerLoading] = useState(false);
	const [showArchived, setShowArchived] = useState(false);

	const {
		incentives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
		totalPages,
		loading,
	} = useReportIncentives();

	const {
		archivedIncentives,
		archivedSecondaryCurrentPage,
		setArchivedSecondaryCurrentPage,
		archivedTotalPages,
		loadingArchived,
	} = useArchivedReportIncentives();

	const { users } = useUsers();
	const [selectedIncentives, setSelectedIncentives] = useState<Incentive[]>([]);

	const [shouldResetFilters, setShouldResetFilters] = useState(false);

	const resetFilters = () => {
		setShouldResetFilters(true);
	};

	const handleIncentiveselect = (selectedIncentives: Incentive[]) => {
		setInnerLoading(true);
		setSelectedIncentives(selectedIncentives);
		let currentRows: any[] = [];

		// Function to process a single incentive asynchronously
		const processIncentive = (incentive, callback) => {
			const allIncentives = incentive.subIncentives
				? [incentive, ...incentive.subIncentives]
				: [incentive];

			allIncentives.forEach((currentIncentive) => {
				if (currentIncentive.users) {
					currentIncentive.users.forEach((user) => {
						const currUser = users.find((u) => u.customId === user.user);
						currentRows.push({
							...user,
							userName: currUser?.name,
							route: currUser?.routeNumber.join(', '),
							userType: currUser?.role,
							incentive: incentive.name,
							subIncentives:
								currentIncentive === incentive
									? 'No Sub Incentives'
									: currentIncentive.name,
							startDate: currentIncentive.customPeriodStart,
							endDate: currentIncentive.customPeriodEnd,
							type: currentIncentive.type,
							quantity: currUser?.totalItems,
							status: user?.percentageAchieved || 0,
							//Target we need to modify for the multi
							payout: user?.totalPayout || 0,
							target: currentIncentive.target,
							image: user.images,
							payoutMTD: user?.totalAchieved || 0,
							supplierContribution: Math.floor(
								(incentive.supplierContribution / 100) *
									currentIncentive.payout || 0
							).toFixed(2),
							accounts: currentIncentive.accounts,
							products: currentIncentive.products,
							//Validation
							valueInput: currentIncentive.valueInput,
							valueInputType: currentIncentive.valueInputType,
							itemId: currentIncentive._id,
							userId: user.user,
							isObjective:false,
						});
					});
				}
			});

			// Use a callback to signal completion of processing for this incentive
			if (callback) callback();
		};

		// Function to process a chunk of incentives
		const processIncentivesChunk = (incentives: Incentive[], index = 0) => {
			if (index >= incentives.length) {
				setRows(currentRows); // Update the state with the new rows after processing
				setInnerLoading(false); // Hide the loading indicator
				return;
			}

			// Process the next incentive in the list
			processIncentive(incentives[index], () => {
				// Move on to the next incentive after a slight delay to allow UI updates
				setTimeout(() => processIncentivesChunk(incentives, index + 1), 0);
			});
		};

		// Start processing the selected incentives
		processIncentivesChunk(selectedIncentives);
	};

	return (
		<Paper className={classes.container}>
			<ItemsList<Incentive>
				itemType="Incentives"
				loading={showArchived ? loadingArchived : loading}
				items={showArchived ? archivedIncentives : incentives}
				currentPage={
					showArchived ? archivedSecondaryCurrentPage : secondaryCurrentPage
				}
				setCurrentPage={
					showArchived
						? setArchivedSecondaryCurrentPage
						: setSecondaryCurrentPage
				}
				totalPages={showArchived ? archivedTotalPages : totalPages}
				onItemSelect={handleIncentiveselect}
				selectedItems={selectedIncentives}
				onFilterChange={resetFilters}
				showArchived={showArchived}
				setShowArchived={setShowArchived}
			/>
			<ItemsManagementReportTable
				itemType="incentive"
				loading={innerLoading}
				columns={columns}
				rows={rows}
				selectedItem={selectedIncentives}
				shouldResetFilters={shouldResetFilters}
				setShouldResetFilters={setShouldResetFilters}
			/>
		</Paper>
	);
};
