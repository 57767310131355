import React, { useEffect, useState } from 'react';
import { Pagination } from '@material-ui/lab';
import usePaginatedCustomers from '../../hooks/usePaginatedCustomers';
import { Incentive } from '../../types/incentivesTypes';
import { Objective } from '../../types/objectiveTypes';
import useAccountTypes from '../../hooks/useAccountTypes';
import {
	Button,
	Checkbox,
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@material-ui/core';
import { SearchBar } from './selectComponent/SearchBar';
import { SelectionControls } from './selectComponent/SelectionControls';

type ManualAccountsSelectionProps = {
	item: Incentive | Objective;
};

export const ManualAccountsSelection: React.FC<
	ManualAccountsSelectionProps
> = ({ item }) => {
	const { accountTypes } = useAccountTypes();

	const [searchTerm, setSearchTerm] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedAccountType, setSelectedAccountType] = useState('');
	const [selectedAccounts, setSelectedAccounts] = useState<
		{ buyerId: string; checked: boolean }[]
	>([]);

	const [premise, setPremise] = useState('');
	const { allBuyers, totalPages, updateAccounts } = usePaginatedCustomers({
		page: currentPage,
		item: item,
		accountType:
			selectedAccountType !== '' && selectedAccountType !== 'All'
				? selectedAccountType
				: undefined,
		premise: premise !== '' ? premise : undefined,
		searchPhrase: searchTerm !== '' ? searchTerm : undefined,
	});
	
	const handleSearchChange = (event: any) => {
		setSearchTerm(event.target.value);
	};

	useEffect(() => {
		const initialSelections = allBuyers.map((buyer) => ({
			buyerId: buyer.customId,
			checked: buyer.isSelected || false,
		}));
		setSelectedAccounts(initialSelections);
	}, [allBuyers]);

	const handleCheck = (account) => {
		setSelectedAccounts((prev) => {
			return prev.map((a) => {
				if (a.buyerId === account.customId) {
					return { ...a, checked: !a.checked };
				}
				return a;
			});
		});
	};

	const handleClear = () => {
		setSelectedAccounts((prev) => prev.map((a) => ({ ...a, checked: false })));
	};

	const handleSelectAll = () => {
		setSelectedAccounts((prev) => prev.map((a) => ({ ...a, checked: true })));
	};

	const handleSave = () => {
		// Prepare data for saving, include all accounts but mark unchecked ones as checked: false
		const accountsToUpdate = selectedAccounts.map((account) => ({
			buyerId: account.buyerId,
			checked: account.checked,
		}));
		updateAccounts(accountsToUpdate);
	};

	const handlePageChange = (event, page: number) => {
		handleSave();
		setCurrentPage(page);
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',
				width: '100%',
			}}
		>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<SearchBar
					value={searchTerm}
					onChange={handleSearchChange}
					height="52"
					placeholder="Search by name"
				/>
				<FormControl variant="outlined" fullWidth style={{ marginTop: 10 }}>
					<InputLabel id="account-type-select-label">Account Type</InputLabel>
					<Select
						labelId="account-type-select-label"
						id="account-type-select"
						value={selectedAccountType}
						onChange={(event) =>
							setSelectedAccountType(event.target.value as string)
						}
						label="Account Type"
					>
						<MenuItem key={'all'} value={'All'}>
							{'All'}
						</MenuItem>
						{accountTypes &&
							accountTypes.map((type) => (
								<MenuItem key={type} value={type}>
									{type}
								</MenuItem>
							))}
					</Select>
				</FormControl>

				<FormControl variant="outlined" fullWidth style={{ marginTop: 10 }}>
					<InputLabel id="premise-select-label">Premise</InputLabel>
					<Select
						labelId="premise-elect-label"
						id="premise-select"
						value={premise}
						onChange={(event) => setPremise(event.target.value as string)}
						label="Premise"
					>
						{['On', 'Off', 'Both'].map((type) => (
							<MenuItem key={type} value={type.toLocaleLowerCase()}>
								{type}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20 }}>
				<SelectionControls
					onSelectAll={handleSelectAll}
					onClear={handleClear}
				/>

				{allBuyers &&
					allBuyers.map((account) => (
						<div
							key={account.customId}
							style={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								justifyContent: 'flex-start',
								marginTop: 20,
							}}
						>
							<Checkbox
								checked={
									selectedAccounts.find((a) => a.buyerId === account.customId)
										?.checked || false
								}
								onChange={() => handleCheck(account)}
								color="primary"
							/>
							<div
								style={{
									marginLeft: 20,
								}}
							>
								<p style={{ margin: '0px' }}>Name: {account.name}</p>

								<p style={{ margin: '0px' }}>{account.accountType}</p>
								<p style={{ margin: '5px 0px 10px 0px' }}>
									Premise: {account.premise}
								</p>
								<Divider />
							</div>
						</div>
					))}

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginTop: '1rem',
					}}
				>
					<Pagination
						count={totalPages}
						page={currentPage}
						onChange={handlePageChange}
						color="primary"
					/>
				</div>
				<Button onClick={handleSave}>SAVE</Button>
			</div>
		</div>
	);
};
