import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	customTable: {
		width: '100%',
	},
	customCell: {
		backgroundColor: 'lightgrey',
		padding: '15px',
	},
	customRow: {
		padding: '5px 10px',
	},
	actionsButtons: {
		display: 'flex',
		justifyContent: 'flex-start',
		flexWrap: 'wrap',
		flex: 1,
		minWidth: '100px',
	},
	customRowName: {
		maxWidth: 100,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	customRowPeriod: {
		maxWidth: '40%',
		width: '40%',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
});
