import React, { useEffect, useState } from "react";
import { Incentive } from "../../types/incentivesTypes";
import { Objective } from "../../types/objectiveTypes";
import ManualProductsList from "./ManualProductsList";
import ManualProductsControls from "./ManualProductsControls";

export interface updateProduct {
	productId: string;
	checked: boolean;
}

type ManualProductsSelectionProps = {
	item: Incentive | Objective;
	isObjective?: boolean;
};

export const ManualProductsSelection: React.FC<
	ManualProductsSelectionProps
> = ({ item }) => {
	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				width: "100%",
			}}
		>
			<div style={{ display: "flex", flexDirection: "column" }}>
				<ManualProductsControls />
			</div>
			<div style={{ display: "flex", flexDirection: "column", marginLeft: 20 }}>
				<ManualProductsList item={item} />
			</div>
		</div>
	);
};
