import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	payoutContainer: {
		display: 'flex',
		flexDirection: 'row',
		gap: '3rem',
		margin: '2rem 0'
	},
	radioGroup: {
		display: 'flex',
		flexDirection: 'column',
		gap: '1rem'
	},
	textFields: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '40%'
	},
	left: {
		display: 'flex',
		flexDirection: 'row',
	},
	right: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
	},
	threshold: {
		display: 'flex',
		flexDirection: 'row',
		gap: '2rem',
		alignItems: 'center',
	},
	cap: {
		display: 'flex',
		flexDirection: 'row',
		gap: '2rem',
		alignItems: 'center',
		marginLeft: '2.2rem',
	},
	select: {
		width: '4rem'
	},
	inputThreshold: {
		maxWidth: '36.5%'
	},
	inputCap: {
		maxWidth: '40%'
	}
});
