import React, { useState } from 'react';
import { FormControl, Typography } from '@mui/material';
import { ProductOptionsButtonSelect } from '../selectComponent/ProductOptionsButtonSelect';
import { ProductTypeButtonSelect } from '../selectComponent/ProductTypeButtonSelect';
import useProducts from '../../../hooks/useProducts';
import { ObjIncProducts } from '../../../types/commonObjIncTypes';
import { lowercaseFirstLetter } from '../utils';
import { useStyles } from './ProductsSelect.styles';
import Modal from '@mui/material/Modal';

export interface ProductsSelectProps {
	objIncProducts: ObjIncProducts;
	onChange: (selectedOptions: ObjIncProducts) => void;
}

export const ProductsSelect: React.FC<ProductsSelectProps> = ({
	objIncProducts,
	onChange,
}) => {
	const [selectedProductType, setSelectedProductType] = useState('');
	const [selectedProductOptions, setSelectedProductOptions] =
		useState<ObjIncProducts>(
			objIncProducts || {
				brands: [],
				packageTypes: [],
				productTypes: [],
				sizes: [],
				suppliers: [],
			}
		);

	const { suppliers, brands, packageTypes, sizes, productTypes } =
		useProducts();

	const handleProductTypeSelected = (type: string) => {
		setSelectedProductType(type);
		setIsModalOpen(true);
	};

	const handleProductOptionsChange = (selectedOptions: ObjIncProducts) => {
		setSelectedProductOptions(selectedOptions);
		onChange(selectedOptions);
	};

	const classes = useStyles();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};


	return (
		<div className={classes.wrapper}>
			<FormControl className={classes.formControlWrapper}>
				<ProductTypeButtonSelect onTypeSelected={handleProductTypeSelected} />
			</FormControl>

			{selectedProductType && (
				<Modal disableAutoFocus open={isModalOpen} onClose={handleCloseModal} className={classes.modal}>
					<div className={classes.paper}>
						<Typography variant="h5" component="h2">
							{selectedProductType}
						</Typography>
						<ProductOptionsButtonSelect
							type={lowercaseFirstLetter(selectedProductType)}
							suppliers={suppliers}
							brands={brands}
							packageTypes={packageTypes}
							sizes={sizes}
							productTypes={productTypes}
							objIncProducts={selectedProductOptions}
							onSelectionChange={handleProductOptionsChange}
							onClose={handleCloseModal}
						/>
					</div>
				</Modal>
			)}
		</div>
	);
};
