import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		marginLeft: '10px',
	},
	formControlWrapper: {
		display: 'flex',
		flexDirection: 'row',
		marginLeft: '10px',
	},
	productsForm: {
		marginRight: '10px',
		minWidth: 120,
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '80%',
		maxWidth: '800px',
		overflowY: 'auto',
		backgroundColor: theme.palette.background.paper,
		// border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	searchContainer: {
		display: 'flex',
		alignItems: 'center',
		margin: '1rem 0',
	},
	searchBar: {
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		marginTop: '10px',
	},
	selectionControls: {
		flexShrink: 0,
		display: 'flex',
		justifyContent: 'center',
	},
}));
