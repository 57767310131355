import { useEffect, useState } from 'react';
import useQuery, { IGeneralQuery } from '../../../hooks/useQuery';
import { incentivesStoreZustand } from '../../objectivesIncentives/store/incentivesStore';

const useIncentives = () => {
	const { query } = useQuery<IGeneralQuery>();

	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [pageSize, setPageSize] = useState(5);

	const loading = incentivesStoreZustand((state) => state.loading);
	
	const currentPage = incentivesStoreZustand((state) => state.currentPage);
	
	const setCurrentPage = incentivesStoreZustand(
		(state) => state.setCurrentPage
	);
	

	const totalPages = incentivesStoreZustand((state) => state.totalPages);
	
	const incentives = incentivesStoreZustand((state) => state.incentives);
	
	const favoriteIncentives = incentivesStoreZustand(
		(state) => state.favoriteIncentives
	);

	useEffect(() => {
		// This effect will only update wholesalerId when queryWholesalerId changes
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		incentivesStoreZustand
			.getState()
			.fetchFavoriteIncentives({ wholesalerId, favorite: true });
	}, [wholesalerId]);

	useEffect(() => {
		const fetchAllSubIncentives = () => {
			const { incentives } = incentivesStoreZustand.getState();
			incentives.forEach((incentive) => {
				if (incentive.type === 'multi') {
					incentivesStoreZustand
						.getState()
						.fetchSubIncentives({ wholesalerId }, incentive._id);
				}
			});
		};

		incentivesStoreZustand
			.getState()
			.fetchIncentives(
				{ wholesalerId, page: currentPage, limit: pageSize },
				fetchAllSubIncentives
			);
	}, [wholesalerId, currentPage, pageSize]);

	return {
		loading,
		incentives,
		currentPage,
		favoriteIncentives,
		setCurrentPage,
		setPageSize,
		totalPages,
	};
};

export default useIncentives;
