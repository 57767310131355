import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    container: {
        padding: '0px',
        margin: 0
    },
    paper: {
        padding: '1rem',
        maxWidth: '600px'
    },
    textFields: {
        display: 'flex',
        flexDirection: 'row',
        gap: '2rem',
    },
    addButton: {
        padding: '1rem 0'
    }
    
}));