import {
	Checkbox,
	FormControlLabel,
	FormLabel,
	TextField,
} from '@material-ui/core';
import { DividerFormControl } from '../../../common/DividerFormControl';
import { useStyles } from '../EditComponent.styles';
import { useFormContext } from 'react-hook-form';

interface TargetComponentProps {
	name?: string;
	target?: number;
	isOpenEnded: boolean;
	onTargetChange: (value: number) => void;
	onIsOpenEndedChange: (checked: boolean) => void;
}

export const TargetComponent = ({
	name = 'Target',
	target,
	isOpenEnded,
	onTargetChange,
	onIsOpenEndedChange,
}: TargetComponentProps) => {
	const classes = useStyles();
	const { register, errors } = useFormContext();
	
	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		onIsOpenEndedChange(event.target.checked);
	};

	const handleTargetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = parseInt(event.target.value);
		onTargetChange(newValue);
	};

	return (
		<DividerFormControl>
			<FormLabel className={classes.spacedFormLabel}>{name}: </FormLabel>
			<TextField
				required
				error={!!errors.target}
				helperText={errors.target && errors.target.message}
				margin="dense"
				variant="outlined"
				label="Units accepted"
				type="number"
				fullWidth
				className={classes.smallTextField}
				name="target"
				defaultValue={target}
				disabled={isOpenEnded}
				onChange={handleTargetChange}
				inputRef={register({
					validate: {
						positiveNumber: value => {
							if (isOpenEnded) {
								return true;
							}
							return parseInt(value) > 0 || "Target must be greater than 0";
						}
					}
				})}
			/>
			<FormControlLabel
				control={
					<Checkbox checked={isOpenEnded} onChange={handleCheckboxChange} />
				}
				label="Open-ended"
			/>
		</DividerFormControl>
	);
};
