import React, { useState } from 'react';
import { Button, Checkbox, ListItemText } from '@mui/material';
import { SearchBar } from './SearchBar';
import { SelectionControls } from './SelectionControls';
import { IUser } from '../../../../../interfaces';
import Pagination from '@mui/material/Pagination';
import {useStyles} from '../accountsProductsUsersSelect/UsersSelect.styles'
import Container from '@mui/material/Container';

interface UserOptionsButtonSelectProps {
	users: IUser[];
	onSelectionChange: (selected: IUser[]) => void;
	initialSelectedUsers: IUser[];
	onClose: () => void;
}

export const UserOptionsButtonSelect: React.FC<
	UserOptionsButtonSelectProps
> = ({ users, onSelectionChange, initialSelectedUsers, onClose }) => {
	const [selectedUsers, setSelectedUsers] =
		useState<IUser[]>(initialSelectedUsers);
	const [searchTerm, setSearchTerm] = useState('');

	const displayedUsers = users.filter((user) =>
		user.name.toLowerCase().includes(searchTerm.toLowerCase())
	);

	const handleUserToggle = (selectedUser: IUser) => {
		setTempSelectedUsers((prev) => {
			const isAlreadySelected = prev.some((user) => user.name === selectedUser.name);
			if (isAlreadySelected) {
				return prev.filter((user) => user.name !== selectedUser.name);
			} else {
				return [...prev, selectedUser];
			}
		});
	};

	const handleSelectAll = () => {
		setTempSelectedUsers(displayedUsers);
	};
	
	const handleClear = () => {
		setTempSelectedUsers([]);
	};

	const classes = useStyles();
	
	const itemsPage = 10;
	const [page,setPage] = useState(1);
	const pageCount = Math.ceil(displayedUsers.length/itemsPage);

	const indexOfLastUser = page * itemsPage;
	const indexOfFirstUser = indexOfLastUser - itemsPage;
	const currentUsers = displayedUsers.slice(indexOfFirstUser, indexOfLastUser);
	const handlePageChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
		setPage(newPage);
	};

	const [tempSelectedUsers, setTempSelectedUsers] = useState<IUser[]>(initialSelectedUsers);

	const handleSave = () => {
		setSelectedUsers([...tempSelectedUsers]);
		onSelectionChange(tempSelectedUsers);
		onClose();
	};

	const handleClose = () => {
		onClose();
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				cursor: 'pointer',
				overflow: 'auto',
				position: 'relative',
				width: '100%',
			}}
		>
			<Container className={classes.searchContainer} style={{display: 'flex', padding: '0'}}>
				<div className={classes.searchBar}>
				<SearchBar
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
				/>
				</div>
				<div className={classes.selectionControls}>
					<SelectionControls onSelectAll={handleSelectAll} onClear={handleClear} />
				</div>

			</Container>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					cursor: 'pointer',
					height: '420px',
					overflow: 'auto',
				}}
			>
				{currentUsers.map((user) => (
					<Button
						key={user.customId}
						style={{
							justifyContent: 'flex-start',
							backgroundColor: tempSelectedUsers.includes(user) ? '#d3d3d3' : '',
							color: 'black',
							textTransform: 'none',
							fontSize: '9px',
							padding: '0px',
							textAlign: 'left',
						}}
						onClick={() => handleUserToggle(user)}
					>
						<Checkbox
							checked={tempSelectedUsers.some(
								(selectedUser) => selectedUser.name === user.name
							)}
						/>
						<ListItemText primary={user.name} />
					</Button>
				))}
			</div>
			<div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
				<Pagination
					count={pageCount}
					page={page}
					onChange={handlePageChange}
					style={{ marginTop: '20px' }}
				/>
			</div>
			<div className={classes.modalButtons}>
					<Button onClick={handleClose} >Close</Button>
					<Button onClick={handleSave} >Save</Button>
				</div>
		</div>
	);
};
