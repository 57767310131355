import { useEffect, useState } from 'react';
import useQuery, { IGeneralQuery } from '../../../hooks/useQuery';
import { incentivesStoreZustand } from '../store/incentivesStore';

const useReportIncentives = () => {
	const { query } = useQuery<IGeneralQuery>();
	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [secondaryPageSize, setSecondaryPageSize] = useState(10);

	const { incentives, archivedIncentives, secondaryCurrentPage, setSecondaryCurrentPage } =
		incentivesStoreZustand();

	useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

	useEffect(() => {
		incentivesStoreZustand.getState().fetchIncentivesReportTable({
			wholesalerId,
			page: secondaryCurrentPage,
			limit: secondaryPageSize,
		});
	}, [wholesalerId, secondaryCurrentPage, secondaryPageSize]);

	return {
		incentives,
		archivedIncentives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
		secondaryPageSize,
		setSecondaryPageSize,
		totalPages: incentivesStoreZustand((state) => state.totalPages),
		loading: incentivesStoreZustand((state) => state.loading),
		setLoading: incentivesStoreZustand((state) => state.setLoading),
	};
};

export default useReportIncentives;
