import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
	formControl: {
		display: 'flex',
		flexDirection: 'row',
		margin: '20px 0',
	},
	dialog: {
		flex: 1,
		marginLeft: '2%',
		height: '80vh',
		overflow: 'scroll',
		boxShadow: `0px 2px 2px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)`,
	},
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		overflow: 'scroll',
	},
	datePicker: {
		width: '55%',
		marginBottom: '20px',
	},
	buttonsWrapper: {
		bottom: 0,
		display: 'flex',
		justifyContent: 'space-between',
	},
	listWrapper: {
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
	},
	objList: {
		width: '50%',
	},
	buttonContainer: {
		width: '50%',
	},
	button: {
		marginBottom: '1rem',
	},
	prizesButtonWrapper: {
		display: 'flex',
		justifyContent: 'flex-start',
	},
	divider: {
		marginTop: '1rem',
		marginBottom: '1rem',
	},
	spacedFormLabel: {
		marginRight: 10,
		marginTop: 10,
	},
	smallTextField: {
		marginRight: 10,
		maxWidth: '50%',
	},
	dialogTitle: {
		textAlign: 'center',
	},
	dialogSubObj: {
		width: '100%',
		height: '100%',
	},
	dialogContentSubObj: {
		display: 'flex',
		flexDirection: 'column',
		width: '900px',
		'@media (max-width: 768px)': {
			width: '100%',
		},
	},
	validationError: {
		color: 'red',
		fontSize: '1rem',
	},
	validationObjContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '2rem',
	},
	validationGroup: {
		display: 'flex',
		flexDirection: 'row',
		gap: '2rem',
	},
});
