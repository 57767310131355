import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Divider } from "@material-ui/core";

interface ManualProductsListItemProps {
	product: any;
	selected?: boolean;
	handleCheck: (productId: string) => void;
}

const useStyles = makeStyles((theme) => ({
	primaryText: {
		whiteSpace: "break-spaces",
		marginLeft: "15px",
	},
	secondaryText: {
		whiteSpace: "break-spaces",
		marginLeft: "20px",
	},
}));

export default function ManualProductsListItem(
	props: ManualProductsListItemProps
) {
	const classes = useStyles();

	const { product } = props;
	return (
		<>
			<ListItem>
				<Checkbox
					checked={props.selected}
					onChange={() => props.handleCheck(props.product._id)}
					color="primary"
				/>
				<ListItemText
					classes={{
						primary: classes.primaryText,
						secondary: classes.secondaryText,
					}}
					primary={`${product?.name}`}
					secondary={`Size: ${product?.size}\nSupplier:${product?.supplier}\nBrand: ${product?.brand}\nPremise: ${product?.premise}\nPackageType: ${product?.packageType}\nTag: ${product?.tags}`}
				/>
			</ListItem>
			<Divider />
		</>
	);
}
