import { useEffect, useState } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { objectivesStoreZustand } from "../../objectivesIncentives/store/objectivesStore";

const useArchivedObjectives = () => {
    const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;
	const [pageSize, setPageSize] = useState(5);

    const loadingArchived = objectivesStoreZustand((state) => state.loadingArchived);
    const archivedCurrentPage = objectivesStoreZustand((state) => state.archivedCurrentPage);
	const setArchivedCurrentPage = objectivesStoreZustand(	
		(state) => state.setArchivedCurrentPage
	);
    const archivedTotalPages = objectivesStoreZustand((state) => state.archivedTotalPages);
    const archivedObjectives = objectivesStoreZustand(
		(state) => state.archivedObjectives
	);

    useEffect(() => {
		objectivesStoreZustand.getState().fetchArchivedObjectives(
			{ wholesalerId, page: archivedCurrentPage, limit: pageSize }
		);
	}, [wholesalerId, archivedCurrentPage, pageSize]);

    return {
		setPageSize,
		archivedObjectives,
		archivedCurrentPage,
		loadingArchived,
		archivedTotalPages,
		setArchivedCurrentPage,
	};
}

export default useArchivedObjectives;