import { Button } from "@material-ui/core";
import { mapRowToColumns } from "./mapRowToColumns";
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import { IBuyer } from "../../../interfaces";

interface ExportCSVButtonProps {
    columns: string[];
    rows: any[];
    calculateTotalPayout: (rows: any[]) => number;
    calculateTotalPayoutMTD: (rows: any[]) => number;
    calculateTotalTarget: (rows: any[]) => number;
}

export const ExportCSVButton = ({ columns, rows, calculateTotalPayout, calculateTotalPayoutMTD, calculateTotalTarget }: ExportCSVButtonProps) => {
    const totalPayout = calculateTotalPayout(rows);
    const totalPayoutMTD = calculateTotalPayoutMTD(rows);
    const totalTarget = calculateTotalTarget(rows);

    const totalRow = ['Total', ...columns.slice(1).map(column => {
        if (column === 'Payout') return totalPayout;
        if (column === 'Payout MTD') return totalPayoutMTD;
         if (column === 'Target') return totalTarget;
         return '';
  })];
  
    const transformedRows = rows.map(row => {
        const transformedRow = { ...row };

        if (columns.includes('Image') && Array.isArray(row.images) && row.images.length > 0) {
            const imageUrls = row.images.map(image => image.imageUrl).join('; ');
            transformedRow.Image = imageUrls;
        } else {
            transformedRow.Image = 'No Image';
        }
        if (columns.includes('Accounts')) {
            if (row.accounts && row.accounts.accountType) {
                
                const accountNames: string[] = [];
                for (const accountType in row.accounts.accountType) {
                    row.accounts.accountType[accountType].forEach((account: IBuyer) => {
                        if (account.name) {
                            accountNames.push(account.name);
                        }
                    });
                }
               
                transformedRow.Accounts = accountNames.join('; ');
            } else {
                transformedRow.Accounts = 'N/A';
            }
        }
        if (columns.includes('Products')) {
            if (row.products && row.products !== null) {
                const productDetails: string[] = [];
                
                for (const [category, values] of Object.entries(row.products)) {
                    const categoryName = category.charAt(0).toUpperCase() + category.slice(1);
                    
                    values.forEach((value: string) => {
                        productDetails.push(`${categoryName}: ${value}`);
                    });
                }

                transformedRow.Products = productDetails.join('; ');
            } else {
                transformedRow.Products = 'N/A';
            }
        }


        return transformedRow;
    });

    const dataWithTotal = [
        ...transformedRows.map(row => {
            return mapRowToColumns(row, columns).map((value, index) => {
                const column = columns[index];
                if (column === 'Image') {
                    return row.Image || 'No Image';
                } else if (column === 'Accounts') {
                    return row.Accounts || 'N/A';
                } else if (column === 'Products') {
                    return row.Products || 'N/A';
                }
                return value;
            });
        }),
        totalRow
    ];    

    return (
        <CSVLink
            data={dataWithTotal}
            headers={columns}
            filename="exported_data.csv"
            className="custom-csv-link"
            target="_blank"
        >
            <Button
                size="small"
                variant='outlined'
                color="primary"
                startIcon={<DownloadIcon />}
            >
                Export CSV
            </Button>
        </CSVLink >
    )
}

