import { useEffect } from "react";
import useQuery, { IPaginatedQuery } from "../../../hooks/useQuery";

import { isIncentive } from "../types/commonObjIncTypes";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";
import { Objective } from "../types/objectiveTypes";
import { Incentive } from "../types/incentivesTypes";

const usePaginatedCustomers = ({
	page,
	item,
	accountType,
	premise,
	searchPhrase,
}: {
	page: number;
	item: Incentive | Objective;
	accountType?: string;
	premise?: string;
	searchPhrase?: string;
}) => {
	const { query } = useQuery<IPaginatedQuery>();
	const { wholesalerId } = query;
	const limit = 7;
	let typeKey: string;

	if (isIncentive(item)) {
		typeKey = "incentiveId";
	} else {
		typeKey = "objectiveId";
	}

	const allBuyers = commonObjIncStoreZustand((state) => state.allBuyers);

	const updateAccounts = (
		newAccounts: { buyerId: string; checked: boolean }[]
	) => {
		commonObjIncStoreZustand
			.getState()
			.updateAccounts(
				!isIncentive(item),
				{ [typeKey]: item?._id, wholesalerId },
				newAccounts
			);
	};
	useEffect(() => {
		commonObjIncStoreZustand.getState().fetchCustomersPaginated({
			searchPhrase,
			accountType,
			premise,
			page,
			limit,
			wholesalerId,
			[typeKey]: item?._id,
			isObjective: !isIncentive(item),
		});
	}, [
		accountType,
		page,
		limit,
		wholesalerId,
		typeKey,
		item?._id,
		premise,
		searchPhrase,
		item,
	]);

	return {
		allBuyers,
		totalPages: commonObjIncStoreZustand.getState().totalPages,
		updateAccounts,
	};
};

export default usePaginatedCustomers;
