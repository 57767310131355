import React from 'react';
import useIncentives from '../../hooks/useIncentives'; // Your custom hook for incentives
import { Item } from '../../types/commonObjIncTypes';
import ItemComponent from '../../common/ItemComponent';
import { Incentive } from '../../types/incentivesTypes';
import useHandleIncentiveUpdate from '../../hooks/useHandleIncentiveUpdate';

export const IncentivesComponent: React.FC = () => {
	const { incentives, setCurrentPage, currentPage, totalPages, loading } =
		useIncentives();

	const { addIncentive, deleteIncentive, editIncentive, runQueryForIncentive } =
		useHandleIncentiveUpdate();

	const handleAddIncentive = async (newIncentiveData: Omit<Item, '_id'>) => {
		return await addIncentive(newIncentiveData as Omit<Incentive, '_id'>);
	};

	const handleEditIncentive = (id: string, updatedIncentive: Partial<Item>) => {
		editIncentive(id, updatedIncentive as Partial<Incentive>);
	};

	const handleDeleteIncentive = (id: string) => {
		deleteIncentive(id);
	};

	const handleFavoriteIncentive = (id: string) => {
		const incentive = incentives.find((incentive) => incentive._id === id);
		if (incentive) {
			editIncentive(id, {
				...incentive,
				favorite: !incentive.favorite,
			});
		}
	};

	const handleDuplicateIncentive = (id: string) => {
		const incentive = incentives.find((incentive) => incentive._id === id);

		if (incentive) {
			const newName = `${incentive.name} copy`;
			const { _id: id, customId, users: originalUsers, ...restOfObject } = incentive;
			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
			}));
			
			handleAddIncentive({
				...restOfObject,
				name: newName,
				users: initializedUsers,
			});
		}
	};

	const handleArchiveIncentive = (id: string) => {
		const incentive = incentives.find((incentive) => incentive._id === id);
		console.log(incentive)
		if (incentive) {
			editIncentive(id, {
				...incentive,
				archived: !incentive.archived,
			});
		}
	}
	const showArchive:boolean = false;
	const handleAction = (id:string) => {
		const incentive = incentives.find((incentive)=> incentive._id===id);
		if (incentive){
			runQueryForIncentive(incentive._id);
		}
	}

	return (
		<ItemComponent
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			totalPages={totalPages}
			items={incentives}
			loading={loading}
			itemType="incentive"
			onAdd={handleAddIncentive}
			onEdit={handleEditIncentive}
			onDelete={handleDeleteIncentive}
			onFavorite={handleFavoriteIncentive}
			onDuplicate={handleDuplicateIncentive}
			onArchive={handleArchiveIncentive}
			showArchive={showArchive}
			onAction={handleAction}
		/>
	);
};
