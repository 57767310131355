export const transformColumnName = (colName: string): string => {
	switch (colName) {
		case 'User Name':
			return 'userName';
		case 'Route':
			return 'route';
		case 'User Type':
			return 'userType';
		case 'Objective':
			return 'objective';
		case 'Incentive':
			return 'incentive';
		case 'Sub Incentive':
			return 'subIncentive';
		case 'Start Date':
			return 'startDate';
		case 'End Date':
			return 'endDate';
		case 'Sub Objective':
			return 'subObjectives';
		case 'Quantity':
			return 'quantity';
		case 'Status':
			return 'status';
		case 'Target':
			return 'target';
		case 'Payout MTD':
			return 'payoutMTD';
		case 'Image':
			return 'image';
		case 'Accounts':
			return 'accounts';
		case 'Products':
			return 'products';
		case 'Supplier':
			return 'supplierContribution';
		case 'Payout':
			return 'payout';
		default:
			return colName;
	}
};

export const determineColumnsToShowObjective = (rows: any[]) => {
	const uniqueTypes = new Set(rows.map((row) => row.type));

	if (uniqueTypes.size === 1) {
		const type = uniqueTypes.values().next().value;
		switch (type) {
			case 'image':
				return [
					'Objective',
					'User Name',
					'Route',
					'User Type',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
					'Image',
				];
			case 'binary':
				return [
					'Objective',
					'User Name',
					'Route',
					'User Type',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
				];
			case 'quantity':
				return [
					'Objective',
					'User Name',
					'Route',
					'User Type',
					'Quantity',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
					'Image',
				];
			case 'validation':
				return [
					'Objective',
					'User Name',
					'Route',
					'User Type',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
					'Input Value Type',
					'Input Value',
				];
			case 'grouped':
				return [
					'Objective',
					'User Name',
					'Route',
					'User Type',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
				];
			case 'multi':
				return [
					'Objective',
					'User Name',
					'Route',
					'User Type',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
				];
			default:
				return [];
		}
	} else {
		return [
			'Objective',
			'User Name',
			'Route',
			'User Type',
			'Objective',
			'Start Date',
			'End Date',
			'Sub Objective',
			'Type',
			'Quantity',
			'Status',
			'Target',
			'Payout',
			'Payout MTD',
			'Image',
			'Accounts',
			'Products',
		];
	}
};

export const determineColumnsToShowIncentive = (rows: any[]) => {
	const uniqueTypes = new Set(rows.map((row) => row.type));

	if (uniqueTypes.size === 1) {
		const type = uniqueTypes.values().next().value;
		switch (type) {
			case 'image':
				return [
					'Incentive',
					'User Name',
					'Route',
					'User Type',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
					'Image',
				];
			case 'binary':
				return [
					'Incentive',
					'User Name',
					'Route',
					'User Type',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
				];
			case 'quantity':
				return [
					'Incentive',
					'User Name',
					'Route',
					'User Type',
					'Quantity',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
				];
			case 'validation':
				return [
					'Incentive',
					'User Name',
					'Route',
					'User Type',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
					'Input Value Type',
					'Input Value',
				];
			case 'grouped':
				return [
					'Incentive',
					'User Name',
					'Route',
					'User Type',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
				];
			case 'multi':
				return [
					'Incentive',
					'User Name',
					'Route',
					'User Type',
					'Status',
					'Target',
					'Payout',
					'Payout MTD',
				];
			default:
				return [];
		}
	} else {
		return [
			'Incentive',
			'User Name',
			'Route',
			'User Type',
			'Incentive',
			'Start Date',
			'End Date',
			'Sub Incentive',
			'Type',
			'Quantity',
			'Status',
			'Target',
			'Payout',
			'Payout MTD',
			'Supplier',
			'Image',
			'Accounts',
			'Products',
		];
	}
};
