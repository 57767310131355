import React, { useEffect, useState } from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	DialogContent,
	Divider,
} from "@material-ui/core";
import {
	AdvancedPayment,
	MeasureState,
	Objective,
	ObjectiveTypeEnum,
	ObjectiveUser,
} from "../../types/objectiveTypes";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { UsersSelect, ProductsSelect } from "./accountsProductsUsersSelect";
import { capitalizeFirstLetter } from "../../utils";
import { useStyles } from "./EditComponent.styles";
import { SubItemList } from "../itemsList/SubItemList";
import { EditSubItemComponent } from "./EditSubItemComponent";
import { AddNewSubItemComponent } from "../addObjectiveComponents/addNewSubItem";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
	CustomObjIncProductFilters,
	Item,
	MeasureSymbol,
	ObjIncProducts,
	SemiItem,
	initialSubObj,
} from "../../types/commonObjIncTypes";
import { QuantityForm } from "../quantityObjective/QuantityForm";
import { DividerFormControl } from "../../common/DividerFormControl";
import { TargetComponent } from "./targetComponent/TargetComponent";
import { PayoutComponent } from "./payoutComponents/PayoutComponent";
import {
	PayoutDetails,
	getPayoutTypeValue,
	initializeMeasures,
	updatedUsers,
} from "./utils";
import { NameDescriptionPeriodComponent } from "./nameDescriptionPeriod/NameDescriptionPeriodComponent";
import useItemValidation from "./useItemValidation";
import useHandleObjectiveUpdate from "../../hooks/useHandleObjectiveUpdate";
import { GroupedTable } from "./groupedObjective/GroupedTable";
import { AdvancedPaymentComponent } from "./paymentAdvancedComponent/AdvancedPaymentComponent";
import { objectivesStoreZustand } from "../../../objectivesIncentives/store/objectivesStore";
import { ValueInput } from "./valueObjective/ValueInputComponent";
import { ManualAccountsSelection } from "./ManualAccountsSelection";
import { CustomAccountsSelection } from "./CustomAccountsSelection";
import { MultiPayoutComponent } from "./payoutComponents/MultiPayoutComponent";
import SelectionRadioButtons from "./RadioSelectionAccounts";
import { CustomProductsSelection } from "./CustomProductsSelection";
import { ManualProductsSelection } from "./ManualProductsSelection";
import { FormProvider, useForm } from "react-hook-form";

interface EditObjectiveComponentProps {
	initialItemState: Item;
	onSave: (item: Item) => void;
	onClose: () => void;
}

const EditObjectiveComponent: React.FC<EditObjectiveComponentProps> = ({
	initialItemState,
	onSave,
	onClose,
}) => {
	const classes = useStyles();
	const methods = useForm();
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const [objective, setObjective] = useState<Objective>(
		initialItemState as Objective
	);

	const [selectionType, setSelectionType] = useState("custom");
	const [selectionTypeProd, setSelectionTypeProd] = useState("custom");
	const { addSubObjective, deleteSubObjective, editSubObjective } =
		useHandleObjectiveUpdate();

	const objectives = objectivesStoreZustand((state) => state.objectives);

	const [objectives2, setObjectives] = useState<Objective[]>([]);

	useEffect(() => {
		setObjectives(objectives);
	}, [objectives]);

	const [open, setOpen] = useState(false);

	const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>({
		payout: objective?.payout || 0,
		payoutType: objective?.payoutType || "perAction",
		allOrNothingAmount: objective?.quantityPayout || 0,
		perActionAmount: objective?.quantityPayout || 0,
		atRiskAmount: objective?.quantityPayout || 0,
		threshold: objective?.threshold || { units: "percentage", value: 0 },
		cap: objective?.cap || { units: "percentage", value: 100 },
	});

	const [selectedProductsFilters, setSelectedProductsFilters] = useState<CustomObjIncProductFilters>(
		initialItemState.productsFilters || {
			supplier: [],
			brand: [],
			packageType: [],
			premise: [],
			size: [],
			productType: [],
		}
	);

	const [measures, setMeasures] = useState(
		initializeMeasures(initialItemState.measure)
	);
	const [valueInputType, setValueInputType] = useState(
		objective.valueInputType || "gt"
	);
	const [valueInput, setValueInput] = useState(objective.valueInput);

	const handleValueInputChange = (name: string, value: string | number) => {
		if (name === "valueInputType") {
			setValueInputType(value as string);
			setObjective((prev) => ({
				...prev,
				valueInputType: value as string,
			}));
		} else if (name === "valueInput") {
			setValueInput(value as number);
			setObjective((prev) => ({
				...prev,
				valueInput: value as number,
			}));
		}
	};

	const [groupedObjectives, setGroupedObjectives] = useState<Objective[]>(
		objective.groupedObjectives || []
	);

	const handleChangeGroupedObjectives = (
		updatedGroupedObjectives: Objective[]
	) => {
		setGroupedObjectives(updatedGroupedObjectives);
		setObjective((prev) => ({
			...prev,
			groupedObjectives: updatedGroupedObjectives,
		}));
	};

	const [advancedPayments, setAdvancedPayments] = useState<AdvancedPayment[]>([
		{ completed: 0, ofBudget: 0 },
	]);

	const handleAdvancedPaymentsChange = (updatedPayments: AdvancedPayment[]) => {
		setAdvancedPayments(updatedPayments);
		setObjective((prev) => ({
			...prev,
			advancedPayments: updatedPayments,
		}));
	};

	const { areAdvancedPaymentsValid, isFormValid } = useItemValidation(
		objective,
		advancedPayments,
		objective.subObjectives
	);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const [selectedUsers, setSelectedUsers] = useState<string[]>(
		objective?.users ? objective?.users?.map((user) => user.user) : []
	);

	const [selectedSubObjective, setSelectedSubObjective] = useState<Objective>();
	const [isEditSubObjectiveModalOpen, setIsEditSubObjectiveModalOpen] =
		useState(false);

	const handleMeasureChange = (
		label: string,
		checked: boolean,
		startDate?: string,
		endDate?: string,
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		symbol?: MeasureSymbol
	) => {
		setMeasures((prevMeasures) =>
			prevMeasures.map((measure) =>
				measure.label === label
					? {
						...measure,
						startDate,
						endDate,
						trackingPeriodStart,
						trackingPeriodEnd,
						checked,
						symbol,
					}
					: { ...measure, checked: false }
			)
		);
	};

	const handleUserSelectionChange = (selectedUsers: string[]) => {
		setSelectedUsers(selectedUsers);

		setObjective((prev) => ({
			...prev,
			users: updatedUsers(selectedUsers, objective) as ObjectiveUser[],
		}));
	};

	const handleChange = (name: string, value: string | number) => {
		setObjective((prev) => {
			const updatedObjective = { ...prev, [name]: value };
			return updatedObjective;
		});
	};

	const getMeasuresForSave = (measures: MeasureState[]) => {
		return measures.filter((measure: MeasureState) => measure.checked)[0];
	};

	const handleEditSubObjective = (subObjectiveId: string) => {
		if (!objective?.subObjectives) return;

		const subObjective = objective.subObjectives?.find(
			(it) => it._id === subObjectiveId
		);
		if (!subObjective) return;

		setSelectedSubObjective(subObjective);
		setIsEditSubObjectiveModalOpen(true); // Open the modal
	};

	const handleAddSubObjective = async (newSubObjectiveData: SemiItem, parentObjectiveId: string) => {
		newSubObjectiveData.customPeriodStart = objective.customPeriodStart;
		newSubObjectiveData.customPeriodEnd = objective.customPeriodEnd;

		const subObj = await addSubObjective(
			{ ...newSubObjectiveData, parentObjectiveId } as Omit<Objective, "_id">,
			parentObjectiveId
		);

		setObjective((prev) => {
			const subObjectives = prev.subObjectives || [];
			const exists = subObjectives.some(sub => sub._id === subObj._id);

			if (exists) {
				return prev;
			}

			return {
				...prev,
				subObjectives: [...subObjectives, subObj],
			};
		});

		setSelectedSubObjective(subObj);
		setIsEditSubObjectiveModalOpen(true);
	};


	const handleDuplicateSubObjective = (
		id: string,
		parentObjectiveId: string
	) => {
		const subObjective = objective.subObjectives?.find((it) => it._id === id);
		if (subObjective) {
			const newName = `${subObjective.name} copy`;
			const { _id: objId, customId, ...restOfSubObject } = subObjective;
			handleAddSubObjective(
				{
					...restOfSubObject,
					name: newName,
				},
				parentObjectiveId
			);
		}
	};

	const handleDeleteSubObjective = async (id: string, parentId: string) => {
		const updatedObjective = await deleteSubObjective(id, parentId);
		setObjective(updatedObjective);
	};

	const handleObjectiveSubmit = () => {
		if (!isFormValid()) {
			setIsFormSubmitted(true);
			return;
		}

		const transformedUsers = selectedUsers.map((selectedUserId) => {
			// Find the user in the existing users array
			const existingUser = objective?.users?.find(
				({ user }) => user === selectedUserId
			);

			if (existingUser) {
				return existingUser;
			}

			// If the user is new, return a new user object with default values
			return {
				user: selectedUserId,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
			};
		});

		let updatedObjective: Objective = {
			...objective,
			users: transformedUsers,
			payoutType: payoutDetails.payoutType,
			subObjectives: objective.subObjectives,
			threshold: payoutDetails.threshold,
			cap: payoutDetails.cap,
			payout: payoutDetails.payout,
			advancedPayments: advancedPayments,
			groupedObjectives: groupedObjectives,
			valueInputType: valueInputType,
			valueInput: valueInput,
			isValid: true,
			productsFilters: selectedProductsFilters,
		};

		if (objective.type === ObjectiveTypeEnum.Quantity) {
			updatedObjective = {
				...updatedObjective,
				measure: getMeasuresForSave(measures),
			};
		}
		if (objective.type !== ObjectiveTypeEnum.Multi) {
			updatedObjective = {
				...updatedObjective,
				quantityPayout:
					getPayoutTypeValue(
						payoutDetails.payoutType,
						payoutDetails.allOrNothingAmount,
						payoutDetails.perActionAmount,
						payoutDetails.atRiskAmount
					) || 0,
			};
		}

		if (objective.type === ObjectiveTypeEnum.Multi) {
			updatedObjective.subObjectives?.forEach((subObjective) => {
				const hasChanges =
					updatedObjective.customPeriodStart !==
					subObjective.customPeriodStart ||
					updatedObjective.customPeriodEnd !== subObjective.customPeriodEnd ||
					JSON.stringify(updatedObjective.users) !==
					JSON.stringify(subObjective.users);

				if (hasChanges) {
					subObjective.customPeriodStart = updatedObjective.customPeriodStart;
					subObjective.customPeriodEnd = updatedObjective.customPeriodEnd;
					subObjective.users = updatedObjective.users;

					if (subObjective._id) {
						editSubObjective(
							subObjective._id,
							objective._id,
							subObjective,
							true
						);
					}
				}
			});
		}

		setIsFormSubmitted(false);
		onSave(updatedObjective);
	};

	const handleSaveSubObjective = (editedSubObjective: Objective) => {
		const subObjectiveId = editedSubObjective._id;

		const updatedSubObjectives = objective.subObjectives?.map((subObj) =>
			subObj._id === editedSubObjective._id ? editedSubObjective : subObj
		);

		setObjective((prevObjective) => ({
			...prevObjective,
			subObjectives: updatedSubObjectives,
		}));

		editSubObjective(subObjectiveId, objective._id, editedSubObjective, false);

		setIsEditSubObjectiveModalOpen(false);
	};

	const handleTargetChange = (value: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			payout: value,
		}));
	};

	const handleIsOpenEndedChange = (checked: boolean) => {
		setObjective((prevObjective) => ({
			...prevObjective,
			isOpenEnded: checked,
		}));
	};

	const handleMultiPayoutChange = (quantityPayout: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			quantityPayout: quantityPayout,
		}));

		setObjective((prev) => ({
			...prev,
			quantityPayout: quantityPayout,
		}));
	};
	if (initialItemState === undefined) return null;

	return (
		<FormProvider {...methods}>
			<div>
				<DialogContent className={classes.dialogContent}>
					<p>
						<b>{capitalizeFirstLetter(objective.type)} Objective</b>
					</p>
					<NameDescriptionPeriodComponent
						item={objective}
						handleChange={handleChange}
					/>
					{objective.type === ObjectiveTypeEnum.Grouped && (
						<GroupedTable
							itemType="objective"
							items={objectives2}
							groupedItems={objective.groupedObjectives}
							handleChangeGroupedItems={handleChangeGroupedObjectives}
						/>
					)}
					{objective.type !== ObjectiveTypeEnum.Multi && (
						<>
							<TargetComponent
								name={"Target"}
								target={payoutDetails?.payout || 0}
								isOpenEnded={objective.isOpenEnded}
								onTargetChange={handleTargetChange}
								onIsOpenEndedChange={handleIsOpenEndedChange}
							/>
						</>
					)}
					{objective.type === ObjectiveTypeEnum.Multi && (
						<>
							<MultiPayoutComponent
								subItems={objective.subObjectives}
								onPayoutChange={handleMultiPayoutChange}
							/>
						</>
					)}
					{objective.type === ObjectiveTypeEnum.Quantity && (
						<QuantityForm
							measures={measures}
							handleMeasureChange={handleMeasureChange}
						/>
					)}
					{objective.type !== ObjectiveTypeEnum.Multi && (
						<>
							<Divider className={classes.divider} />
							<div style={{ display: "flex", flexDirection: "column" }}>
								<PayoutComponent
									payoutDetails={payoutDetails}
									setPayoutDetails={setPayoutDetails}
								/>
								{objective.type === ObjectiveTypeEnum.Grouped && (
									<>
										<AdvancedPaymentComponent
											advancedPayments={advancedPayments}
											onAdvancedPaymentsChange={handleAdvancedPaymentsChange}
										/>
										{isFormSubmitted && !areAdvancedPaymentsValid && (
											<span className={classes.validationError}>
												Values must be greater than the last one
											</span>
										)}
									</>
								)}
							</div>
						</>
					)}

					{objective.type === ObjectiveTypeEnum.Validation && (
						<DividerFormControl>
							<div className={classes.validationObjContainer}>
								<div>
									<ValueInput
										valueInputType={objective.valueInputType}
										valueInput={objective.valueInput}
										handleChange={handleValueInputChange}
									/>
								</div>
							</div>
						</DividerFormControl>
					)}

					{objective.type !== ObjectiveTypeEnum.Multi && (
						<>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1-content"
									id="panel1-header"
									style={{ fontSize: 16 }}
								>
									Accounts:
								</AccordionSummary>
								<AccordionDetails
									style={{ display: "flex", flexDirection: "column" }}
								>
									<SelectionRadioButtons
										selectionType={selectionType}
										setSelectionType={(type) => setSelectionType(type)}
									/>

									{selectionType === "custom" && (
										<CustomAccountsSelection item={initialItemState} />
									)}
									{selectionType === "manual" && (
										<ManualAccountsSelection item={initialItemState} />
									)}
								</AccordionDetails>
							</Accordion>

							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1-content"
									id="panel1-header"
									style={{ fontSize: 16 }}
								>
									Products:
								</AccordionSummary>
								<AccordionDetails
									style={{ display: "flex", flexDirection: "column" }}
								>
									<SelectionRadioButtons
										selectionType={selectionTypeProd}
										setSelectionType={(type) => setSelectionTypeProd(type)}
									/>
									{selectionTypeProd === "custom" && (
										<CustomProductsSelection
											selectedProductsFilters={selectedProductsFilters}
											setSelectedProductsFilters={setSelectedProductsFilters}
										/>
									)}
									{selectionTypeProd === "manual" && (
										<ManualProductsSelection
											item={initialItemState}
											isObjective={true}
										/>
									)}
								</AccordionDetails>
							</Accordion>
						</>
					)}
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							aria-controls="panel1-content"
							id="panel1-header"
							style={{ fontSize: 16 }}
						>
							Users:
						</AccordionSummary>
						<AccordionDetails>
							<UsersSelect
								initialSelectedUserIds={selectedUsers}
								onChange={handleUserSelectionChange}
							/>
						</AccordionDetails>
					</Accordion>

					{objective.type === "multi" && (
						<>
							<div className={classes.buttonContainer}>
								<Button
									className={classes.button}
									color="primary"
									startIcon={<AddCircleOutlineIcon />}
									onClick={handleClickOpen}
								>
									New Sub-Objective
								</Button>
							</div>

							<AddNewSubItemComponent
								open={open}
								initState={{ ...initialSubObj }}
								itemType="objective"
								onSave={(item: SemiItem, parentItemId: string) =>
									handleAddSubObjective(
										item as Omit<Objective, "_id">,
										parentItemId
									)
								}
								onClose={() => setOpen(false)}
								parentItemId={objective._id}
							/>
							<SubItemList
								subItems={objective.subObjectives as Objective[]}
								onEdit={handleEditSubObjective}
								onDuplicate={handleDuplicateSubObjective}
								onDelete={handleDeleteSubObjective}
							/>
						</>
					)}
					{isEditSubObjectiveModalOpen && selectedSubObjective && (
						<EditSubItemComponent
							initialSubItem={selectedSubObjective as Objective}
							open={true}
							onSave={handleSaveSubObjective}
							onClose={() => setIsEditSubObjectiveModalOpen(false)}
							itemType={"objective"}
						/>
					)}
				</DialogContent>
				<div className={classes.buttonsWrapper}>
					<Button onClick={onClose} color="primary">
						Cancel
					</Button>
					<Button
						type="submit"
						onClick={methods.handleSubmit(handleObjectiveSubmit)}
						color="primary"
					>
						Save
					</Button>
				</div>
			</div>
		</FormProvider>
	);
};

export default EditObjectiveComponent;
