import React from 'react';
import useObjectives from '../../hooks/useObjectives'; // Your custom hook for objectives
import { Objective } from '../../types/objectiveTypes'; // Import your Objective and GenericItem types
import { Item } from '../../types/commonObjIncTypes';
import ItemComponent from '../../common/ItemComponent';
import useHandleObjectiveUpdate from '../../hooks/useHandleObjectiveUpdate';

export const ObjectivesComponent: React.FC = () => {
	const { objectives, currentPage, setCurrentPage, totalPages, loading } =
		useObjectives();
	const { addObjective, deleteObjective, editObjective, runQueryForObjective } =
		useHandleObjectiveUpdate();

	const handleAddObjective = async (newObjectiveData: Omit<Item, '_id'>) => {
		return await addObjective(newObjectiveData as Omit<Objective, '_id'>);
	};

	const handleEditObjective = (id: string, updatedObjective: Partial<Item>) => {
		editObjective(id, updatedObjective as Partial<Objective>);
	};

	const handleDeleteObjective = (id: string) => {
		deleteObjective(id);
	};

	const handleFavoriteObjective = (id: string) => {
		const objective = objectives.find((objective) => objective._id === id);
		if (objective) {
			editObjective(id, {
				...objective,
				favorite: !objective.favorite,
			});
		}
	};

	const handleDuplicateObjective = (id: string) => {
		const objective = objectives.find((objective) => objective._id === id);

		if (objective) {
			const newName = `${objective.name} copy`;
			const { _id: id, customId, users: originalUsers, ...restOfObject } = objective;
			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
			}));

			handleAddObjective({
				...restOfObject,
				name: newName,
				users: initializedUsers,
			});
		}
	};

	const handleArchiveObjective = (id: string) => {
		const objective = objectives.find((objective) => objective._id === id);
		
		if (objective) {
			editObjective(id, {
				...objective,
				archived: !objective.archived,
			});
		}
	}

	const showArchive:boolean = false;

	const handleAction = (id:string) => {
		const objective = objectives.find((objective)=> objective._id===id);
		if (objective){
			runQueryForObjective(objective._id)
		}
	}

	return (
		<ItemComponent
			currentPage={currentPage}
			setCurrentPage={setCurrentPage}
			totalPages={totalPages}
			loading={loading}
			items={objectives}
			itemType="objective"
			onAdd={handleAddObjective}
			onEdit={handleEditObjective}
			onDelete={handleDeleteObjective}
			onFavorite={handleFavoriteObjective}
			onDuplicate={handleDuplicateObjective}
			onArchive={handleArchiveObjective}
			showArchive={showArchive}
			onAction={handleAction}
		/>
	);
};
