import {
	ObjIncentiveImages,
	ObjIncProducts,
	MeasureState,
	CustomObjIncProductFilters,
} from './commonObjIncTypes';

export type IncentiveUser = {
	user: string;
	images: ObjIncentiveImages | [];
	quantity: number | null;
	validation: boolean | null;
	completed: boolean;
	subIncentives: null | any[];
	goal: number | null;
	progress?: number;
	payout?: number;
	totalPods: number;
};

export type RankingPrize = {
	prizeName: string;
	prizeDescription?: string;
	prizeImage?: string;
	prizeRank: number;
};

export type Incentive = {
	_id: string;
	name: string;
	type: string;
	description?: string;
	period?: string;
	customPeriodStart?: string;
	customPeriodEnd?: string;
	payout: number;
	supplierContribution: number;
	status?: number;
	accountsFilters?: {
		accountType?: string[];
		premise?: string[];
	};
	selectAllAccounts?: boolean;
	buyerIds?: string[];
	productsFilters?: CustomObjIncProductFilters;
	products?: ObjIncProducts;
	users?: IncentiveUser[];
	favorite?: boolean;
	archived?: boolean;
	customId?: string;
	price?: number;
	earned?: number;
	progress?: number;
	available?: string;
	quantity?: {
		achieved: number;
		target: number;
	};
	rankingPrizes: RankingPrize[];
	isSubIncentive?: boolean;
	parentIncentiveId?: string;
	measure?: MeasureState;
	isOpenEnded: boolean;
	threshold?: { units: string; value: number };
	cap?: { units: string; value: number };
	payoutType?: string;
	quantityPayout?: number;
	images?: ObjIncentiveImages;
	hasCheck?: boolean;
	multi?: boolean;
	subIncentives?: Incentive[];
	expanded: boolean;
	isGrouped?: boolean;
	groupedIncentives?: Incentive[];
	weight?: number;
	valueInputType?: string;
	valueInput?: number;
	isValid: boolean;
};

export enum IncentiveTypeEnum {
	Image = 'image',
	Binary = 'binary',
	Validation = 'validation',
	Quantity = 'quantity',
	Multi = 'multi',
	Grouped = 'grouped',
}

export const incentiveTypes: { value: IncentiveTypeEnum; label: string }[] = [
	{ value: IncentiveTypeEnum.Image, label: 'Image Incentive' },
	{ value: IncentiveTypeEnum.Binary, label: 'Binary Incentive' },
	{ value: IncentiveTypeEnum.Quantity, label: 'Quantity Incentive' },
	{ value: IncentiveTypeEnum.Multi, label: 'Multi Incentive' },
	{ value: IncentiveTypeEnum.Grouped, label: 'Grouped Incentive' },
	{ value: IncentiveTypeEnum.Validation, label: 'Validation Incentive' },
];

export const subIncentiveTypes: { value: IncentiveTypeEnum; label: string }[] =
	[
		{ value: IncentiveTypeEnum.Image, label: 'Image Incentive' },
		{ value: IncentiveTypeEnum.Binary, label: 'Binary Incentive' },
		{ value: IncentiveTypeEnum.Quantity, label: 'Quantity Incentive' },
	];
