import { create } from "zustand";
import api from "../../../utils/api";
import {
	IPaginatedQuery,
	IProductsPaginatedQuery,
	IQueryFavorite,
} from "../../../hooks/useQuery";
import { IBuyer } from "../../../interfaces";
import { Accounts } from "./objectivesStore";

interface Store {
	accounts: Accounts[];
	totalPages?: number;
	allBuyers: IBuyer[];
	allProducts: any[];
	totalProdPages?: number;
	currentPage: number;
	secondaryTotalPages: number;
	secondaryLoading: boolean;
	setCurrentPage: (page: number) => void;
	fetchCustomersPaginated: (query: IPaginatedQuery) => void;
	fetchProductsPaginated: (query: IProductsPaginatedQuery) => void;
	setSecondaryLoading: (secondaryLoading: boolean) => void;
	updateAccounts: (
		isObjective: boolean,
		query: any,
		newAccounts: { buyerId: string; checked: boolean }[]
	) => void;
	updateProducts: (
		isObjective: boolean,
		query: any,
		newProducts: { productId: string; checked: boolean }[]
	) => void;
	fetchAccounts: (
		query: IQueryFavorite,
		itemId: string,
		userId: string,
		isObjective?: boolean,
		callback?: () => void
	) => void;
}

export const commonObjIncStoreZustand = create<Store>((set) => ({
	allBuyers: [],
	allProducts: [],
	totalProdPages: 1,
	accounts: [],
	currentPage: 1,
	secondaryTotalPages: 1,
	secondaryLoading: false,
	setSecondaryLoading: (secondaryLoading: boolean) => set({ secondaryLoading }),
	setCurrentPage: (page: number) => set({ currentPage: page }),
	fetchCustomersPaginated: async (query: IPaginatedQuery) => {
		let res;
		if (query.isObjective) {
			res = await api.fetch({
				path: `/api/v2/objective/${query.objectiveId}/buyers`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `/api/v2/incentive/${query.incentiveId}/buyers`,
				method: "GET",
				query,
			});
		}

		if (res.ok === true) {
			set({
				allBuyers: res.payload,
				totalPages: res.meta?.totalPages,
			});
		} else {
			console.log("error fetching users");
			set({ allBuyers: [] });
		}
	},
	fetchProductsPaginated: async (query: IProductsPaginatedQuery) => {
		let res;
		if (query.isObjective) {
			res = await api.fetch({
				path: `/api/v2/objective/${query.objectiveId}/products`,
				method: "GET",
				query,
			});
		} else {
			res = await api.fetch({
				path: `/api/v2/incentive/${query.incentiveId}/products`,
				method: "GET",
				query,
			});
		}

		if (res.ok === true) {
			set({
				allProducts: res.payload.results,
				totalProdPages: res.payload.count,
			});
		} else {
			console.log("error fetching users");
			set({ allProducts: [] });
		}
	},
	updateAccounts: async (
		isObjective: boolean,
		query: IPaginatedQuery,
		newAccounts: { buyerId: string; checked: boolean }[]
	) => {
		let res;
		if (isObjective) {
			res = await api.fetch({
				path: `/api/v2/objective/${query.objectiveId}/updateAccounts`,
				method: "POST",
				query,
				body: {
					objectiveId: query.objectiveId,
					accounts: newAccounts,
				},
			});
		} else {
			res = await api.fetch({
				path: `/api/v2/incentive/${query.incentiveId}/updateAccounts`,
				method: "POST",
				query,
				body: {
					incentiveId: query.incentiveId,
					accounts: newAccounts,
				},
			});
		}

		if (res.ok === true) {
			console.log(res.payload);
			console.log("updated accounts");
		} else {
			console.log("error fetching users");
		}
	},
	updateProducts: async (
		isObjective: boolean,
		query: IPaginatedQuery,
		newProducts: { productId: string; checked: boolean }[]
	) => {
		let res;
		if (isObjective) {
			res = await api.fetch({
				path: `/api/v2/objective/${query.objectiveId}/updateProducts`,
				method: "POST",
				query,
				body: {
					objectiveId: query.objectiveId,
					products: newProducts,
				},
			});
		} else {
			res = await api.fetch({
				path: `/api/v2/incentive/${query.incentiveId}/updateProducts`,
				method: "POST",
				query,
				body: {
					incentiveId: query.incentiveId,
					products: newProducts,
				},
			});
		}

		if (res.ok === true) {
			console.log(res.payload);
			console.log("updated products");
		} else {
			console.log("error fetching users");
		}
	},
	fetchAccounts: async (
		query: IQueryFavorite,
		itemId: string,
		userId: string,
		isObjective?: boolean,
		callback?: () => void
	) => {
		if (itemId !== "" || itemId !== undefined) {
			set({ secondaryLoading: true });
			let res;
			if (isObjective) {
				res = await api.fetch({
					path: `/api/v2/objective/${itemId}/buyers/${userId}`,
					method: "GET",
					query,
				});
			} else {
				res = await api.fetch({
					path: `/api/v2/incentive/${itemId}/buyers/${userId}`,
					method: "GET",
					query,
				});
			}

			if (res.ok === true) {
				set({
					accounts: res.payload,
					secondaryTotalPages: res?.meta?.totalPages,
				});
				if (callback) callback();
				set({ secondaryLoading: false });
			} else {
				console.log("error fetching objectives");
				set({ accounts: [] });
				set({ secondaryLoading: false });
			}
		}
	},
}));
