import { useState, useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { objectivesStoreZustand } from '../store/objectivesStore';
import { mapRowToColumns } from '../utils/mapRowToColumns';
import { ButtonGroup, CircularProgress } from '@material-ui/core';
import { ExportXLSButton } from '../utils/ExportXLSXButton';
import { ExportCSVButton } from '../utils/ExportCSVButton';
import SearchField from '../utils/Search';
import ToggleColumnsButton from '../utils/ToggleColumnsButton';
import {
	determineColumnsToShowIncentive,
	determineColumnsToShowObjective,
	transformColumnName,
} from './utils';
import TableContainer from '@material-ui/core/TableContainer';
import { Item } from '../../usersManagement/types/commonObjIncTypes';
import { incentivesStoreZustand } from '../store/incentivesStore';

export interface IRow {
	userName?: string;
	userType?: string;
	objective?: string;
	subObjectives?: string;
	incentive?: string;
	subIncentives?: string;
	startDate?: string;
	endDate?: string;
	type?: string;
	route?: string;
}

interface IProps {
	itemType: string;
	loading: boolean;
	columns: string[];
	rows: IRow[];
	selectedItem?: Item[];
	shouldResetFilters: boolean;
	setShouldResetFilters: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
	success: {
		color: theme.palette.success.main,
	},
	error: {
		color: theme.palette.error.main,
	},
	hoveredRow: {
		backgroundColor: theme.palette.action.hover,
	},
	emptyBox: {
		justifyContent: 'center',
		alignItems: 'center',
		padding: theme.spacing(2),
		display: 'flex',
		flex: 1,
	},
	headerCell: {
		minWidth: 155,
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.primary.contrastText,
		marginRight: theme.spacing(1),
	},
	firstHeaderCell: {
		minWidth: 155,
		zIndex: 3,
		backgroundColor: '#FAFAFA',
	},
	totalRow: {
		backgroundColor: '#E8E8E8',
		'& > *': {
			fontWeight: 'bold',
			textTransform: 'uppercase',
		},
	},
	buttonGroupContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: theme.spacing(2),
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(1.2),
		marginLeft: theme.spacing(0.5),
	},
}));

export default function ItemsManagementReportTable(props: IProps) {
	const {
		itemType,
		loading,
		columns,
		rows,
		shouldResetFilters,
		setShouldResetFilters,
	} = props;

	const store =
		itemType === 'objective'
			? objectivesStoreZustand()
			: incentivesStoreZustand();

	const {
		sortColumnName,
		sortDirection,
		setDirection,
		setColumnName,
		setOrder,
	} = store;

	const classes = useStyles();

	const calculateTotalPayout = (rows: any[]): number => {
		let totalPayout = 0;
		rows.forEach((row) => {
			if(typeof row.payout==='number'){
				totalPayout += parseFloat(row.payout || 0);
			}
		});
		return totalPayout;
	};

	const calculateTotalTarget = (rows: any[]): number => {
		let totalTarget = 0;
		rows.forEach((row) => {
			totalTarget += parseFloat(row.target || 0);
		});
		return totalTarget;
	};
	// assuming PayoutMTD is a number
	const calculateTotalPayoutMTD = (rows: any[]): number => {
		let totalPayoutMTD = 0;
		rows.forEach((row) => {
			totalPayoutMTD += parseFloat(row.payoutMTD || 0);
		});
		return totalPayoutMTD;
	};

	const [searchValue, setSearchValue] = useState<string>('');

	const handleSearchChange = (value: string) => {
		setSearchValue(value.toLowerCase());
	};

	const filteredRows = rows.filter((row) => {
		return (
			row.userName?.toLowerCase().includes(searchValue) ||
			row.userType?.toLowerCase().includes(searchValue) ||
			row.objective?.toLowerCase().includes(searchValue) ||
			(typeof row.subObjectives === 'string' &&
				row.subObjectives.toLowerCase().includes(searchValue)) ||
			(row.startDate &&
				new Date(row.startDate)
					.toLocaleDateString()
					.toLowerCase()
					.includes(searchValue)) ||
			(row.endDate &&
				new Date(row.endDate)
					.toLocaleDateString()
					.toLowerCase()
					.includes(searchValue)) ||
			row.type?.toLowerCase().includes(searchValue) ||
			row.route?.toLowerCase().includes(searchValue)
		);
	});
	const determineColumnsToShow =
		itemType === 'objective'
			? determineColumnsToShowObjective(rows)
			: determineColumnsToShowIncentive(rows);
	const [showAllColumns, setShowAllColumns] = useState(false);
	const columnsToShow = showAllColumns ? columns : determineColumnsToShow;

	useEffect(() => {
		if (shouldResetFilters) {
			setSearchValue('');
			setShowAllColumns(false);
			setShouldResetFilters(false);
		}
	}, [shouldResetFilters, setShouldResetFilters]);

	return (
		<div style={{ overflow: 'auto' }}>
			<ButtonGroup className={classes.buttonGroupContainer}>
				<SearchField
					label="Search in report table"
					value={searchValue}
					onChange={handleSearchChange}
				/>
				<ToggleColumnsButton
					showAllColumns={showAllColumns}
					setShowAllColumns={setShowAllColumns}
				/>
				<ExportXLSButton
					columns={columns}
					rows={rows}
					calculateTotalPayout={calculateTotalPayout}
					calculateTotalPayoutMTD={calculateTotalPayoutMTD}
					calculateTotalTarget={calculateTotalTarget}
				/>
				<ExportCSVButton
					columns={columns}
					rows={rows}
					calculateTotalPayout={calculateTotalPayout}
					calculateTotalPayoutMTD={calculateTotalPayoutMTD}
					calculateTotalTarget={calculateTotalTarget}
				/>
			</ButtonGroup>
			{!loading && (
				<TableContainer style={{ maxHeight: '80vh' }}>
					<Table size="small">
						<TableHead>
							<TableRow>
								{columnsToShow.map((column, index) => (
									<TableCell className={classes.headerCell} key={index}>
										<TableSortLabel
											active={sortColumnName === column}
											direction={sortDirection}
											onClick={() => {
												const transformedColName = transformColumnName(column);
												setDirection(sortDirection === 'asc' ? 'desc' : 'asc');
												setColumnName(column);
												setOrder(
													sortDirection === 'asc' ? 'desc' : 'asc',
													transformedColName
												);
											}}
										>
											{column}
										</TableSortLabel>
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{filteredRows.length !== 0 &&
								filteredRows.map((row, index) => {
									const orderedRow = mapRowToColumns(row, columnsToShow);
									return (
										<TableRow key={index}>
											{orderedRow.map((cellContent, cellIndex) => (
												<TableCell key={cellIndex}>{cellContent}</TableCell>
											))}
										</TableRow>
									);
								})}
							{rows.length !== 0 && (
								<TableRow className={classes.totalRow}>
									<TableCell>Total</TableCell>
									{columnsToShow.slice(1).map((column, index) => (
										<TableCell key={index}>
											{column === 'Target'
												? calculateTotalTarget(filteredRows)
												: ''}
											{column === 'Payout MTD'
												? calculateTotalPayoutMTD(filteredRows)
												: ''}
											{column === 'Payout'
												? calculateTotalPayout(filteredRows)
												: ''}
										</TableCell>
									))}
								</TableRow>
							)}
						</TableBody>
					</Table>
					{rows.length === 0 && (
						<div className={classes.emptyBox}>
							<Typography variant="subtitle1">No data</Typography>
						</div>
					)}
				</TableContainer>
			)}
			{loading && (
				<div
					style={{
						display: 'flex',
					}}
				>
					<CircularProgress
						size={'sm'}
						style={{ alignItems: 'center', width: '20px' }}
					/>
				</div>
			)}
		</div>
	);
}
