import React, { useState } from 'react';

import useAccountTypes from '../../hooks/useAccountTypes';
import { Button, FormControl } from '@material-ui/core';

import Select from 'react-select';
import useCustomAccounts from '../../hooks/useCustomAccounts';
import { Item, isIncentive } from '../../types/commonObjIncTypes';

type CustomAccountsSelectionProps = {
	item: Item;
};

export const CustomAccountsSelection: React.FC<
	CustomAccountsSelectionProps
> = ({ item }) => {
	const [selectedAccountTypes, setSelectedAccountTypes] = useState<string[]>(
		item.accountsFilters?.accountType || []
	);

	const [selectedPremises, setSelectedPremises] = useState<string[]>(
		item.accountsFilters?.premise || []
	);
	const { accountTypes } = useAccountTypes();

	const premises = ['On Premise', 'Off Premise'];

	const { handleAddAccountType } = useCustomAccounts();
	const handleAccountSelectChange = (multi: any) => {
		console.log(multi);
		if (multi) {
			setSelectedAccountTypes(multi.map((type: any) => type.value));
		} else {
			setSelectedAccountTypes([]);
		}
	};

	const handlePremiseSelectChange = (multi: any) => {
		console.log(multi);
		if (multi) {
			setSelectedPremises(multi.map((type: any) => type.value));
		} else {
			setSelectedPremises([]);
		}
	};

	const handleSave = () => {
		handleAddAccountType(
			item._id,
			{
				accountType: selectedAccountTypes,
				premise: selectedPremises,
			},
			!isIncentive(item)
		);
	};
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				marginLeft: '0',
			}}
		>
			<FormControl variant="outlined" style={{ marginTop: 10 }}>
				<Select
					isMulti
					name="accountTypes"
					placeholder="Select Account Types"
					options={accountTypes.map((type) => ({
						value: type,
						label: type,
					}))}
					value={selectedAccountTypes.map((type) => ({
						value: type,
						label: type,
					}))}
					onChange={(multi) => handleAccountSelectChange(multi)}
				/>
			</FormControl>

			<FormControl variant="outlined" style={{ marginTop: 10 }}>
				<Select
					isMulti
					name="premises"
					placeholder="Select Premise Types"
					options={premises.map((type) => ({
						value: type,
						label: type,
					}))}
					value={selectedPremises.map((type) => ({
						value: type,
						label: type,
					}))}
					onChange={(multi) => handlePremiseSelectChange(multi)}
				/>
			</FormControl>

			<Button
				variant="contained"
				color="primary"
				onClick={() => handleSave()}
				style={{ marginTop: 10 }}
			>
				Save
			</Button>
		</div>
	);
};
