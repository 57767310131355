import React, { useState } from "react";
import {
	Button,
	TextField,
	DialogContent,
	FormLabel,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
} from "@material-ui/core";
import ExpandMore from "@material-ui/icons/ExpandMore";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { UsersSelect } from "./accountsProductsUsersSelect";
import { capitalizeFirstLetter } from "../../utils";
import { useStyles } from "./EditComponent.styles";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { ManualAccountsSelection } from "./ManualAccountsSelection";
import { CustomAccountsSelection } from "./CustomAccountsSelection";
import {
	CustomObjIncProductFilters,
	Item,
	MeasureState,
	MeasureSymbol,
	ObjIncProducts,
	SemiItem,
	initialSubInc,
	isIncentive,
} from "../../types/commonObjIncTypes";
import { AddPrizesModal } from "./addPrizesModal/AddPrizesModal";
import {
	Incentive,
	IncentiveTypeEnum,
	IncentiveUser,
} from "../../types/incentivesTypes";
import { SubItemList } from "../itemsList/SubItemList";
import { DividerFormControl } from "../../common/DividerFormControl";
import { TargetComponent } from "./targetComponent/TargetComponent";
import { PayoutComponent } from "./payoutComponents/PayoutComponent";
import {
	PayoutDetails,
	getPayoutTypeValue,
	initializeMeasures,
	updatedUsers,
} from "./utils";
import { EditSubItemComponent } from "./EditSubItemComponent";
import { QuantityForm } from "../quantityObjective/QuantityForm";
import { AddNewSubItemComponent } from "../addObjectiveComponents/addNewSubItem";
import { NameDescriptionPeriodComponent } from "./nameDescriptionPeriod/NameDescriptionPeriodComponent";
import useItemValidation from "./useItemValidation";
import { AdvancedPayment } from "../../types/objectiveTypes";
import useHandleIncentiveUpdate from "../../hooks/useHandleIncentiveUpdate";
import { GroupedTable } from "./groupedObjective/GroupedTable";
import { AdvancedPaymentComponent } from "./paymentAdvancedComponent/AdvancedPaymentComponent";
import { incentivesStoreZustand } from "../../../objectivesIncentives/store/incentivesStore";
import { ValueInput } from "./valueObjective/ValueInputComponent";
import { MultiPayoutComponent } from "./payoutComponents/MultiPayoutComponent";
import SelectionRadioButtons from "./RadioSelectionAccounts";
import { CustomProductsSelection } from "./CustomProductsSelection";
import { ManualProductsSelection } from "./ManualProductsSelection";
import { FormProvider, useForm } from "react-hook-form";

interface EditIncentiveComponentProps {
	initialItemState: Incentive;
	onSave: (item: Item) => void;
	onClose: () => void;
}

export const EditIncentiveComponent: React.FC<EditIncentiveComponentProps> = ({
	initialItemState,
	onSave,
	onClose,
}) => {
	const classes = useStyles();
	const methods = useForm();
	const [isFormSubmitted, setIsFormSubmitted] = useState(false);

	const [item, setItem] = useState<Incentive>(initialItemState as Incentive);
	const [open, setOpen] = useState(false);
	const { addSubIncentive, deleteSubIncentive, editSubIncentive } =
		useHandleIncentiveUpdate();
	const incentives = incentivesStoreZustand((state) => state.incentives);
	const [selectionType, setSelectionType] = useState("custom");
	const [selectionTypeProd, setSelectionTypeProd] = useState("custom");

	const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>({
		payout: item?.payout || 0,
		payoutType: item?.payoutType || "perAction",
		allOrNothingAmount: item?.quantityPayout || 0,
		perActionAmount: item?.quantityPayout || 0,
		atRiskAmount: item?.quantityPayout || 0,
		threshold: item?.threshold || { units: "percentage", value: 0 },
		cap: item?.cap || { units: "percentage", value: 100 },
	});

	const [selectedProductsFilters, setSelectedProductsFilters] = useState<CustomObjIncProductFilters>(
		initialItemState.productsFilters || {
			supplier: [],
			brand: [],
			packageType: [],
			premise: [],
			size: [],
			productType: [],
		}
	);

	const [selectedUsers, setSelectedUsers] = useState<string[]>(
		item?.users ? item?.users?.map((user) => user.user) : []
	);

	const [measures, setMeasures] = useState(
		initializeMeasures(initialItemState.measure)
	);

	const [valueInputType, setValueInputType] = useState(item.valueInputType);
	const [valueInput, setValueInput] = useState(item.valueInput);

	const handleValueInputChange = (name: string, value: string | number) => {
		if (name === "valueInputType") {
			setValueInputType(value as string);
			setItem((prev) => ({
				...prev,
				valueInputType: value as string,
			}));
		} else if (name === "valueInput") {
			setValueInput(value as number);
			setItem((prev) => ({
				...prev,
				valueInput: value as number,
			}));
		}
	};

	const [groupedIncentives, setGroupedIncentives] = useState<Incentive[]>(
		item.groupedIncentives || []
	);

	const handleChangeGroupedIncentives = (
		updatedGroupedIncentives: Incentive[]
	) => {
		setGroupedIncentives(updatedGroupedIncentives);
		setItem((prev) => ({
			...prev,
			groupedIncentives: updatedGroupedIncentives,
		}));
	};

	const [advancedPayments, setAdvancedPayments] = useState<AdvancedPayment[]>([
		{ completed: 0, ofBudget: 0 },
	]);

	const handleAdvancedPaymentsChange = (updatedPayments: AdvancedPayment[]) => {
		setAdvancedPayments(updatedPayments);
		setItem((prev) => ({
			...prev,
			advancedPayments: updatedPayments,
		}));
	};

	const { areAdvancedPaymentsValid, isFormValid } = useItemValidation(
		item,
		advancedPayments,
		item.subIncentives
	);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleMeasureChange = (
		label: string,
		checked: boolean,
		startDate?: string,
		endDate?: string,
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		symbol?: MeasureSymbol
	) => {
		setMeasures((prevMeasures) =>
			prevMeasures.map((measure) =>
				measure.label === label
					? {
						...measure,
						startDate,
						endDate,
						trackingPeriodStart,
						trackingPeriodEnd,
						checked,
						symbol,
					}
					: { ...measure, checked: false }
			)
		);
	};

	const handleUserSelectionChange = (selectedUsers: string[]) => {
		if (isIncentive(item)) {
			setSelectedUsers(selectedUsers);

			setItem((prev) => {
				return {
					...prev,
					users: updatedUsers(selectedUsers, item) as IncentiveUser[],
				};
			});
		}
	};

	const handleChange = (name: string, value: string | number) => {
		setItem((prev) => {
			const updateIncentive = { ...prev, [name]: value };
			return updateIncentive;
		});
	};

	const getMeasuresForSave = (measures: MeasureState[]) => {
		return measures.filter((measure: MeasureState) => measure.checked)[0];
	};

	const [selectedSubIncentive, setSelectedSubIncentive] = useState<Incentive>();
	const [isEditSubIncentiveModalOpen, setIsEditSubIncentiveModalOpen] =
		useState(false);

	const handleEditSubIncentive = (subIncentiveId: string) => {
		if (!item?.subIncentives || !item.subIncentives) return;

		const subIncentive = item.subIncentives?.find(
			(it) => it._id === subIncentiveId
		);
		if (!subIncentive) return;

		setSelectedSubIncentive(subIncentive);
		setIsEditSubIncentiveModalOpen(true);
	};

	const handleDeleteSubIncentive = async (id: string, parentId: string) => {
		const updatedIncentive = await deleteSubIncentive(id, parentId);
		setItem(updatedIncentive);
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		if (!isFormValid()) {
			setIsFormSubmitted(true);
			return;
		}

		const transformedUsers = selectedUsers.map((selectedUserId) => {
			// Find the user in the existing users array
			const existingUser = item?.users?.find(
				({ user }) => user === selectedUserId
			);

			if (existingUser) {
				return existingUser;
			}

			// If the user is new, return a new user object with default values
			return {
				user: selectedUserId,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subIncentives: null,
				goal: null,
			};
		});

		let updatedIncentive = {
			...item,
			measure: getMeasuresForSave(measures),
			users: transformedUsers,
			subIncentives: item.subIncentives,
			payoutType: payoutDetails.payoutType,
			threshold: payoutDetails.threshold,
			cap: payoutDetails.cap,
			payout: payoutDetails.payout,
			groupedIncentives: groupedIncentives,
			valueInputType: valueInputType,
			valueInput: valueInput,
			isValid: true,
			productsFilters: selectedProductsFilters,
		};

		if (item.type === IncentiveTypeEnum.Quantity) {
			updatedIncentive = {
				...updatedIncentive,
				measure: getMeasuresForSave(measures),
			};
		}
		if (item.type !== IncentiveTypeEnum.Multi) {
			updatedIncentive = {
				...updatedIncentive,
				quantityPayout:
					getPayoutTypeValue(
						payoutDetails.payoutType,
						payoutDetails.allOrNothingAmount,
						payoutDetails.perActionAmount,
						payoutDetails.atRiskAmount
					) || 0,
			};
		}

		updatedIncentive = {
			...updatedIncentive,
			rankingPrizes: prizes,
		};

		if (item.type === IncentiveTypeEnum.Multi) {
			updatedIncentive.subIncentives?.forEach((subIncentive) => {
				const hasChanges =
					updatedIncentive.customPeriodStart !==
					subIncentive.customPeriodStart ||
					updatedIncentive.customPeriodEnd !== subIncentive.customPeriodEnd ||
					JSON.stringify(updatedIncentive.users) !==
					JSON.stringify(subIncentive.users);

				if (hasChanges) {
					subIncentive.customPeriodStart = updatedIncentive.customPeriodStart;
					subIncentive.customPeriodEnd = updatedIncentive.customPeriodEnd;
					subIncentive.users = updatedIncentive.users;

					if (subIncentive._id) {
						editSubIncentive(subIncentive._id, item._id, subIncentive, true);
					}
				}
			});
		}

		setIsFormSubmitted(false);
		onSave(updatedIncentive);
	};

	const [prizeModalOpen, setPrizeModalOpen] = useState(false);

	const [prizes, setPrizes] = useState(initialItemState?.rankingPrizes ?? []);

	const handlePrizeModalOpen = () => {
		setPrizeModalOpen(true);
	};

	const handlePrizeModalClose = () => {
		setPrizeModalOpen(false);
	};

	const handleTargetChange = (value: number) => {
		setPayoutDetails((prevItem) => ({
			...prevItem,
			payout: value,
		}));
	};

	const handleIsOpenEndedChange = (checked: boolean) => {
		setItem((prevItem) => ({
			...prevItem,
			isOpenEnded: checked,
		}));
	};

	if (initialItemState === undefined) return null;

	const handleAddSubIncentive = async (
		newSubIncentiveData: SemiItem,
		parentIncentiveId: string
	) => {
		newSubIncentiveData.customPeriodStart = item.customPeriodStart;
		newSubIncentiveData.customPeriodEnd = item.customPeriodEnd;

		const subInc = await addSubIncentive(
			{ ...newSubIncentiveData, parentIncentiveId } as Omit<Incentive, "_id">,
			parentIncentiveId
		);

		setItem((prev) => ({
			...prev,
			subIncentives: [...(prev?.subIncentives || []), subInc],
		}));
		setSelectedSubIncentive(subInc);
		setIsEditSubIncentiveModalOpen(true);
	};

	const handleDuplicateSubIncentive = (
		id: string,
		parentIncentiveId: string
	) => {
		const subIncentive = item.subIncentives?.find((it) => it._id === id);
		if (subIncentive) {
			const newName = `${subIncentive.name} copy`;
			const { _id: objId, customId, ...restOfSubObject } = subIncentive;
			handleAddSubIncentive(
				{
					...restOfSubObject,
					name: newName,
				},
				parentIncentiveId
			);
		}
	};

	const handleSaveSubIncentive = (editedSubIncentive: Incentive) => {
		const subIncentiveId = editedSubIncentive._id;

		const updatedSubIncentives = item.subIncentives?.map((subItem) =>
			subItem._id === editedSubIncentive._id ? editedSubIncentive : subItem
		);

		setItem((prev) => ({
			...prev,
			subIncentives: updatedSubIncentives,
		}));

		editSubIncentive(subIncentiveId, item._id, editedSubIncentive, false);

		setIsEditSubIncentiveModalOpen(false);
	};

	const handleMultiPayoutChange = (quantityPayout: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			quantityPayout: quantityPayout,
		}));

		setItem((prev) => ({
			...prev,
			quantityPayout: quantityPayout,
		}));
	};

	return (
		<FormProvider {...methods}>
			<div>
				<DialogContent className={classes.dialogContent}>
					<p>
						<b>{capitalizeFirstLetter(item.type)} Incentive</b>
					</p>
					<NameDescriptionPeriodComponent
						item={item}
						handleChange={handleChange}
					/>
					{item.type === IncentiveTypeEnum.Grouped && (
						<GroupedTable
							itemType="incentive"
							items={incentives}
							groupedItems={item.groupedIncentives}
							handleChangeGroupedItems={handleChangeGroupedIncentives}
						/>
					)}
					{item.type !== IncentiveTypeEnum.Multi && (
						<>
							<TargetComponent
								name={"Target"}
								target={payoutDetails?.payout || 0}
								isOpenEnded={item.isOpenEnded}
								onTargetChange={handleTargetChange}
								onIsOpenEndedChange={handleIsOpenEndedChange}
							/>
						</>
					)}
					{item.type === IncentiveTypeEnum.Multi && (
						<>
							<MultiPayoutComponent
								subItems={item.subIncentives}
								onPayoutChange={handleMultiPayoutChange}
							/>
						</>
					)}
					{item.type !== IncentiveTypeEnum.Multi && (
						<>
							<Divider className={classes.divider} />
							<div style={{ display: "flex", flexDirection: "column" }}>
								<PayoutComponent
									payoutDetails={payoutDetails}
									setPayoutDetails={setPayoutDetails}
								/>
								{item.type === IncentiveTypeEnum.Grouped && (
									<>
										<AdvancedPaymentComponent
											advancedPayments={advancedPayments}
											onAdvancedPaymentsChange={handleAdvancedPaymentsChange}
										/>
										{isFormSubmitted && !areAdvancedPaymentsValid && (
											<span className={classes.validationError}>
												Values must be greater than the last one
											</span>
										)}
									</>
								)}
							</div>
						</>
					)}

					{item.type === IncentiveTypeEnum.Validation && (
						<DividerFormControl>
							<div className={classes.validationObjContainer}>
								<div>
									<ValueInput
										valueInputType={item.valueInputType}
										valueInput={item.valueInput}
										handleChange={handleValueInputChange}
									/>
								</div>
							</div>
						</DividerFormControl>
					)}

					<DividerFormControl>
						<FormLabel
							style={{ marginRight: 10, marginTop: 10, alignSelf: "center" }}
						>
							Supplier Contribution: %
						</FormLabel>
						<TextField
							margin="dense"
							id="supplierContribution"
							variant="outlined"
							type="number"
							fullWidth
							name="supplierContribution"
							value={item.supplierContribution}
							style={{ flex: 2 }}
							onChange={(event) =>
								handleChange("supplierContribution", event.target.value)
							}
						/>
					</DividerFormControl>
					{item.type === IncentiveTypeEnum.Quantity && (
						<QuantityForm
							measures={measures}
							handleMeasureChange={handleMeasureChange}
						/>
					)}

					{item.type !== IncentiveTypeEnum.Multi && (
						<>
							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1-content"
									id="panel1-header"
									style={{ fontSize: 16 }}
								>
									Accounts:
								</AccordionSummary>
								<AccordionDetails
									style={{ display: "flex", flexDirection: "column" }}
								>
									<SelectionRadioButtons
										selectionType={selectionType}
										setSelectionType={(type) => setSelectionType(type)}
									/>

									{selectionType === "custom" && (
										<CustomAccountsSelection item={initialItemState} />
									)}
									{selectionType === "manual" && (
										<ManualAccountsSelection item={initialItemState} />
									)}
								</AccordionDetails>
							</Accordion>

							<Accordion>
								<AccordionSummary
									expandIcon={<ExpandMore />}
									aria-controls="panel1-content"
									id="panel1-header"
									style={{ fontSize: 16 }}
								>
									Products:
								</AccordionSummary>
								<AccordionDetails
									style={{ display: "flex", flexDirection: "column" }}
								>
									<SelectionRadioButtons
										selectionType={selectionTypeProd}
										setSelectionType={(type) => setSelectionTypeProd(type)}
									/>
									{selectionTypeProd === "custom" && (
										<CustomProductsSelection
											selectedProductsFilters={selectedProductsFilters}
											setSelectedProductsFilters={setSelectedProductsFilters}
										/>
									)}
									{selectionTypeProd === "manual" && (
										<ManualProductsSelection
											item={initialItemState}
											isObjective={false}
										/>
									)}
								</AccordionDetails>
							</Accordion>
						</>
					)}
					<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMore />}
							aria-controls="panel1-content"
							id="panel1-header"
							style={{ fontSize: 16 }}
						>
							Users:
						</AccordionSummary>
						<AccordionDetails>
							<UsersSelect
								initialSelectedUserIds={selectedUsers}
								onChange={handleUserSelectionChange}
							/>
						</AccordionDetails>
					</Accordion>

					<div className={classes.prizesButtonWrapper}>
						<Button
							className={classes.button}
							color="primary"
							startIcon={<AddCircleOutlineIcon />}
							onClick={handlePrizeModalOpen}
						>
							Edit Prizes
						</Button>
					</div>
					{prizeModalOpen && prizes !== undefined && (
						<AddPrizesModal
							handleClose={handlePrizeModalClose}
							customPrizes={prizes}
							handlePrizesChange={(prizes) => setPrizes(prizes)}
						/>
					)}
					{item.type === IncentiveTypeEnum.Multi && (
						<>
							<div className={classes.buttonContainer}>
								<Button
									className={classes.button}
									color="primary"
									startIcon={<AddCircleOutlineIcon />}
									onClick={handleClickOpen}
								>
									New Sub Incentive
								</Button>
							</div>

							<AddNewSubItemComponent
								open={open}
								initState={initialSubInc}
								itemType="incentive"
								onSave={(item: SemiItem, parentItemId: string) => {
									handleAddSubIncentive(
										item as Omit<Incentive, "_id">,
										parentItemId
									);
								}}
								onClose={() => setOpen(false)}
								parentItemId={item._id}
							/>
							<SubItemList
								subItems={item.subIncentives as Incentive[]}
								onEdit={handleEditSubIncentive}
								onDelete={handleDeleteSubIncentive}
								onDuplicate={handleDuplicateSubIncentive}
							/>
						</>
					)}

					{isEditSubIncentiveModalOpen && selectedSubIncentive && (
						<EditSubItemComponent
							initialSubItem={selectedSubIncentive as Incentive}
							open={true}
							onSave={handleSaveSubIncentive}
							onClose={() => setIsEditSubIncentiveModalOpen(false)}
							itemType={"incentive"}
						/>
					)}
				</DialogContent>
				<div className={classes.buttonsWrapper}>
					<Button onClick={onClose} color="primary">
						Cancel
					</Button>
					<Button
						type="submit"
						onClick={methods.handleSubmit(handleSubmit)}
						color="primary"
					>
						Save
					</Button>
				</div>
			</div>
		</FormProvider>
	);
};
