import { FormControl } from "@material-ui/core";
import { CustomObjIncProductFilters } from "../../types/commonObjIncTypes";
import Select from 'react-select';
import useProductsFilters from "../../hooks/useProductsFilters";

type CustomProductsSelectionProps = {
    selectedProductsFilters: CustomObjIncProductFilters;
    setSelectedProductsFilters: React.Dispatch<React.SetStateAction<CustomObjIncProductFilters>>;
}

export const CustomProductsSelection: React.FC<
    CustomProductsSelectionProps
> = ({
    selectedProductsFilters,
    setSelectedProductsFilters,
}) => {

        const { suppliers, brands, packageTypes, sizes, productTypes, premise } =
            useProductsFilters();


        const getOptionsForFilter = (filterName: string) => {
            switch (filterName) {
                case 'supplier':
                    return suppliers.map(supplier => ({ value: supplier, label: supplier }));
                // case 'noSupplier':
                //     return suppliers.map(supplier => ({ value: supplier, label: supplier }));
                case 'brand':
                    return brands.map(brand => ({ value: brand, label: brand }));
                // case 'noBrand':
                //     return brands.map(brand => ({ value: brand, label: brand }));
                case 'packageType':
                    return packageTypes.map(packageType => ({ value: packageType, label: packageType }));
                case 'size':
                    return sizes.map(size => ({ value: size, label: size }));
                case 'productType':
                    return productTypes.map(productType => ({ value: productType, label: productType }));
                case 'premise':
                    return premise.map(pr => ({ value: pr, label: pr }));
                default:
                    return [];
            }
        };



        const handleProductSelectChange = (selectedOptions: any, selectName: string) => {
            setSelectedProductsFilters((prevSelectedProducts: any) => ({
                ...prevSelectedProducts,
                [selectName]: selectedOptions ? selectedOptions.map((option: any) => option.value) : [],
            }));
        };

        const filterLabels: { [key: string]: string } = {
            supplier: 'Suppliers',
            // noSupplier: 'Excluded Suppliers',
            brand: 'Brands',
            // noBrand: 'Excluded Brands',
            packageType: 'Package Types',
            size: 'Sizes',
            productType: 'Product Types',
            premise: 'Premise',
        };


        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    marginLeft: '0',
                }}
            >
                {Object.entries(selectedProductsFilters).map(([filterName, selectedValues]) => (
                    <FormControl key={filterName} variant="outlined" style={{ marginTop: 10 }}>
                        <Select
                            isMulti
                            name={filterName}
                            placeholder={`Select ${filterLabels[filterName]}`}
                            options={getOptionsForFilter(filterName as keyof CustomObjIncProductFilters)}
                            value={Array.isArray(selectedValues) ? selectedValues.map((value: string) => ({ value, label: value })) : []}
                            onChange={(selectedOption: any) => handleProductSelectChange(selectedOption, filterName as keyof CustomObjIncProductFilters)}
                        />
                    </FormControl>
                ))}
            </div>
        );
    };