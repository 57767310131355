import { useEffect, useState } from 'react';
import { objectivesStoreZustand } from '../../objectivesIncentives/store/objectivesStore';
import useQuery, { IGeneralQuery } from '../../../hooks/useQuery';

const useObjectives = () => {
	const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;
	const [pageSize, setPageSize] = useState(5);

	const loading = objectivesStoreZustand((state) => state.loading);
	
	const currentPage = objectivesStoreZustand((state) => state.currentPage);
	const setCurrentPage = objectivesStoreZustand(
		(state) => state.setCurrentPage
	);
	

	const totalPages = objectivesStoreZustand((state) => state.totalPages);
	
	const objectives = objectivesStoreZustand((state) => state.objectives);
	
	const favoriteObjectives = objectivesStoreZustand(
		(state) => state.favoriteObjectives
	);

	useEffect(() => {
		const fetchAllSubObjectives = () => {
			const { objectives } = objectivesStoreZustand.getState();
			objectives.forEach((objective) => {
				if (objective.type === 'multi') {
					objectivesStoreZustand
						.getState()
						.fetchSubObjectives({ wholesalerId }, objective._id);
				}
			});
		};

		objectivesStoreZustand
			.getState()
			.fetchObjectives(
				{ wholesalerId, page: currentPage, limit: pageSize },
				fetchAllSubObjectives
			);
	}, [wholesalerId, currentPage, pageSize]);

	useEffect(() => {
		objectivesStoreZustand
			.getState()
			.fetchFavoriteObjectives({ wholesalerId, favorite: true });
	}, [wholesalerId]);

	return {
		objectives,
		currentPage,
		loading,
		totalPages,
		setCurrentPage,
		setPageSize,
		favoriteObjectives,
	};
};

export default useObjectives;
