import { makeStyles } from '@material-ui/core';


export const useStyles = makeStyles({

    container: {
        maxHeight: '100px',
        overflowY: 'auto',
    },
    dialogImage: {
        maxWidth: '100%',
        height: 'auto',
    },
})