import { ImageListCell } from './ImageListCell';
import { ProductsList } from './ProductsList';
import AccountsList from './AccountsList';

export const mapRowToColumns = (row: any, columns: string[]) => {
	const orderedRow: any[] = [];

	columns.forEach((column) => {
		switch (column) {
			case 'User Name':
				orderedRow.push(row.userName || 'N/A');
				break;
			case 'Route':
				orderedRow.push(row.route || 'N/A');
				break;
			case 'User Type':
				orderedRow.push(row.userType || 'N/A');
				break;
			case 'Objective':
				orderedRow.push(row.objective || 'N/A');
				break;
			case 'Incentive':
				orderedRow.push(row.incentive || 'N/A');
				break;
			case 'Start Date':
				const startDate = new Date(row.startDate).toLocaleDateString();
				orderedRow.push(startDate);
				break;
			case 'End Date':
				const endDate = new Date(row.endDate).toLocaleDateString();
				orderedRow.push(endDate);
				break;
			case 'Sub Objective':
				orderedRow.push(row.subObjectives || 'N/A');
				break;
			case 'Sub Incentive':
				orderedRow.push(row.subIncentives || 'N/A');
				break;
			case 'Type':
				orderedRow.push(row.type || 'N/A');
				break;
			case 'Quantity':
				orderedRow.push(row.totalItems || '0');
				break;
			case 'Status':
				if (row.type === 'image' || row.type === 'binary') {
					if (row.status === undefined) {
						orderedRow.push('Not defined');
					} else {
						const progress = parseInt(row.status);
						if (row.progress === '∞') {
							orderedRow.push('∞');
						} else if (isNaN(progress)) {
							orderedRow.push('Not defined');
						} else {
							let statusColor = 'black';
							if (progress < 50) {
								statusColor = 'red';
							} else if (progress >= 50 && progress < 80) {
								statusColor = '#F4C01D';
							} else if (progress >= 80) {
								statusColor = '#4E915E';
							}
							orderedRow.push(
								<span style={{ color: statusColor }}>{`${progress}%`}</span>
							);
						}
					}
				} else if (row.type === 'quantity') {
					if (row.progress === undefined) {
						orderedRow.push('Not defined');
					} else {
						const progress = parseInt(row.progress);
						if (row.progress === '∞') {
							orderedRow.push('∞');
						} else if (isNaN(progress)) {
							orderedRow.push('Not defined');
						} else {
							let statusColor = 'black';
							if (progress < 50) {
								statusColor = 'red';
							} else if (progress >= 50 && progress < 80) {
								statusColor = '#F4C01D';
							} else if (progress >= 80) {
								statusColor = '#4E915E';
							}
							orderedRow.push(
								<span style={{ color: statusColor }}>{`${progress}%`}</span>
							);
						}
					}
				} else {
					orderedRow.push('Not defined');
				}

				break;
			case 'Target':
				orderedRow.push(row.target ? `${row.target}` : '0');
				break;
			case 'Payout MTD':
				orderedRow.push(row.payoutMTD ? `${row.payoutMTD}` : '0');
				break;
			case 'Supplier':
				orderedRow.push(
					row.supplierContribution ? `$${row.supplierContribution}` : '$-'
				);
				break;
			case 'Image':
				orderedRow.push(
					row.images.length > 0 ? (
						<ImageListCell images={row.images} />
					) : (
						'No Image'
					)
				);
				break;
			case 'Accounts':
				orderedRow.push(<AccountsList itemId={row.itemId} userId={row.userId} isObjective={row.isObjective} />);
				break;
			case 'Products':
				orderedRow.push(<ProductsList products={row.products} />);
				break;
			//For validation type
			case 'Input Value':
				orderedRow.push(row.valueInput ? `${row.valueInput}` : '0');
				break;
			case 'Input Value Type':
				switch (row.valueInputType) {
					case 'gr':
						orderedRow.push('Greater than');
						break;
					case 'lt':
						orderedRow.push('Less than');
						break;
					case 'gre':
						orderedRow.push('Greater than or equal');
						break;
					case 'lte':
						orderedRow.push('Less than or equal');
						break;
					default:
						orderedRow.push('Not selected');
				}
				break;
			case 'Payout':
				orderedRow.push(row.payout ? `${row.payout}` : '0');
				break;
			default:
				orderedRow.push('N/A');
				break;
		}
	});

	return orderedRow;
};
