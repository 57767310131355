import { useEffect } from "react";
import useQuery, { IPaginatedQuery } from "../../../hooks/useQuery";

import { isIncentive } from "../types/commonObjIncTypes";
import { commonObjIncStoreZustand } from "../../objectivesIncentives/store/commonObjIncStore";
import { Objective } from "../types/objectiveTypes";
import { Incentive } from "../types/incentivesTypes";

const usePaginatedProducts = ({
	page,
	item,
}: {
	page: number;
	item: Incentive | Objective;
}) => {
	const { query } = useQuery<IPaginatedQuery>();
	const { wholesalerId } = query;
	let typeKey: string;

	if (isIncentive(item)) {
		typeKey = "incentiveId";
	} else {
		typeKey = "objectiveId";
	}

	const allProducts = commonObjIncStoreZustand((state) => state.allProducts);

	const updateProducts = (
		newProducts: { productId: string; checked: boolean }[]
	) => {
		commonObjIncStoreZustand
			.getState()
			.updateProducts(
				!isIncentive(item),
				{ [typeKey]: item?._id, wholesalerId },
				newProducts
			);
	};

	useEffect(() => {
		commonObjIncStoreZustand.getState().fetchProductsPaginated({
			page,
			wholesalerId,
			[typeKey]: item?._id,
			isObjective: !isIncentive(item),
			query,
		});
	}, [item, page, query, typeKey, wholesalerId]);

	console.log("allProducts", allProducts);
	console.log("totalPages", commonObjIncStoreZustand.getState().totalProdPages);
	return {
		allProducts,
		totalPages: commonObjIncStoreZustand.getState().totalProdPages,
		updateProducts,
	};
};

export default usePaginatedProducts;
