import React from 'react';
import { useStyles } from './FavoritesComponent.styles';
import { Item } from '../types/commonObjIncTypes';
import ItemComponent from '../common/ItemComponent';
import useIncentives from '../hooks/useIncentives';
import { Incentive } from '../types/incentivesTypes';

export const FavoriteIncentivesComponent: React.FC = () => {
	const classes = useStyles();

	const { favoriteIncentives, addIncentive, deleteIncentive, editIncentive } =
		useIncentives();

	const handleAdd = async (newData: Omit<Item, '_id'>) => {
		return await addIncentive(newData as Omit<Incentive, '_id'>);
	};

	const handleEdit = (id: string, updated: Partial<Item>) => {
		editIncentive(id, updated as Partial<Incentive>);
	};

	const handleDelete = (id: string) => {
		deleteIncentive(id);
	};

	const handleFavorite = (id: string) => {
		const item = favoriteIncentives.find((it) => it._id === id);
		if (item) {
			editIncentive(id, {
				...item,
				favorite: !item.favorite,
			});
		}
	};

	const handleDuplicate = (id: string) => {
		const item = favoriteIncentives.find((it) => it._id === id);

		if (item) {
			const newName = `${item.name} copy`;
			const { _id: id, customId, users: originalUsers, ...restOfObject } = item;
			const initializedUsers = (originalUsers || []).map((user) => ({
				...user,
				images: [],
				quantity: null,
				validation: null,
				completed: false,
				subObjectives: null,
				goal: null,
				progress: 0,
				totalPods: 0,
				payout: 0,
			}));
			handleAdd({
				...restOfObject,
				name: newName,
				users: initializedUsers,
			});
		}
	};

	return (
		<div className={classes.container}>
			<div className={classes.listWrapper}>
				<ItemComponent
					items={favoriteIncentives}
					itemType="incentive"
					showAddButton={false}
					onAdd={handleAdd}
					onEdit={handleEdit}
					onDelete={handleDelete}
					onFavorite={handleFavorite}
					onDuplicate={handleDuplicate}
				/>
			</div>
		</div>
	);
};
