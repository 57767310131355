import { useState } from "react";
import {
	Button,
	DialogContent,
	Dialog,
	DialogTitle,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Divider,
} from "@material-ui/core";
import { MeasureState, ObjectiveTypeEnum } from "../../types/objectiveTypes";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { capitalizeFirstLetter } from "../../utils";
import { useStyles } from "./EditComponent.styles";
import {
	Item,
	MeasureSymbol,
	ObjIncProducts,
	isIncentive,
} from "../../types/commonObjIncTypes";
import { NameDescriptionPeriodComponent } from "./nameDescriptionPeriod/NameDescriptionPeriodComponent";
import { PayoutDetails, getPayoutTypeValue, initializeMeasures } from "./utils";
import { TargetComponent } from "./targetComponent";
import { PayoutComponent } from "./payoutComponents";
import { ExpandMore } from "@material-ui/icons";
import { ManualAccountsSelection } from "./ManualAccountsSelection";
import { QuantityForm } from "../quantityObjective/QuantityForm";
import { ManualProductsSelection } from "./ManualProductsSelection";
import { useFormContext } from "react-hook-form";

interface EditSubItemComponentProps<T> {
	initialSubItem: T;
	open: boolean;
	itemType: "objective" | "incentive";
	onSave: (item: T) => void;
	onClose: () => void;
}

export const EditSubItemComponent = <T extends Item>({
	initialSubItem,
	itemType,
	open,
	onSave,
	onClose,
}: EditSubItemComponentProps<T>) => {
	const classes = useStyles();
	const { handleSubmit } = useFormContext();
	const [subItem, setSubItem] = useState<T>(initialSubItem);

	const [measures, setMeasures] = useState(initializeMeasures(subItem.measure));
	const [payoutDetails, setPayoutDetails] = useState<PayoutDetails>({
		payout: subItem?.payout || 0,
		payoutType: subItem?.payoutType || "perAction",
		allOrNothingAmount: subItem?.quantityPayout || 0,
		perActionAmount: subItem?.quantityPayout || 0,
		atRiskAmount: subItem?.quantityPayout || 0,
		threshold: subItem?.threshold || { units: "percentage", value: 0 },
		cap: subItem?.cap || { units: "percentage", value: 100 },
	});

	const handleMeasureChange = (
		label: string,
		checked: boolean,
		startDate?: string,
		endDate?: string,
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		symbol?: MeasureSymbol
	) => {
		setMeasures((prevMeasures) =>
			prevMeasures.map((measure) =>
				measure.label === label
					? {
							...measure,
							startDate,
							endDate,
							trackingPeriodStart,
							trackingPeriodEnd,
							checked,
							symbol,
					  }
					: { ...measure, checked: false }
			)
		);
	};

	const handleChange = (name: string, value: string | number) => {
		setSubItem((prev) => ({
			...prev,
			[name]: value,
		}));
	};

	const getMeasuresForSave = (measures: MeasureState[]) => {
		return measures.filter((measure: MeasureState) => measure.checked)[0];
	};
	const handleTargetChange = (value: number) => {
		setPayoutDetails((prev) => ({
			...prev,
			payout: value,
		}));
	};

	const handleIsOpenEndedChange = (checked: boolean) => {
		setSubItem((prevSubItem) => ({
			...prevSubItem,
			isOpenEnded: checked,
		}));
	};

	const handleSubItemSubmit = () => {
		const updatedObjective = {
			...subItem,
			measure: getMeasuresForSave(measures),
			payoutType: payoutDetails.payoutType,
			quantityPayout:
				getPayoutTypeValue(
					payoutDetails.payoutType,
					payoutDetails.allOrNothingAmount,
					payoutDetails.perActionAmount,
					payoutDetails.atRiskAmount
				) || 0,
			threshold: payoutDetails.threshold,
			cap: payoutDetails.cap,
			payout: payoutDetails.payout,
			isValid: true,
		};

		onSave(updatedObjective);
	};

	if (initialSubItem === undefined) return null;

	return (
		<Dialog
			open={open}
			onClose={onClose}
			aria-labelledby="form-dialog-title"
			className={classes.dialogSubObj}
			maxWidth="md"
		>
			<DialogTitle id="form-dialog-title" className={classes.dialogTitle}>
				{`SUB-${itemType.toLocaleUpperCase()}: ${capitalizeFirstLetter(
					subItem.type
				)} ${capitalizeFirstLetter(itemType)}`}
			</DialogTitle>
			<DialogContent className={classes.dialogContentSubObj}>
				<NameDescriptionPeriodComponent
					item={subItem}
					handleChange={handleChange}
				/>
				<>
					<TargetComponent
						name={"Target"}
						target={payoutDetails?.payout || 0}
						isOpenEnded={subItem.isOpenEnded}
						onTargetChange={handleTargetChange}
						onIsOpenEndedChange={handleIsOpenEndedChange}
					/>
				</>
				<Divider className={classes.divider} />
				<div style={{ display: "flex", flexDirection: "column" }}>
					<PayoutComponent
						payoutDetails={payoutDetails}
						setPayoutDetails={setPayoutDetails}
					/>
				</div>

				{subItem.type === ObjectiveTypeEnum.Quantity && (
					<QuantityForm
						measures={measures}
						handleMeasureChange={handleMeasureChange}
					/>
				)}

				{subItem.type !== ObjectiveTypeEnum.Multi && (
					<>
						<Accordion>
							<AccordionSummary
								aria-expanded={true}
								expandIcon={<ExpandMore />}
								aria-controls="panel1-content"
								id="panel1-header"
								style={{ fontSize: 16 }}
							>
								Accounts:
							</AccordionSummary>
							<AccordionDetails
								style={{ display: "flex", flexDirection: "column" }}
							>
								<ManualAccountsSelection item={subItem} />
							</AccordionDetails>
						</Accordion>

						<Accordion>
							<AccordionSummary
								expandIcon={<ExpandMore />}
								aria-controls="panel1-content"
								id="panel1-header"
								style={{ fontSize: 16 }}
							>
								Products:
							</AccordionSummary>
							<AccordionDetails>
								<ManualProductsSelection
									item={subItem}
									isObjective={!isIncentive(subItem)}
								/>
							</AccordionDetails>
						</Accordion>
					</>
				)}
			</DialogContent>
			<div className={classes.buttonsWrapper}>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button onClick={handleSubmit(handleSubItemSubmit)} color="primary">
					{`Save sub-${itemType}`}
				</Button>
			</div>
		</Dialog>
	);
};
