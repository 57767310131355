import React from 'react';
import { Button, ButtonGroup } from '@material-ui/core';
import { SelectionControlsProps } from './selectComponentInterfaces';

export const SelectionControls: React.FC<SelectionControlsProps> = ({
	onSelectAll,
	onClear,
}) => {
	return (
		<ButtonGroup variant="outlined">
			<Button onClick={onSelectAll} size="large">
				Select All
			</Button>
			<Button onClick={onClear} size="large">
				Clear
			</Button>
		</ButtonGroup>
	);
};
