import React, { useEffect, useState } from 'react';
import { FormControl } from '@mui/material';
import { UserGroupSelect } from '../selectComponent/UserGroupSelect';
import { UserOptionsButtonSelect } from '../selectComponent/UserOptionsButtonSelect';
import useUsers from '../../../hooks/useUsers';
import { IUser } from '../../../../../interfaces';
import Modal from '@mui/material/Modal';
import { useStyles } from './UsersSelect.styles';

interface UsersSelectProps {
	initialSelectedUserIds: string[];
	onChange: (selectedUsers: string[]) => void;
}

export const UsersSelect: React.FC<UsersSelectProps> = ({
	initialSelectedUserIds,
	onChange,
}) => {
	const [selectedGroup, setSelectedGroup] = useState('');
	const [selectedUsers, setSelectedUsers] = useState<IUser[]>([]);
	const { groupedUsers, users } = useUsers();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const mapUserIdsToUsers = (userIds: string[], allUsers: IUser[]): IUser[] => {
		return userIds
			.map((userId) =>
				allUsers.find((user) => {
					return user.customId === userId;
				})
			)
			.filter((user) => user) as IUser[];
	};

	useEffect(() => {
		const initialUsers = mapUserIdsToUsers(initialSelectedUserIds, users);

		setSelectedUsers(initialUsers);
	}, [initialSelectedUserIds, users]);

	const handleUserSelectionChange = (selectedUsers: IUser[]) => {
		setSelectedUsers(selectedUsers);
		onChange(selectedUsers.map((user) => user.customId));
	};

	const handleGroupSelected = (group: string) => {
		setSelectedGroup(group);
		setIsModalOpen(true);
	};

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const classes = useStyles();

	return (
		<>
			<FormControl
				style={{ marginBottom: '10px', marginLeft: '10px', minWidth: 120 }}
			>
				<UserGroupSelect onGroupSelected={handleGroupSelected} />
			</FormControl>

			<Modal
				disableAutoFocus
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				className={classes.modal}
			>
				<div className={classes.paper}>
					<UserOptionsButtonSelect
						users={groupedUsers[selectedGroup]}
						onSelectionChange={handleUserSelectionChange}
						initialSelectedUsers={selectedUsers}
						onClose={handleCloseModal}
					/>
				</div>
			</Modal>
		</>
	);
};
