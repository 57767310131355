import { create } from 'zustand';
import api from '../../../utils/api';
import { ISortDirection, incColumns } from '../utils/types';
import { IGeneralQuery, IQueryFavorite } from '../../../hooks/useQuery';
import snack from '../../../utils/snack';
import { IBuyer, IUser } from '../../../interfaces';
import { IWholeSaler } from '../../usersManagement/types/types';
import { Incentive } from '../../usersManagement/types/incentivesTypes';
import { CustomObjIncProductFilters, ObjIncProducts } from '../../usersManagement/types/commonObjIncTypes';

interface Store {
	incentives: Incentive[];
	totalPages: number;
	currentPage: number;
	secondaryCurrentPage: number;
	secondaryTotalPages: number;
	setSecondaryCurrentPage: (page: number) => void;
	archivedTotalPages: number;
	archivedCurrentPage: number;
	archivedSecondaryCurrentPage: number;
	archivedSecondaryTotalPages: number;
	setArchivedSecondaryCurrentPage: (page: number) => void;
	loadingArchived: boolean;
	archivedIncentives: Incentive[];
	favoriteIncentives: Incentive[];
	accountTypes: string[];
	users: IUser[];
	products: IWholeSaler | null;
	customers: IBuyer[] | null;
	setCurrentPage: (page: number) => void;
	setArchivedCurrentPage: (page: number) => void;
	fetchIncentives: (query: IQueryFavorite, callback?: () => void) => void;
	fetchSubIncentives: (query: IGeneralQuery, incentiveId: string) => void;
	fetchArchivedIncentives: (
		query: IQueryFavorite,
		callback?: () => void
	) => void;
	fetchFavoriteIncentives: (query: IQueryFavorite) => void;
	editIncentive: (query: IGeneralQuery, incentive: Partial<Incentive>) => any;
	editSubIncentive: (
		query: IGeneralQuery,
		incentive: Partial<Incentive>,
		parentId: string,
		suppressSnack: boolean
	) => any;
	columns: string[];
	rows: any[];
	loading: boolean;
	sortColumnName: string | undefined;
	sortDirection: ISortDirection;
	setDirection: (dir: ISortDirection) => void;
	setColumnName: (colName: string) => void;
	setRows: (rows: any[]) => void;
	setLoading: (loading: boolean) => void;
	setLoadingArchived: (loading: boolean) => void;
	hoveredId?: string | number;
	selectedId?: string | number;
	setHoveredId: (id: string | number) => void;
	setSelectedId: (id: string | number) => void;
	setOrder: (dir: ISortDirection, colName: string) => void;
	addIncentive: (
		query: IGeneralQuery,
		newIncentive: Omit<Incentive, '_id' | 'customId'>
	) => Promise<Incentive | undefined>;
	addSubIncentive: (
		query: IGeneralQuery,
		newIncentive: Omit<Incentive, '_id' | 'customId'>,
		parentIncentiveId: string
	) => Promise<Incentive | undefined>;
	deleteIncentive: (query: IGeneralQuery, incentiveId: string) => void;
	deleteSubIncentive: (
		query: IGeneralQuery,
		incentiveId: string,
		parentId: string
	) => Promise<Incentive | undefined>;
	fetchAccountTypes: (query: IGeneralQuery) => void;
	fetchAllUsers: (query: IGeneralQuery) => void;
	fetchProducts: (query: IGeneralQuery) => void;
	fetchCustomers: (query: IGeneralQuery) => void;
	fetchIncentivesReportTable: (
		query: IQueryFavorite,
		callback?: () => void
	) => void;
	addAllBuyersToIncentiveWithFilters: (
		query: IGeneralQuery,
		incentiveId: string,
		filters?: {
			accountType?: string[];
			premise?: string[];
		}
	) => void;
	runQueryForIncentive: (query: IGeneralQuery, incentiveId: string) => void;
}

export const incentivesStoreZustand = create<Store>((set) => ({
	incentives: [],
	totalPages: 1,
	currentPage: 1,
	secondaryCurrentPage: 1,
	secondaryTotalPages: 1,
	setSecondaryCurrentPage: (page: number) =>
		set({ secondaryCurrentPage: page }),
	archivedTotalPages: 1,
	archivedCurrentPage: 1,
	archivedSecondaryCurrentPage: 1,
	archivedSecondaryTotalPages: 1,
	setArchivedSecondaryCurrentPage: (page: number) =>
		set({ archivedSecondaryCurrentPage: page }),
	loadingArchived: false,
	favoriteIncentives: [],
	archivedIncentives: [],
	accountTypes: [],
	loading: false,
	users: [],
	products: null,
	customers: null,
	hoveredId: undefined,
	columns: incColumns,
	sortColumnName: undefined,
	sortDirection: 'asc',
	rows: [],
	setCurrentPage: (page: number) => set({ currentPage: page }),
	setArchivedCurrentPage: (page: number) => set({ archivedCurrentPage: page }),
	setDirection: (dir: ISortDirection) => set({ sortDirection: dir }),
	setRows: (rows: any) => set({ rows }),
	setColumnName: (colName: string) => set({ sortColumnName: colName }),
	setHoveredId: (id: string | number) => set({ hoveredId: id }),
	setSelectedId: (id: string | number) => set({ selectedId: id }),
	setLoading: (loading: boolean) => set({ loading }),
	setLoadingArchived: (loadingArchived: boolean) => set({ loadingArchived }),
	fetchIncentives: async (query: IQueryFavorite, callback?: () => void) => {
		set({ loading: true });
		const res = await api.fetch({
			path: '/incentives',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ incentives: res.payload, totalPages: res?.meta?.totalPages });
			if (callback) callback();
			set({ loading: false });
		} else {
			console.log('error fetching incentives');
			set({ incentives: [] });
			set({ loading: false });
		}
	},
	fetchSubIncentives: async (query: IGeneralQuery, incentiveId: string) => {
		const res = await api.fetch({
			path: `/incentive/${incentiveId}/subIncentives`,
			method: 'GET',
			query,
		});

		if (res.ok === true && res.payload?.length > 0) {
			set((state) => {
				const updatedIncentives = state.incentives.map((incentive) => {
					if (incentive._id === incentiveId) {
						return {
							...incentive,
							subIncentives: res.payload,
						};
					}
					return incentive;
				});

				return { incentives: updatedIncentives };
			});
		} else {
			console.log(
				'error fetching subIncentives or no subIncentives found',
				res
			);
		}
	},
	fetchArchivedIncentives: async (
		query: IQueryFavorite,
		callback?: () => void
	) => {
		set({ loadingArchived: true });
		const res = await api.fetch({
			path: '/incentives/archived',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({
				archivedIncentives: res.payload,
				archivedTotalPages: res?.meta?.totalPages,
			});
			if (callback) callback();
			set({ loadingArchived: false });
		} else {
			console.log('error fetching incentives');
			set({ archivedIncentives: [] });
			set({ loadingArchived: false });
		}
	},
	fetchFavoriteIncentives: async (query: IQueryFavorite) => {
		const res = await api.fetch({
			path: '/incentives',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ favoriteIncentives: res.payload });
		} else {
			console.log('error fetching incentives');
			set({ favoriteIncentives: [] });
		}
	},
	addIncentive: async (
		query: IGeneralQuery,
		newIncentive: Omit<Incentive, '_id' | 'customId'>
	) => {
		const res = await api.fetch({
			path: '/incentive',
			method: 'POST',
			query,
			body: newIncentive,
		});

		if (res.ok) {
			set((state) => ({
				incentives: [...state.incentives, res.payload],
			}));
			return res.payload;
		} else {
			console.log('error adding incentive');
		}
	},

	addSubIncentive: async (
		query: IGeneralQuery,
		newIncentive: Omit<Incentive, '_id' | 'customId'>,
		parentIncentiveId: string
	) => {
		const res = await api.fetch({
			path: '/subIncentive',
			method: 'POST',
			query,
			body: { ...newIncentive, parentIncentiveId },
		});

		console.log('parentIncentiveId', parentIncentiveId);
		if (res.ok) {
			set((state) => {
				// Find the parent incentive and update its subIncentives array
				const updatedIncentives = state.incentives.map((incentive) => {
					if (incentive._id === parentIncentiveId) {
						return {
							...incentive,
							subIncentives: [...(incentive.subIncentives || []), res.payload],
						};
					}
					return incentive;
				});

				return { incentives: updatedIncentives };
			});
			return res.payload;
		} else {
			console.log('error adding sub-incentive', res);
			return undefined;
		}
	},
	deleteIncentive: async (query: IGeneralQuery, incentiveId: string) => {
		const res = await api.fetch({
			path: `/incentive/${incentiveId}`,
			method: 'DELETE',
			query,
		});

		if (res.ok) {
			set((state) => ({
				incentives: [
					...state.incentives.filter((obj) => obj._id !== incentiveId),
				],
			}));
		} else {
			console.log('error deleting incentive');
		}
	},
	deleteSubIncentive: async (
		query: IGeneralQuery,
		incentiveId: string,
		parentId: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/subIncentive/${incentiveId}`,
			method: 'DELETE',
			query,
		});

		if (res.ok) {
			let updatedParentIncentive = undefined;
			set((state) => {
				const updatedIncentives = state.incentives.map((incentive) => {
					if (incentive._id === parentId) {
						updatedParentIncentive = {
							...incentive,
							subIncentives: incentive.subIncentives?.filter(sub => sub._id !== incentiveId),
						};
						return updatedParentIncentive;
					}
					return incentive;
				});
				return { incentives: updatedIncentives };
			});

			if (updatedParentIncentive) {
				snack.success('Delete completed 👍');
				return updatedParentIncentive;
			} else {
				throw new Error('Parent objective not found');
			}
		} else {
			snack.error('Delete failed');
		}
	},
	setOrder: (dir: ISortDirection, colName: string) => {
		set((state: Store) => {
			const updatedState: Partial<Store> = {};

			const currentRows = [...state.rows];
			updatedState.rows = currentRows.sort((a, b) => {
				const aVal = a[colName];
				const bVal = b[colName];
				if (aVal > bVal) {
					return dir === 'asc' ? -1 : 1;
				} else if (aVal < bVal) {
					return dir === 'asc' ? 1 : -1;
				} else {
					return 0;
				}
			});
			return updatedState;
		});
	},
	editIncentive: async (
		query: IGeneralQuery,
		incentive: Partial<Incentive>
	) => {
		snack.info('Updating...');

		const res = await api.fetch({
			path: `/api/v2/incentive/${incentive._id}`,
			method: 'PATCH',
			query,
			body: incentive,
		});

		if (res.ok) {
			snack.success('Update completed 👍');
			console.log('Update completed 👍', res.payload);
			set((state) => {
				// Find the index of the incentive being updated
				const incentiveIndex = state.incentives.findIndex(
					(i) => i._id === incentive._id
				);

				// Find the index of the archived incentive being updated
				const archivedIncentiveIndex = state.archivedIncentives.findIndex(
					(i) => i._id === incentive._id
				);

				// Preserve existing subincentives or similar data
				const existingSubIncentives = incentiveIndex !== -1
					? state.incentives[incentiveIndex].subIncentives
					: null;
				const existingAccountsFilters = incentiveIndex !== -1
					? state.incentives[incentiveIndex].accountsFilters
					: null;

				// Assuming res.payload contains the updated incentive without subincentives
				const updatedIncentive = incentiveIndex !== -1
					? {
						...state.incentives[incentiveIndex],
						...res.payload, // Merge updated incentive data
						accountsFilters: existingAccountsFilters, // Keep existing accountsFilters unchanged
						subIncentives: existingSubIncentives, // Keep existing subincentives unchanged
					}
					: res.payload;

				// Clone the existing incentives array for immutability
				const updatedIncentives = incentiveIndex !== -1
					? [...state.incentives]
					: state.incentives;
				if (incentiveIndex !== -1) {
					updatedIncentives[incentiveIndex] = updatedIncentive;
				}

				const updatedArchivedIncentives = archivedIncentiveIndex !== -1
					? state.archivedIncentives.map((i) =>
						i._id === incentive._id ? { ...i, ...res.payload } : i
					)
					: state.archivedIncentives;

				const newArchivedIncentives = res.payload.archived
					? [...updatedArchivedIncentives, res.payload]
					: updatedArchivedIncentives.filter((i) => i._id !== incentive._id);

				const newIncentives = res.payload.archived
					? updatedIncentives.filter((i) => i._id !== incentive._id)
					: updatedIncentives;

				// Return the new state
				return {
					incentives: newIncentives,
					archivedIncentives: newArchivedIncentives,
				};
			});

			return res.payload;
		} else {
			console.log('error updating incentive', res);
		}
	},

	editSubIncentive: async (
		query: IGeneralQuery,
		incentive: Partial<Incentive>,
		parentId: string,
		suppressSnack: boolean
	) => {
		if (!suppressSnack) {
			snack.info('Updating...');
		}

		const res = await api.fetch({
			path: `/api/v2/subIncentive/${incentive._id}`,
			method: 'PATCH',
			query,
			body: incentive,
		});
		if (res.ok) {
			set((state) => {
				// Find the parent incentive and update its subIncentives array
				const updatedIncentives = state.incentives.map((obj) => {
					if (obj._id === parentId) {
						return {
							...obj,
							subIncentives: obj.subIncentives?.map((sub) =>
								sub._id === incentive._id ? res.payload : sub
							),
						};
					} else {
						return obj;
					}
				});

				return { incentives: updatedIncentives };
			});
			if (!suppressSnack) {
				snack.success('Update completed 👍');
			}
			return res.payload;
		} else {
			console.log('error updating incentive');
		}
	},
	fetchAccountTypes: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: '/distinctAccountTypes',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ accountTypes: res.payload });
		} else {
			console.log('error fetching incentives');
			set({ accountTypes: [] });
		}
	},
	fetchAllUsers: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: '/users',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ users: res.payload });
		} else {
			console.log('error fetching users');
			set({ users: [] });
		}
	},
	fetchProducts: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: '/wholesaler',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ products: res.payload });
		} else {
			console.log('error fetching users');
			set({ products: null });
		}
	},
	fetchCustomers: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: '/buyersByAccountType',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ customers: res.payload });
		} else {
			console.log('error fetching users');
			set({ customers: null });
		}
	},
	fetchIncentivesReportTable: async (
		query: IQueryFavorite,
		callback?: () => void
	) => {
		set({ loading: true });
		const res = await api.fetch({
			path: '/api/v2/incentives',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ incentives: res.payload, totalPages: res?.meta?.totalPages });
			if (callback) callback();
			set({ loading: false });
		} else {
			console.log('error fetching incentives');
			set({ incentives: [] });
			set({ loading: false });
		}
	},
	addAllBuyersToIncentiveWithFilters: async (
		query: IGeneralQuery,
		incentiveId: string,
		filters?: {
			accountType?: string[];
			premise?: string[];
		}
	) => {
		const res = await api.fetch({
			path: `/api/v2/incentive/${incentiveId}/updateAccountsWithFilters`,
			method: 'POST',
			query,
			body: {
				incentiveId,
				filters,
			},
		});

		if (res.ok === true) {
			console.log('success');
			set((state) => {
				const incentiveIndex = state.incentives.findIndex(
					(o) => o._id === incentiveId
				);
				if (incentiveIndex === -1) {
					console.log('Incentive not found in state', state.incentives);
					return {}; // No update to state needed
				}

				const updatedIncentive = {
					...state.incentives[incentiveIndex],
					accountsFilters: filters,
				};

				// Clone the existing incentives array for immutability
				const updatedIncentives = [...state.incentives];
				updatedIncentives[incentiveIndex] = updatedIncentive;

				console.log('updatedIncentive', updatedIncentive);
				return { incentives: updatedIncentives };
			});
			snack.success('Accounts added successfully');
		} else {
			console.log('error fetching users');
		}
	},
	runQueryForIncentive: async (query: IQueryFavorite, incentiveId: string) => {
		const res = await api.fetch({
			path: `/runQueryForIncentive/${incentiveId}`,
			method: 'GET',
			query,
		});

		if (res.ok) {
			snack.success('Action completed 👍');
			console.log('Action completed 👍', res.payload);
		} else {
			console.log('error');
		}
	},
}));
