import { useState } from "react";
import { formatDate } from "./utils";
import { Box, Collapse, Grid, IconButton, Typography, makeStyles } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Item } from "../../usersManagement/types/commonObjIncTypes";

const useStyles = makeStyles((_) => ({
    card: {
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '3px 10px',
        marginBottom: '10px',
        cursor: 'pointer',
        maxWidth: '100%',
        minWidth: '65%',
    },
    mainBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        wordBreak: 'break-word',
    },
    expandedContent: {
        padding: '10px',
        borderTop: '1px solid #ccc',
    },
    expandedText: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    title: {
        fontWeight: 'bold',
    },
}));

interface ItemCardProps<T extends Item> {
    item: T;
    handleItemSelect: (item: T) => void;
    isSelected: boolean;
}

export const ItemCard = <T extends Item>({ item, handleItemSelect, isSelected }: ItemCardProps<T>) => {
    const classes = useStyles();
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setIsExpanded(!isExpanded);
    };

    const handleSelect = () => {
        handleItemSelect(item);
    };

    return (
        <Box className={classes.card} onClick={handleSelect} style={{ backgroundColor: isSelected ? '#e8eaf6' : '' }}>
            <div className={classes.mainBox}>
                <div className={classes.title}>{item.name}</div>
                <IconButton onClick={toggleExpand}>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </div>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                <Box className={classes.expandedContent}>
                    <Grid container spacing={2}>
                        <Grid container spacing={2} className={classes.expandedText}>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">Type:</Typography>
                                <Typography variant="body2">{item.type}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">Period:</Typography>
                                <Typography variant="body2">
                                    {item.customPeriodStart && formatDate(item.customPeriodStart)} - {item.customPeriodEnd && formatDate(item.customPeriodEnd)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
        </Box>
    );
};