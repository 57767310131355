import { useState } from 'react';
import { Button, ButtonGroup, CircularProgress, TextField } from '@material-ui/core';
import FilterButton from '../utils/TypeFilterButtons';
import Pagination from '@material-ui/lab/Pagination';
import { ItemCard } from '../utils/ItemCard';
import { useStyles } from '../utils/ItemsList.styles';
import { Item } from '../../usersManagement/types/commonObjIncTypes';

export interface SelectAllState {
    [page: number]: boolean;
}

export interface ItemListProps<T extends Item> {
    itemType: string;
    loading: boolean;
    items: T[];
    currentPage: number;
    setCurrentPage: (page: number) => void;
    totalPages: number;
    onItemSelect: (selectedItems: T[]) => void;
    selectedItems: T[];
    onFilterChange: () => void;
    showArchived: boolean;
    setShowArchived: (value: boolean) => void;
}

export const ItemsList = <T extends Item>({
    itemType,
    items,
    currentPage,
    totalPages,
    loading,
    setCurrentPage,
    onItemSelect,
    selectedItems,
    onFilterChange,
    showArchived,
    setShowArchived,
}: ItemListProps<T>) => {
    const classes = useStyles();
    const [selectAllActivePages, setSelectAllActivePages] = useState<SelectAllState>({});
    const [selectAllArchived, setSelectAllArchived] = useState<SelectAllState>({});
    const [filterType, setFilterType] = useState('');
    const [search, setSearch] = useState('');

    const handleItemSelect = (item: T) => {
        const isSelected = selectedItems.some(inc => inc._id === item._id);
        let updatedItems = isSelected
            ? selectedItems.filter(inc => inc._id !== item._id)
            : [...selectedItems, item];

        const allSelected = items.every(inc =>
            updatedItems.some(selectedInc => selectedInc._id === inc._id)
        );
        onItemSelect(updatedItems);

        setSelectAllActivePages(prev => ({ ...prev, [currentPage]: allSelected }));
        setSelectAllArchived(prev => ({ ...prev, [currentPage]: allSelected }))
    };

    const handleSelectAll = () => {
        const currentSelectAllState = (showArchived ? selectAllArchived : selectAllActivePages)[currentPage] || false;
        const newSelectAllState = !currentSelectAllState;
    
        let updatedItems;
        if (newSelectAllState) {
            updatedItems = [...selectedItems, ...filteredItems.filter(inc => !selectedItems.some(selected => selected._id === inc._id))];
        } else {
            updatedItems = selectedItems.filter(selected => !filteredItems.some(inc => inc._id === selected._id));
        }
        if (showArchived) {
            setSelectAllArchived(prev => ({ ...prev, [currentPage]: newSelectAllState }));
        } else {
            setSelectAllActivePages(prev => ({ ...prev, [currentPage]: newSelectAllState }));
        }
        onItemSelect(updatedItems);
    };
    

    const handleFilterTypeChange = (type: string) => {
        setFilterType((prevType) => (prevType === type ? '' : type));
        onItemSelect([]);
        onFilterChange();
        setSelectAllActivePages({});
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        setSelectAllActivePages({});
        onItemSelect([]);
    };

    const filteredItems = items.filter(item => 
        (!filterType || item.type.toLowerCase() === filterType) && 
        (!search || item.name.toLowerCase().includes(search.toLowerCase()))
    );

    const selectAll = (showArchived ? selectAllArchived : selectAllActivePages)[currentPage] || false;


    return (
        <div className={classes.container}>
            <ButtonGroup className={classes.activeArchivedButtons}>
                <Button color='primary' onClick={() => setShowArchived(false)} variant={!showArchived ? "contained" : "outlined"} size="small" className={classes.widthButton}>
                    {itemType}
                </Button>
                <Button color='primary' onClick={() => setShowArchived(true)} variant={showArchived ? "contained" : "outlined"} size="small" className={classes.widthButton}>
                    Archive
                </Button>
            </ButtonGroup>
            <ButtonGroup size="small" aria-label="outlined primary button group" className={classes.typesButton}>
                <FilterButton types={['Binary', 'Image', 'Quantity', 'Multi', 'Validation', 'Grouped']} onClick={handleFilterTypeChange} />
                <Button onClick={handleSelectAll} variant={selectAll ? 'contained' : 'outlined'} color="primary" className={classes.widthButton}>
                    {!selectAll ? 'Select All' : 'Deselect All'}
                </Button>
            </ButtonGroup>
            <TextField
                label="Search by title"
                size="small"
                variant="outlined"
                value={search}
                onChange={handleSearchChange}
                className={classes.searchField}
            />

            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: '20px' }}>
                    <CircularProgress />
                </div>
            ) :
                filteredItems.map((item) => {
                    const isSelected = selectedItems.some(inc => inc._id === item._id);
                    return (
                        <ItemCard
                            key={item._id}
                            item={item}
                            handleItemSelect={handleItemSelect}
                            isSelected={isSelected}
                        />
                    );
                })}

            {!loading && (
                <div className={classes.pagination}>
                    <Pagination
                        size="small"
                        color="primary"
                        count={totalPages}
                        page={currentPage}
                        onChange={(_, value) => setCurrentPage(value)}
                    />
                </div>
            )}

            {loading && <p>Loading items...</p>}
        </div>
    );
};
