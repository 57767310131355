import React from 'react';
import {
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material';

const SelectionRadioButtons = ({
	selectionType,
	setSelectionType,
}: {
	selectionType: string;
	setSelectionType: (selectionType: string) => void;
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectionType(event.target.value);
	};

	return (
		<FormControl component="fieldset">
			<FormLabel component="legend">Selection Type</FormLabel>
			<RadioGroup
				aria-label="selection type"
				name="selection-type"
				value={selectionType}
				onChange={handleChange}
				style={{ flexDirection: 'row' }}
			>
				<FormControlLabel
					value="custom"
					control={<Radio />}
					label="Custom Selection"
				/>
				<FormControlLabel
					value="manual"
					control={<Radio />}
					label="Manual Selection"
				/>
			</RadioGroup>
		</FormControl>
	);
};

export default SelectionRadioButtons;
