import { Objective } from '../types/objectiveTypes';
import { objectivesStoreZustand } from '../../objectivesIncentives/store/objectivesStore';
import useQuery, { IGeneralQuery } from '../../../hooks/useQuery';

const useHandleObjectiveUpdate = () => {
	const { query } = useQuery<IGeneralQuery>();

	const { wholesalerId } = query;

	const addObjective = async (newObjective: Omit<Objective, '_id'>) => {
		const obj = await objectivesStoreZustand
			.getState()
			.addObjective({ wholesalerId }, { ...newObjective });

		return obj;
	};

	const addSubObjective = (
		newObjective: Omit<Objective, '_id'>,
		parentObjectiveId: string
	) => {
		const obj = objectivesStoreZustand
			.getState()
			.addSubObjective(
				{ wholesalerId },
				{ ...newObjective },
				parentObjectiveId
			);
		objectivesStoreZustand
			.getState()
			.fetchSubObjectives({ wholesalerId }, parentObjectiveId);

		console.log('obj', obj);
		return obj;
	};

	const editObjective = (
		objectiveId: string,
		updatedFields: Partial<Objective>
	) => {
		objectivesStoreZustand
			.getState()
			.editObjective(query, { _id: objectiveId, ...updatedFields });
	};

	const editSubObjective = (
		objectiveId: string,
		parentId: string,
		updatedFields: Partial<Objective>,
		suppressSnack: boolean,
	) => {
		objectivesStoreZustand
			.getState()
			.editSubObjective(
				query,
				{ _id: objectiveId, ...updatedFields },
				parentId,
				suppressSnack
			);
	};

	const deleteObjective = (objectiveId: string) => {
		objectivesStoreZustand.getState().deleteObjective(query, objectiveId);
	};

	const deleteSubObjective = (objectiveId: string, parentId: string) => {
		const updatedObjective = objectivesStoreZustand.getState().deleteSubObjective(query, objectiveId, parentId);
		objectivesStoreZustand.getState().fetchSubObjectives({ wholesalerId }, parentId);
		return updatedObjective;
	};

	const runQueryForObjective = ( objectiveId:string ) => {
		objectivesStoreZustand.getState().runQueryForObjective( query, objectiveId );
	}

	return {
		addObjective,
		addSubObjective,
		editObjective,
		deleteObjective,
		editSubObjective,
		deleteSubObjective,
		runQueryForObjective,
	};
};

export default useHandleObjectiveUpdate;
