import { useState, useEffect } from 'react';
import { IBuyer } from '../../../../../../interfaces';

export const useCustomersByPremise = (
	data: IBuyer[] | null,
	selectedPremise: string
) => {
	const [customers, setCustomers] = useState<IBuyer[]>([]);

	useEffect(() => {
		if (selectedPremise && data && data.length > 0) {
			const filteredCustomers = data
				.filter(
					(item) =>
						item.premise.toLocaleLowerCase() ===
						selectedPremise.toLocaleLowerCase()
				)
				.map((item) => item);

			setCustomers(filteredCustomers);
		} else {
			setCustomers([]);
		}
	}, [data, selectedPremise]);

	return customers;
};
