import {
	TextField,
	FormControl,
	FormLabel,
} from '@material-ui/core';
import { DateRange } from 'react-date-range';
import { Item, isIncentive } from '../../../types/commonObjIncTypes';
import { useState } from 'react';
import { useStyles } from './NameDescriptionPeriodComponent.styles';
import moment from 'moment-timezone';
import { Incentive } from '../../../types/incentivesTypes';
import { Objective } from '../../../types/objectiveTypes';
import { useFormContext } from 'react-hook-form';

interface NameDescriptionPeriodComponentProps<T> {
	item: T;
	handleChange: (name: string, value: string | number) => void;
}

export const NameDescriptionPeriodComponent = <T extends Item>({
	item,
	handleChange,
}: NameDescriptionPeriodComponentProps<T>) => {
	const classes = useStyles();
	const { register, errors } = useFormContext();
	const [periodDates, setPeriodDates] = useState<any[]>([
		{
			startDate: item.customPeriodStart
				? new Date(item.customPeriodStart)
				: new Date(),
			endDate: item.customPeriodEnd
				? new Date(item.customPeriodEnd)
				: new Date(),
			key: 'selection',
		},
	]);

	const handlePeriodChange = (item: any) => {
		const { startDate, endDate } = item.selection;

		setPeriodDates([item.selection]);

		const startInGMT = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
		const endInGMT = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');

		if (startDate && endDate) {
			handleChange('customPeriodStart', startInGMT);
			handleChange('customPeriodEnd', endInGMT);
		}
	};

	const isSubItem = (isIncentive(item) && (item as Incentive).isSubIncentive) ||
		(!isIncentive(item) && (item as Objective).isSubObjective);


	return (
		<div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
			<div>
				<TextField
					size="small"
					error={!!errors.name}
					helperText={errors.name && errors.name.message}
					name="name"
					id="name"
					variant="outlined"
					label="Name"
					type="text"
					fullWidth
					defaultValue={item.name}
					onChange={(event) => handleChange('name', event.target.value)}
					inputRef={register({
						required: "Name is required"
					})}
				/>
				<TextField
					margin="dense"
					id="description"
					label="Description"
					variant="outlined"
					multiline
					rows={4}
					fullWidth
					name="description"
					value={item.description}
					onChange={(event) => handleChange('description', event.target.value)}
				/>
			</div>
			<div>
				{!isSubItem && (
					<FormControl component="fieldset" className={classes.periodFormControl}>
						<FormLabel component="legend">Period</FormLabel>
						<div className={classes.datePicker}>
							<DateRange
								editableDateInputs={true}
								onChange={(item) => {
									handlePeriodChange(item);
								}}
								moveRangeOnFirstSelection={false}
								ranges={periodDates}
							/>
						</div>
					</FormControl>
				)}
			</div>
		</div>
	);
};
