import { useEffect, useState } from "react";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Scroller from "../../../scroller/Scroller";
import Pagination from "@material-ui/lab/Pagination";
import ManualProductsListItem from "./ManualProductListItem";
import usePaginatedProducts from "../../hooks/usePaginatedProducts";
import { Item, isIncentive } from "../../types/commonObjIncTypes";
import { SelectionControls } from "./selectComponent/SelectionControls";
import useManualProducts from "../../hooks/useManualProducts";
import { Button } from "@material-ui/core";

interface ManualProductsListProps {
	item: Item;
}

const useStyles = makeStyles((theme) => ({
	contentScroll: {
		"&::-webkit-scrollbar": {
			display: "none",
		},
		"-ms-overflow-style": "none",
		"scrollbar-width": "none",
		overflowY: "auto",
		flex: 1,
	},
	list: {
		display: "flex",
		flexDirection: "column",
	},
	pager: {
		padding: theme.spacing(1),
		margin: "auto",
	},
}));

export default function ManualProductsList({ item }: ManualProductsListProps) {
	const classes = useStyles();

	const [currentPage, setCurrentPage] = useState(1);
	const { allProducts, totalPages } = usePaginatedProducts({
		page: currentPage,
		item: item,
	});

	const [selectedProds, setSelectedProds] = useState<
		{ productId: string; checked: boolean }[]
	>([]);

	const { updateProducts } = useManualProducts();

	const handleSave = () => {
		const productsToUpdate = selectedProds.map((product) => ({
			productId: product.productId,
			checked: product.checked,
		}));
		updateProducts(item._id, productsToUpdate, !isIncentive(item));
	};

	useEffect(() => {
		const initialSelections = allProducts.map((prod) => ({
			productId: prod.customId,
			checked: prod.isSelected || false,
		}));

		setSelectedProds(initialSelections);
	}, [allProducts]);

	const handlePageChange = (event, page: number) => {
		handleSave();
		setCurrentPage(page);
	};

	const handleClear = () => {
		setSelectedProds((prev) => prev.map((a) => ({ ...a, checked: false })));
	};

	const handleSelectAll = () => {
		setSelectedProds((prev) => prev.map((a) => ({ ...a, checked: true })));
	};

	return (
		<>
			<SelectionControls onSelectAll={handleSelectAll} onClear={handleClear} />

			<Scroller>
				<Box className={classes.contentScroll}>
					<List className={classes.list} dense>
						{allProducts.map((prod) => (
							<ManualProductsListItem
								key={prod.customId}
								product={prod}
								selected={
									selectedProds.find((a) => a.productId === prod.customId)
										?.checked || false
								}
								handleCheck={() =>
									setSelectedProds((prev) =>
										prev.map((a) =>
											a.productId === prod.customId
												? { ...a, checked: !a.checked }
												: a
										)
									)
								}
							/>
						))}
					</List>
				</Box>
				<Pagination
					count={totalPages}
					page={currentPage}
					onChange={handlePageChange}
					color="primary"
				/>
			</Scroller>
			<Button onClick={handleSave}>SAVE</Button>
		</>
	);
}
