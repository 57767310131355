import React from 'react';
import {
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import { Item } from '../../types/commonObjIncTypes';
import { ItemDisplayComponent } from './ItemDisplayComponent';
import { useStyles } from './ItemsList.styles';
import Pagination from '@material-ui/lab/Pagination';

interface ObjectiveListProps {
	items: Item[];
	currentPage: number;
	totalPages: number;
	loading?: boolean;
	setCurrentPage: (page: number) => void;
	showDeleteButton?: boolean;
	onDelete: (id: string) => void;
	onEdit: (id: string) => void;
	onDuplicate: (id: string) => void;
	onFavorite: (id: string) => void;
	onArchive: (id: string) => void;
	showArchive: boolean;
	onAction:(id: string) => void;
}

export const ItemList: React.FC<ObjectiveListProps> = ({
	items,
	currentPage,
	totalPages,
	setCurrentPage,
	showDeleteButton = true,
	loading,
	onDelete,
	onEdit,
	onDuplicate,
	onFavorite,
	onArchive,
	showArchive,
	onAction,
}) => {
	const classes = useStyles();
	
	return (
		<>
			<Table className={classes.customTable}>
				<TableHead>
					<TableRow>
						<TableCell className={classes.customCell}>Name</TableCell>
						<TableCell className={classes.customCell}>Type</TableCell>
						<TableCell className={classes.customCell}>Period</TableCell>
						<TableCell className={classes.customCell}>Actions</TableCell>
					</TableRow>
				</TableHead>
				{!loading && (
					<TableBody
						style={{
							display: loading ? 'flex' : '',
							alignItems: 'center',
						}}
					>
						{items
							.filter(item => item.archived === showArchive)  
							.map((item) => (
								<ItemDisplayComponent
								key={item._id}
								item={item}
								showDeleteButton={showDeleteButton}
								onDelete={onDelete}
								onEdit={() => onEdit(item._id)}
								onFavorite={() => onFavorite(item._id)}
								onDuplicate={() => onDuplicate(item._id)}
								onArchive={() => onArchive(item._id)}
								showActionQueryButton={item.type === 'quantity' || item.type === 'multi'}
								onAction={() => onAction(item._id)}
								/>
							))}
					</TableBody>
				)}
			</Table>
			{loading && (
				<CircularProgress
					size={'1rem'}
					style={{
						alignSelf: 'center',
						marginTop: 20,
					}}
				/>
			)}
			<div style={{ marginTop: 10 }}>
				<Pagination
					count={totalPages}
					page={currentPage}
					onChange={(_, value) => setCurrentPage(value)}
				/>
			</div>
		</>
	);
};

export default ItemList;
