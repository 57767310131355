import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TableCell,
  TableRow,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { capitalizeFirstLetter } from "../../utils";
import { Item, isIncentive } from "../../types/commonObjIncTypes";

interface SubItemProps {
  item: Item;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onDuplicate: (id: string, parentObjectiveId: string | undefined) => void;
}

export const SubItem: React.FC<SubItemProps> = ({
  item,
  onDelete,
  onEdit,
  onDuplicate,
}) => {
  const isValid = item.isValid;
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const handleAction = (action: "open" | "close" | "confirm") => {
    switch (action) {
      case "open":
        setDeleteConfirmationOpen(true);
        break;
      case "close":
        setDeleteConfirmationOpen(false);
        break;
      case "confirm":
        onDelete(item._id);
        setDeleteConfirmationOpen(false);
        break;
      default:
        break;
    }
  };

  const parentId:string | undefined = isIncentive(item)? item.parentIncentiveId: item.parentObjectiveId;

  const itemName = isValid
    ? capitalizeFirstLetter(item.name)
    : `❗${capitalizeFirstLetter(item.name)}`;
  return (
    <TableRow key={item._id}>
      <TableCell>{itemName}</TableCell>
      <TableCell>{item && capitalizeFirstLetter(item.type)}</TableCell>
      <TableCell style={{ display: "flex" }}>
        <IconButton
          size="small"
          color="primary"
          onClick={() => onEdit(item._id)}
        >
          <EditIcon />
        </IconButton>

        <IconButton
          size="small"
          color="primary"
          onClick={() => {
            onDuplicate(item._id, parentId);
          }}
        >
          <FileCopyIcon />
        </IconButton>

        <IconButton size="small" onClick={() => handleAction("open")}>
          <DeleteIcon />
        </IconButton>
        <Dialog
          open={deleteConfirmationOpen}
          onClose={() => handleAction("close")}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete {item.name}?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleAction("close")}>Cancel</Button>
              <Button onClick={() => handleAction("confirm")} color="primary">
                Delete
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </TableCell>
    </TableRow>
  );
};
