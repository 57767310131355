import React, { useEffect } from "react";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useQuery, { IGeneralQuery } from "../../hooks/useQuery";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useForm } from "react-hook-form";
import SpacingBoxView from "../spacingBox/SpacingBoxView";
import { SingleSelect } from "../select/Select";
import Scroller from "../scroller/Scroller";
import {
  getWholesaler,
  IWholesalerPatch,
  patchWholesaler,
} from "../../warehouse/wholesalerWarehouse";
import sortBy from "lodash/sortBy";
import moment from "moment";
import removeUndefined from "../../utils/removeUndefined";
import WholesalerEditSkeleton from "./WholesalerEditSkeleton";

const useStyles = makeStyles((theme) => ({
  container: {
    width: 375,
    minWidth: 375,
  },
  scroll: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    overflowY: "auto",
  },
  title: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subTitle: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(),
  },
}));

export default function WholesalerEditForm() {
  const { register, errors, handleSubmit, setValue } =
    useForm<IWholesalerPatch>();
  const classes = useStyles();
  const { query } = useQuery<IGeneralQuery>();
  const details = useSelector((state: RootState) => {
    return state.wholesalerWarehouse.details;
  });
  const dispatch = useDispatch();
  const { wholesalerId, db } = query;
  useEffect(() => {
    dispatch(getWholesaler({ wholesalerId, db }));
  }, [dispatch, wholesalerId, db]);

  if (!details || details?.customId !== wholesalerId) {
    return <WholesalerEditSkeleton />;
  }
  register("salesPeriod");
  register("visibility");
  register("fplMode");
  register("nonSellingDays");
  register("hideProfits");
  const fplMode = details.companyWideFpl
    ? "companyWide"
    : details.fplBySuperTeamLead
    ? "bySuperTeamlead"
    : "byTeam";
  return (
    <Paper className={classes.container}>
      <Box className={classes.title}>
        <Typography variant="h5">Settings</Typography>
        <Button
          size="small"
          onClick={handleSubmit((patch) => {
            dispatch(
              patchWholesaler(
                {
                  db: query.db,
                  wholesalerId: query.wholesalerId,
                },
                removeUndefined(patch)
              )
            );
          })}
          variant="outlined"
        >
          Save
        </Button>
      </Box>
      <Divider />
      <Scroller>
        <Box className={classes.scroll}>
          <Box padding={2}>
            {}
            <TextField
              InputLabelProps={{ shrink: true }}
              defaultValue={details.title}
              error={!!errors.title}
              name="title"
              inputRef={register({
                pattern: {
                  value: /^[a-zA-Z -]{1,30}$/,
                  message:
                    "The name should include between 1 and 30 letters, spaces and dashes",
                },
              })}
              label="Wholesaler name"
              helperText={errors.title?.message}
              fullWidth
              size="small"
              variant="outlined"
            />
            <SpacingBoxView />
            <TextField
              InputLabelProps={{ shrink: true }}
              defaultValue={details.maxFPL}
              error={!!errors.maxFPL}
              name="maxFPL"
              type="number"
              size="small"
              inputRef={register({
                valueAsNumber: true,
                required: {
                  value: true,
                  message: "Should be numeric",
                },
                max: {
                  value: 100,
                  message: "Cannot be more than 100",
                },
                min: {
                  value: 0,
                  message: "Cannot be less than 0",
                },
              })}
              label="Max FPL"
              helperText={errors.maxFPL?.message}
              fullWidth
              variant="outlined"
            />
            <SpacingBoxView />
            <SingleSelect
              noDefault
              label="Sales Period"
              options={[
                { key: "monthly", label: "Monthly" },
                { key: "biMonthly", label: "Bi-monthly" },
                { key: "quarterly", label: "Quarterly" },
              ]}
              defaultValue={details.salesPeriod || "monthly"}
              onSelect={(val) => {
                setValue("salesPeriod", val);
              }}
            />
            <SpacingBoxView />
            <SingleSelect
              noDefault
              label="Sales Reps can see"
              options={[
                {
                  key: "all",
                  label: "All Sales Reps and Customers",
                },
                {
                  key: "teammatesOnly",
                  label: "Teammates only",
                },
              ]}
              defaultValue={details.permissions?.visibility || "all"}
              onSelect={(val) => {
                setValue("visibility", val);
              }}
            />
            <SpacingBoxView />
            <SingleSelect
              noDefault
              label="Hide profits"
              options={[
                {
                  key: "false",
                  label: "No",
                },
                {
                  key: "true",
                  label: "Yes",
                },
              ]}
              defaultValue={details.hideProfits ? "true" : "false"}
              onSelect={(val) => {
                setValue("hideProfits", val === "true" ? true : false);
              }}
            />
            <SpacingBoxView />
            <SingleSelect
              noDefault
              label="FPL mode"
              options={[
                {
                  key: "companyWide",
                  label: "Company-wide",
                },
                {
                  key: "byTeam",
                  label: "By team",
                },
                {
                  key: "bySuperTeamlead",
                  label: "By super teaml lead",
                },
              ]}
              defaultValue={fplMode}
              onSelect={(val) => {
                setValue("fplMode", val);
              }}
            />
            <SpacingBoxView />
            <TextField
              InputLabelProps={{ shrink: true }}
              defaultValue={sortBy(details.allNonSellingDays).join("\n")}
              multiline
              rows={5}
              error={!!errors.nonSellingDays}
              name="nonSellingDays"
              inputRef={register({
                setValueAs: (val: string) => {
                  return val
                    .split("\n")
                    .map((item) => moment(item.trim()).format("YYYY-MM-DD"));
                },
                validate: (val: string) => {
                  let errorMessage = "";
                  for (const item of val.split("\n")) {
                    const trimmedDate = item.trim();
                    const d = moment(trimmedDate);
                    const lowerThreshold = moment().subtract(3, "years");
                    const upperThreshold = moment().add(2, "year");
                    if (!d.isValid()) {
                      errorMessage = `Date ${trimmedDate} is invalid`;
                      break;
                    } else if (d < lowerThreshold) {
                      errorMessage = `Date ${trimmedDate} is over 3 years old. There's no use for it, pls delete it.`;
                      break;
                    } else if (d > upperThreshold) {
                      errorMessage = `Date ${trimmedDate} is over 2 year in the future. This doesn't look right. Pls delete it.`;
                      break;
                    }
                  }

                  return errorMessage || true;
                },
              })}
              label="Non-selling days"
              helperText={
                // @ts-ignore, because useForms typing is incorrect
                errors.nonSellingDays?.message ||
                "Dates in YYYY-MM-DD format separated by line breaks"
              }
              fullWidth
              size="small"
              variant="outlined"
            />
          </Box>
          <Box className={classes.subTitle}>
            <Typography variant="h5">Sales Reps</Typography>
          </Box>
          <Box padding={2}>
            <TextField
              InputLabelProps={{ shrink: true }}
              defaultValue={details.gpSlope}
              error={!!errors.gpSlope}
              name="gpSlope"
              type="number"
              size="small"
              inputRef={register({
                valueAsNumber: true,
              })}
              label="GP slope per 1% (0 to disable)"
              helperText={errors.gpSlope?.message}
              fullWidth
              variant="outlined"
            />
            <SpacingBoxView />
            <TextField
              InputLabelProps={{ shrink: true }}
              defaultValue={details.volumeSlope}
              error={!!errors.volumeSlope}
              name="volumeSlope"
              type="number"
              size="small"
              inputRef={register({
                valueAsNumber: true,
              })}
              label="Volume slope per 1% (0 to disable)"
              helperText={errors.volumeSlope?.message}
              fullWidth
              variant="outlined"
            />
            <SpacingBoxView />
            <TextField
              InputLabelProps={{ shrink: true }}
              defaultValue={details.fplSlope}
              error={!!errors.fplSlope}
              name="fplSlope"
              type="number"
              size="small"
              inputRef={register({
                valueAsNumber: true,
              })}
              label="FPL slope per 1% (0 to disable)"
              helperText={errors.fplSlope?.message}
              fullWidth
              variant="outlined"
            />
          </Box>
          <Box className={classes.subTitle}>
            <Typography variant="h5">Team Leads</Typography>
          </Box>
          <Box padding={2}>
            <TextField
              InputLabelProps={{ shrink: true }}
              defaultValue={details.teamleadGpSlope}
              error={!!errors.teamleadGpSlope}
              name="teamleadGpSlope"
              type="number"
              size="small"
              inputRef={register({
                valueAsNumber: true,
              })}
              label="GP slope per 1% (0 to disable)"
              helperText={errors.teamleadGpSlope?.message}
              fullWidth
              variant="outlined"
            />
            <SpacingBoxView />
            <TextField
              InputLabelProps={{ shrink: true }}
              defaultValue={details.teamleadVolumeSlope}
              error={!!errors.teamleadVolumeSlope}
              name="teamleadVolumeSlope"
              type="number"
              size="small"
              inputRef={register({
                valueAsNumber: true,
              })}
              label="Volume slope per 1% (0 to disable)"
              helperText={errors.teamleadVolumeSlope?.message}
              fullWidth
              variant="outlined"
            />
            <SpacingBoxView />
            <TextField
              InputLabelProps={{ shrink: true }}
              defaultValue={details.teamleadFplSlope}
              error={!!errors.teamleadFplSlope}
              name="teamleadFplSlope"
              type="number"
              size="small"
              inputRef={register({
                valueAsNumber: true,
              })}
              label="FPL slope per 1% (0 to disable)"
              helperText={errors.teamleadFplSlope?.message}
              fullWidth
              variant="outlined"
            />
          </Box>
        </Box>
      </Scroller>
    </Paper>
  );
}
