import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles({

    container: {
        maxHeight: '95%',
        maxWidth: '15%', 
        minWidth: '15%', 
        margin: '10px 20px',
    },
    activeArchivedButtons: {
        marginBottom: '1rem',
        maxWidth: '100%',
        minWidth: '50%',
        width: '100%',
    },
    widthButton: {
        width: '100%',
    },
    typesButton: {
        marginBottom: '1rem',
        width: '100%',
    },
    pagination: {
        marginTop: '2rem',
        marginBottom: '1rem',
        display: 'flex',
        justifyContent: 'center'
    },
    searchField: {
        marginBottom:'1rem',
        width:'100%',
    },
})