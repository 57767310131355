import {
	AdvancedPayment,
	Objective,
	ObjectiveTypeEnum,
} from "../../types/objectiveTypes";
import { Incentive, IncentiveTypeEnum } from "../../types/incentivesTypes";

const useItemValidation = <T extends Objective | Incentive>(
	item: T,
	advancedPayments: AdvancedPayment[],
	subItems?: T[]
) => {
	let areAdvancedPaymentsValid = true;
	let areSubItemsValid = true;

	if (item.type === (ObjectiveTypeEnum.Multi || IncentiveTypeEnum.Multi)) {
		if (subItems !== undefined) {
			areSubItemsValid = subItems.every((subItem) => subItem.isValid);
		}
	}

	if (advancedPayments.length <= 1) {
		areAdvancedPaymentsValid = true;
	} else {
		areAdvancedPaymentsValid = advancedPayments.every((payment, index, arr) => {
			if (index === 0) return true;
			const previousPayment = arr[index - 1];
			return (
				payment.completed >= previousPayment.completed &&
				payment.ofBudget >= previousPayment.ofBudget
			);
		});
	}

	const isFormValid = () => areAdvancedPaymentsValid && areSubItemsValid;

	return {
		areAdvancedPaymentsValid,
		isFormValid,
		areSubItemsValid,
	};
};

export default useItemValidation;
