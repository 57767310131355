import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Menu, MenuItem } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';

interface FilterButtonProps {
    types: string[]; 
    onClick: (type: string) => void;
}

const FilterButton: React.FC<FilterButtonProps> = ({ types, onClick }) => {
    const [activeTab, setActiveTab] = useState<string>(''); 
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (type?: string) => {
        setAnchorEl(null);
        if (type !== undefined) {
            setActiveTab(type);
            onClick(type ? type.toLowerCase() : '');
        }
    };

    return (
        <>
           <Button 
                color="primary" 
                variant="contained"
                endIcon={<ArrowDropDown />}
                aria-controls="simple-menu" 
                aria-haspopup="true"
                onClick={handleClick}
                size='small'
                style={{width: '100%'}}
            >
                {activeTab || 'Show all'}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose()}
                style={{marginTop: '3.5rem'}}
            >
                <MenuItem
                    key="show-all"
                    onClick={() => handleClose('')}
                    selected={activeTab === null}
                    style={{padding: '1rem 4rem'}}
                >
                    Show all
                </MenuItem>
                {types.map((type, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => handleClose(type)}
                        selected={type === activeTab}
                        style={{padding: '1rem 4rem'}}
                    >
                        {type}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}


export default FilterButton;
