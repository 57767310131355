import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import SettingsIcon from '@material-ui/icons/Settings';
import CompareIcon from '@material-ui/icons/Compare';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import StarOutlineIcon from '@material-ui/icons/StarOutline';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import PagesIcon from '@material-ui/icons/Pages';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { logOut } from '../session/sessionSlice';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import Tooltip from '@material-ui/core/Tooltip';
import { allRoles, IRole } from '../../utils/roles';
import makeAvatarUrl from '../../utils/makeAvatarUrl';
import config from '../../config';
import { getWholesalers } from '../../warehouse/wholesalerWarehouse';
import Select from '@material-ui/core/Select';
import useQuery, { IGeneralQuery } from '../../hooks/useQuery';
import qs from 'qs';

interface IMenuItem {
	title: string;
	icon: React.FunctionComponent | null;
	href?: string;
	action?: Function;
	access: IRole[];
}
const items: IMenuItem[] = [
	{
		title: 'Wholesaler',
		icon: SettingsIcon,
		href: '/wholesaler',
		access: ['admin'],
	},
	{
		title: 'User Management',
		icon: SettingsIcon,
		href: '/userManagement',
		access: ['admin', 'manager'],
	},
	{
		title: 'Diff View',
		icon: CompareIcon,
		href: '/diff',
		access: ['admin'],
	},
	{
		title: 'Executive Report',
		icon: AttachMoneyIcon,
		href: '/executiveReport',
		access: ['admin', 'manager'],
	},
	{
		title: 'Leadership',
		icon: StarOutlineIcon,
		href: '/leadership',
		access: ['admin', 'manager', 'teamLead'],
	},
	{
		title: 'Team leads',
		icon: PeopleAltIcon,
		href: '/teamleads',
		access: allRoles,
	},
	{
		title: 'Sales Reps',
		icon: PeopleOutlineIcon,
		href: '/salesreps',
		access: allRoles,
	},
	{
		title: 'Suppliers',
		icon: LocalShippingIcon,
		href: '/suppliers',
		access: ['admin', 'manager'],
	},
	{
		title: 'Brands',
		icon: LocalBarIcon,
		href: '/brands',
		access: ['admin', 'manager'],
	},
	{
		title: 'Products',
		icon: PagesIcon,
		href: '/products',
		access: allRoles,
	},
	{
		title: 'Customers',
		icon: LocationOnIcon,
		href: '/customers',
		access: allRoles,
	},
	{
		title: 'Files',
		icon: InsertDriveFileIcon,
		href: '/files',
		access: allRoles,
	},
	{
		title: 'Analytics [beta]',
		icon: EqualizerIcon,
		href: '/analytics',
		access: ['admin', 'manager'],
	},
	{
		title: 'Objectives',
		icon: SettingsIcon,
		href: '/objectives',
		access: ['admin', 'manager'],
	},
	{
		title: 'Incentives',
		icon: SettingsIcon,
		href: '/incentives',
		access: ['admin', 'manager'],
	},
	{
		title: 'Log out',
		icon: ExitToAppIcon,
		action: logOut,
		access: allRoles,
	},
];

const useStyles = makeStyles((theme) => {
	return createStyles({
		drawerHeader: {
			display: 'flex',
			alignItems: 'center',
			...theme.mixins.toolbar,
		},
		headerItem: {
			flex: 1,
		},
		headerItemRoot: {
			paddingTop: 0,
			paddingBottom: 0,
		},
		dropdown: {
			marginBottom: theme.spacing(2),
		},
		avatar: {
			width: '100%',
			height: 'auto',
			borderRadius: theme.shape.borderRadius,
		},
		bottomBlock: {
			padding: theme.spacing(2),
			flexDirection: 'column',
			display: 'flex',
			justifyContent: 'flex-end',
		},
		scrollable: {
			'&::-webkit-scrollbar': {
				display: 'none',
			},
			'-ms-overflow-style': 'none',
			'scrollbar-width': 'none',
			overflowY: 'auto',
		},
	});
});

interface IProps {
	closeDrawer: () => void;
	hideTooltips?: boolean;
	closeOnClick?: boolean;
}
let cachedQueries: { [pathname: string]: any } = {};
export default function DrawerContent(props: IProps) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { pathname, search } = useLocation();
	const { query, setQuery } = useQuery<IGeneralQuery>();
	const isAdmin = useSelector(
		(state: RootState) => state.session.role === 'admin'
	);
	React.useEffect(() => {
		cachedQueries[pathname] = query;
		if (isAdmin) {
			dispatch(
				getWholesalers({ db: query.db, wholesalerId: query.wholesalerId })
			);
		}
	}, [dispatch, search, pathname, isAdmin]);
	const wholesalers = useSelector(
		(state: RootState) => state.wholesalerWarehouse.otherWholesalers
	);
	const session = useSelector((state: RootState) => state.session);
	const menuItems = useSelector((state: RootState) =>
		items.filter((item) => item.access.includes(state.session.role!))
	);
	const name = session.name || session.email;
	const wholesalerId = query.wholesalerId || session.wholesalerId;
	return (
		<>
			<List dense disablePadding className={classes.drawerHeader}>
				<ListItem
					component={Link}
					to={{
						pathname: '/profile',
						search: qs.stringify({
							...(query.wholesalerId && {
								wholesalerId: query.wholesalerId,
							}),
							...(query.db && { db: query.db }),
						}),
					}}
					classes={{
						root: classes.headerItemRoot,
						container: classes.headerItem,
					}}
				>
					<ListItemAvatar>
						<Avatar
							src={makeAvatarUrl(
								session.wholesalerId,
								session.customId,
								session.avatarHash
							)}
						>
							{name
								?.match(/\b(\w)/g)
								?.join('')
								.substring(0, 2)
								.toUpperCase()}
						</Avatar>
					</ListItemAvatar>
					<ListItemText primary={name} secondary={session.role} />
					<ListItemSecondaryAction>
						<IconButton edge="end" onClick={props.closeDrawer}>
							<ChevronLeftIcon />
						</IconButton>
					</ListItemSecondaryAction>
				</ListItem>
			</List>
			<Divider />
			<List className={classes.scrollable}>
				{menuItems.map((item) => (
					<Tooltip
						key={item.title}
						title={item.title}
						disableFocusListener={props.hideTooltips}
						disableHoverListener={props.hideTooltips}
						disableTouchListener={props.hideTooltips}
						placement="right"
					>
						<ListItem
							// @ts-ignore due to imperfect typing of material-ui
							component={item.href ? Link : undefined}
							selected={pathname === item.href}
							to={{
								pathname: item.href,
								search: qs.stringify({
									...cachedQueries[item.href!],
									...(query.wholesalerId && {
										wholesalerId: query.wholesalerId,
									}),
									...(query.db && { db: query.db }),
								}),
							}}
							onClick={() => {
								!!item.action && dispatch(item.action());
								!!props.closeOnClick && props.closeDrawer();
							}}
							button
						>
							<ListItemIcon>{item.icon && <item.icon />}</ListItemIcon>
							<ListItemText primary={item.title} />
						</ListItem>
					</Tooltip>
				))}
			</List>
			<Box flex={1} />
			<Divider />
			<Box className={classes.bottomBlock}>
				{session.role === 'admin' ? (
					<Box flexDirection="column" display="flex">
						<Tooltip
							title={wholesalerId || ''}
							disableFocusListener={props.hideTooltips}
							disableHoverListener={props.hideTooltips}
							disableTouchListener={props.hideTooltips}
							placement="right"
						>
							<Select
								variant="outlined"
								native
								value={wholesalerId}
								className={classes.dropdown}
								fullWidth
								margin="dense"
								onChange={(e) => {
									setQuery({
										wholesalerId: e.target.value as string,
									});
								}}
							>
								{wholesalers.map((item) => (
									<option key={item.customId + 'select'} value={item.customId}>
										{item.title}
									</option>
								))}
							</Select>
						</Tooltip>
						<Tooltip
							title={query.db || 'prod'}
							disableFocusListener={props.hideTooltips}
							disableHoverListener={props.hideTooltips}
							disableTouchListener={props.hideTooltips}
							placement="right"
						>
							<Select
								variant="outlined"
								native
								margin="dense"
								fullWidth
								value={query.db || 'prod'}
								onChange={(e) => {
									setQuery({
										db: e.target.value as string,
									});
								}}
							>
								<option value={'preview'}>Preview</option>
								<option value={'prod'}>Production</option>
							</Select>
						</Tooltip>
					</Box>
				) : (
					<Avatar
						className={classes.avatar}
						variant="square"
						src={`${config.bucketUrl}/images/${wholesalerId}/self/${wholesalerId}.jpg`}
					/>
				)}
			</Box>
		</>
	);
}
