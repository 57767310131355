import { Container, FormControlLabel, IconButton, Paper, Radio, TextField } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { AdvancedPayment } from "../../../types/objectiveTypes";
import { useState } from "react";
import { useStyles } from "./AdvancedPaymentComponent.styles";


interface PayoutAdvancedComponentProps {
    advancedPayments: AdvancedPayment[];
    onAdvancedPaymentsChange: (updatedPayments: AdvancedPayment[]) => void;
}


export const AdvancedPaymentComponent = ({
    advancedPayments,
    onAdvancedPaymentsChange,
}: PayoutAdvancedComponentProps) => {
    const classes = useStyles();
    const [isChecked, setIsChecked] = useState(false);

    const handleAddRow = () => {
        const newPayment: AdvancedPayment = { completed: 0, ofBudget: 0 };
        onAdvancedPaymentsChange([...advancedPayments, newPayment]);
    };

    const handleDeleteRow = (index: number) => {
        const updatedPayments = [...advancedPayments];
        updatedPayments.splice(index, 1);
        onAdvancedPaymentsChange(updatedPayments);
    };

    const handleInputChange = (index: number, name: keyof AdvancedPayment, value: number) => {
        value = Math.min(Math.max(value, 0), 100);
        const updatedPayments = [...advancedPayments];
        updatedPayments[index][name] = value;
        onAdvancedPaymentsChange(updatedPayments);
    };
    

    const handleRadioChange = () => {
        setIsChecked(!isChecked);
    };

    return (
        <Container className={classes.container}>
            <FormControlLabel
                control={<Radio checked={isChecked} onChange={handleRadioChange} />}
                label="Advanced"
            />
            {isChecked && (
                <Paper variant="outlined" className={classes.paper}>
                    {advancedPayments.map((payment, index) => (
                        <div key={index} className={classes.textFields}>
                            <TextField
                                label="% Completed"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={payment.completed}
                                onChange={(e) => handleInputChange(index, 'completed', parseInt(e.target.value))}
                            />
                            <TextField
                                label="% of Budget"
                                margin="dense"
                                variant="outlined"
                                type="number"
                                value={payment.ofBudget}
                                onChange={(e) => handleInputChange(index, 'ofBudget', parseInt(e.target.value))}
                            />
                            <IconButton onClick={() => handleDeleteRow(index)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    ))}
                    <IconButton onClick={handleAddRow} className={classes.addButton}>
                        <AddIcon />
                    </IconButton>
                </Paper>
            )}
        </Container>
    );
};