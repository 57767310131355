import React, { useState } from 'react';
import { makeStyles, Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText } from '@material-ui/core';
import { ObjIncProducts } from '../../usersManagement/types/commonObjIncTypes';

interface ProductsListProps {
    products: ObjIncProducts;
}

const useStyles = makeStyles((theme) => ({
    center: {
        textAlign: 'center',
    },
}));

export const ProductsList: React.FC<ProductsListProps> = ({ products }) => {
    const [open, setOpen] = useState(false);
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getFormattedKey = (key: string) => {
        switch (key) {
            case 'suppliers':
                return 'Suppliers:'
            case 'brands':
                return 'Brands:';
            case 'sizes':
                return 'Sizes:'
            case 'productTypes':
                return 'Product Types:'        
            case 'packageTypes':
                return 'Package Types:';
            default:
                return key;
        }
    };

    return (
        <div>
            <Button
                variant="outlined"
                color="primary"
                onClick={handleClickOpen}
                className={classes.center}
            >
                See All
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle className={classes.center}>All Products</DialogTitle>
                <DialogContent>
                    <List>
                        {Object.entries(products).map(([category, values]) => (
                            <div key={category}>
                                <Typography variant="h6">
                                    {getFormattedKey(category)}
                                </Typography>
                                {values.length > 0 ? (
                                    values.map((value: string, index: number) => (
                                        <ListItem key={index}>
                                            <ListItemText primary={value} />
                                        </ListItem>
                                    ))
                                ) : (
                                    <ListItem>
                                        <ListItemText primary="N/A" />
                                    </ListItem>
                                )}
                            </div>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions className={classes.center}>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
