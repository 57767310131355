import React from 'react';
import { SearchBarProps } from './selectComponentInterfaces';

export const SearchBar: React.FC<SearchBarProps> = ({
	value,
	onChange,
	height,
	placeholder,
}) => {
	return (
		<input
			type="text"
			placeholder={placeholder ?? 'Search...'}
			value={value}
			onChange={onChange}
			style={{
				padding: '15px',
				marginBottom: '10px',
				border: '1px solid #d3d3d3',
				borderRadius: '0px',
				width: '100%',
				outline: 'none',
				boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
				fontSize: '1rem',
				height: height ? height : 'auto',
			}}
		/>
	);
};
