import { create } from 'zustand';
import api from '../../../utils/api';
import { ISortDirection, objColumns } from '../utils/types';
import {
	IGeneralQuery,
	IPaginatedQuery,
	IQueryFavorite,
} from '../../../hooks/useQuery';
import snack from '../../../utils/snack';
import { Objective } from '../../usersManagement/types/objectiveTypes';
import { IBuyer, IUser } from '../../../interfaces';
import { IWholeSaler } from '../../usersManagement/types/types';
import { CustomObjIncProductFilters } from '../../usersManagement/types/commonObjIncTypes';

export interface SalesRep {
	customId: string;
	name: string;
}

export interface Accounts {
	_id: string;
	name: string;
	salesRep: SalesRep;
	customId: string;
}

interface Store {
	objectives: Objective[];
	accounts: Accounts[];
	filteredCustomers: any;
	totalPages: number;
	currentPage: number;
	secondaryCurrentPage: number;
	secondaryTotalPages: number;
	setSecondaryCurrentPage: (page: number) => void;
	loading: boolean;
	archivedTotalPages: number;
	archivedCurrentPage: number;
	archivedSecondaryCurrentPage: number;
	archivedSecondaryTotalPages: number;
	setArchivedSecondaryCurrentPage: (page: number) => void;
	loadingArchived: boolean;
	archivedObjectives: Objective[];
	favoriteObjectives: Objective[];
	accountTypes: string[];
	users: IUser[];
	products: IWholeSaler | null;
	customers: IBuyer[] | null;
	setLoading: (loading: boolean) => void;
	setLoadingArchived: (loading: boolean) => void;
	setCurrentPage: (page: number) => void;
	setArchivedCurrentPage: (page: number) => void;
	fetchObjectives: (query: IQueryFavorite, callback?: () => void) => void;
	fetchSubObjectives: (query: IGeneralQuery, objectiveId: string) => void;
	fetchArchivedObjectives: (
		query: IQueryFavorite,
		callback?: () => void
	) => void;
	fetchFavoriteObjectives: (query: IQueryFavorite) => void;
	editObjective: (query: IGeneralQuery, objective: Partial<Objective>) => any;
	editSubObjective: (
		query: IGeneralQuery,
		objective: Partial<Objective>,
		parentId: string,
		suppressSnack: boolean
	) => any;
	columns: string[];
	rows: any[];
	sortColumnName: string | undefined;
	sortDirection: ISortDirection;
	setDirection: (dir: ISortDirection) => void;
	setColumnName: (colName: string) => void;
	setRows: (rows: any[]) => void;
	hoveredId?: string | number;
	selectedId?: string | number;
	setHoveredId: (id: string | number) => void;
	setSelectedId: (id: string | number) => void;
	setOrder: (dir: ISortDirection, colName: string) => void;
	addObjective: (
		query: IGeneralQuery,
		newObjective: Omit<Objective, '_id' | 'customId'>
	) => Promise<Objective | undefined>;
	addSubObjective: (
		query: IGeneralQuery,
		newObjective: Omit<Objective, '_id' | 'customId'>,
		parentObjectiveId: string
	) => Promise<Objective>;
	deleteObjective: (query: IGeneralQuery, objectiveId: string) => void;
	deleteSubObjective: (query: IGeneralQuery, objectiveId: string, parentId: string) => Promise<Objective | undefined>;
	fetchAccountTypes: (query: IGeneralQuery) => void;
	fetchAllUsers: (query: IGeneralQuery) => void;
	fetchProducts: (query: IGeneralQuery) => void;
	fetchCustomers: (query: IGeneralQuery) => void;
	fetchCustomersPaginated: (query: IPaginatedQuery) => void;
	fetchObjectivesReportTable: (
		query: IQueryFavorite,
		callback?: () => void
	) => void;
	addAllBuyersToObjectiveWithFilters: (
		query: IGeneralQuery,
		objectiveId: string,
		filters?: {
			accountType?: string[];
			premise?: string[];
		}
	) => void;
	runQueryForObjective: (query: IGeneralQuery, objectiveId: string) => void;
}

export const objectivesStoreZustand = create<Store>((set) => ({
	objectives: [],
	accounts: [],
	totalPages: 1,
	currentPage: 1,
	secondaryCurrentPage: 1,
	secondaryTotalPages: 1,
	setSecondaryCurrentPage: (page: number) =>
		set({ secondaryCurrentPage: page }),
	loading: false,
	archivedTotalPages: 1,
	archivedCurrentPage: 1,
	archivedSecondaryCurrentPage: 1,
	archivedSecondaryTotalPages: 1,
	setArchivedSecondaryCurrentPage: (page: number) =>
		set({ archivedSecondaryCurrentPage: page }),
	loadingArchived: false,
	favoriteObjectives: [],
	archivedObjectives: [],
	accountTypes: [],
	users: [],
	products: null,
	customers: null,
	filteredCustomers: null,
	hoveredId: undefined,
	columns: objColumns,
	sortColumnName: undefined,
	sortDirection: 'asc',
	rows: [],
	setLoading: (loading: boolean) => set({ loading }),
	setLoadingArchived: (loadingArchived: boolean) => set({ loadingArchived }),
	setCurrentPage: (page: number) => set({ currentPage: page }),
	setArchivedCurrentPage: (page: number) => set({ archivedCurrentPage: page }),
	setDirection: (dir: ISortDirection) => set({ sortDirection: dir }),
	setRows: (rows: any) => set({ rows }),
	setColumnName: (colName: string) => set({ sortColumnName: colName }),
	setHoveredId: (id: string | number) => set({ hoveredId: id }),
	setSelectedId: (id: string | number) => set({ selectedId: id }),
	fetchObjectives: async (query: IQueryFavorite, callback?: () => void) => {
		set({ loading: true });
		const res = await api.fetch({
			path: '/objectives',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ objectives: res.payload, totalPages: res?.meta?.totalPages });
			if (callback) callback();
			set({ loading: false });
		} else {
			console.log('error fetching objectives');
			set({ objectives: [] });
			set({ loading: false });
		}
	},
	fetchSubObjectives: async (query: IGeneralQuery, objectiveId: string) => {
		const res = await api.fetch({
			path: `/objective/${objectiveId}/subObjectives`,
			method: 'GET',
			query,
		});

		if (res.ok === true && res.payload?.length > 0) {
			set((state) => {
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === objectiveId) {
						return {
							...objective,
							subObjectives: res.payload,
						};
					}
					return objective;
				});

				return { objectives: updatedObjectives };
			});
		} else {
			console.log(
				'error fetching subObjectives or no subObjectives found',
				res
			);
		}
	},
	fetchArchivedObjectives: async (
		query: IQueryFavorite,
		callback?: () => void
	) => {
		set({ loadingArchived: true });
		const res = await api.fetch({
			path: '/objectives/archived',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({
				archivedObjectives: res.payload,
				archivedTotalPages: res?.meta?.totalPages,
			});
			if (callback) callback();
			set({ loadingArchived: false });
		} else {
			console.log('error fetching objectives');
			set({ archivedObjectives: [] });
			set({ loadingArchived: false });
		}
	},
	fetchFavoriteObjectives: async (query: IQueryFavorite) => {
		const res = await api.fetch({
			path: '/objectives',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ favoriteObjectives: res.payload });
		} else {
			console.log('error fetching objectives');
			set({ favoriteObjectives: [] });
		}
	},
	addObjective: async (
		query: IGeneralQuery,
		newObjective: Omit<Objective, '_id' | 'customId'>
	) => {
		const res = await api.fetch({
			path: '/objective',
			method: 'POST',
			query,
			body: newObjective,
		});

		if (res.ok) {
			set((state) => ({
				objectives: [...state.objectives, res.payload],
			}));
			return res.payload;
		} else {
			console.log('error adding objective');
		}
	},
	addSubObjective: async (
		query: IGeneralQuery,
		newObjective: Omit<Objective, '_id' | 'customId'>,
		parentObjectiveId: string
	) => {
		const res = await api.fetch({
			path: '/subObjective',
			method: 'POST',
			query,
			body: { ...newObjective, parentObjectiveId },
		});

		if (res.ok) {
			set((state) => {
				// Find the parent objective and update its subObjectives array
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === parentObjectiveId) {
						return {
							...objective,
							subObjectives: [...(objective.subObjectives || []), res.payload],
						};
					}
					return objective;
				});

				return { objectives: updatedObjectives };
			});
			return res.payload as Objective;
		} else {
			console.log('error adding sub-objective', res);
			return {} as Objective;
		}
	},
	deleteObjective: async (query: IGeneralQuery, objectiveId: string) => {
		const res = await api.fetch({
			path: `/objective/${objectiveId}`,
			method: 'DELETE',
			query,
		});

		if (res.ok) {
			set((state) => ({
				objectives: [
					...state.objectives.filter((obj) => obj._id !== objectiveId),
				],
			}));
		} else {
			console.log('error deleting objective');
		}
	},
	deleteSubObjective: async (
		query: IGeneralQuery,
		objectiveId: string,
		parentId: string
	) => {
		const res = await api.fetch({
			path: `/api/v2/subObjective/${objectiveId}`,
			method: 'DELETE',
			query,
		});

		if (res.ok) {
			let updatedParentObjective = undefined;
			set((state) => {
				const updatedObjectives = state.objectives.map((objective) => {
					if (objective._id === parentId) {
						updatedParentObjective = {
							...objective,
							subObjectives: objective.subObjectives?.filter(sub => sub._id !== objectiveId),
						};
						return updatedParentObjective;
					}
					return objective;
				});
				return { objectives: updatedObjectives };
			});

			if (updatedParentObjective) {
				snack.success('Delete completed 👍');
				return updatedParentObjective;
			} else {
				throw new Error('Parent objective not found');
			}
		} else {
			snack.error('Delete failed');
		}
	},
	setOrder: (dir: ISortDirection, colName: string) => {
		set((state: Store) => {
			const updatedState: Partial<Store> = {};

			const currentRows = [...state.rows];
			updatedState.rows = currentRows.sort((a, b) => {
				const aVal = a[colName];
				const bVal = b[colName];
				if (aVal > bVal) {
					return dir === 'asc' ? -1 : 1;
				} else if (aVal < bVal) {
					return dir === 'asc' ? 1 : -1;
				} else {
					return 0;
				}
			});
			return updatedState;
		});
	},
	editObjective: async (
		query: IGeneralQuery,
		objective: Partial<Objective>
	) => {
		snack.info('Updating...');

		const res = await api.fetch({
			path: `/api/v2/objective/${objective._id}`,
			method: 'PATCH',
			query,
			body: objective,
		});

		if (res.ok) {
			snack.success('Update completed 👍');
			console.log('Update completed 👍', res.payload);
			set((state) => {
				// Find the index of the objective being updated
				const objectiveIndex = state.objectives.findIndex(
					(o) => o._id === objective._id
				);

				// Find the index of the archived objective being updated
				const archivedObjectiveIndex = state.archivedObjectives.findIndex(
					(o) => o._id === objective._id
				);

				// Preserve existing subObjectives or similar data
				const existingSubObjectives = objectiveIndex !== -1
					? state.objectives[objectiveIndex].subObjectives
					: null;
				const existingAccountsFilters = objectiveIndex !== -1
					? state.objectives[objectiveIndex].accountsFilters
					: null;

				// Assuming res.payload contains the updated objective without subObjectives
				const updatedObjective = objectiveIndex !== -1
					? {
						...state.objectives[objectiveIndex],
						...res.payload, // Merge updated objective data
						accountsFilters: existingAccountsFilters, // Keep existing accountsFilters unchanged
						subObjectives: existingSubObjectives, // Keep existing subObjectives unchanged
					}
					: res.payload;

				// Clone the existing objectives array for immutability
				const updatedObjectives = objectiveIndex !== -1
					? [...state.objectives]
					: state.objectives;
				if (objectiveIndex !== -1) {
					updatedObjectives[objectiveIndex] = updatedObjective;
				}

				const updatedArchivedObjectives = archivedObjectiveIndex !== -1
					? state.archivedObjectives.map((o) =>
						o._id === objective._id ? { ...o, ...res.payload } : o
					)
					: state.archivedObjectives;

				const newArchivedObjectives = res.payload.archived
					? [...updatedArchivedObjectives, res.payload]
					: updatedArchivedObjectives.filter((o) => o._id !== objective._id);

				const newObjectives = res.payload.archived
					? updatedObjectives.filter((o) => o._id !== objective._id)
					: updatedObjectives;

				// Return the new state
				return {
					objectives: newObjectives,
					archivedObjectives: newArchivedObjectives,
				};
			});

			return res.payload;
		} else {
			console.log('error updating objective', res);
		}
	},

	editSubObjective: async (
		query: IGeneralQuery,
		objective: Partial<Objective>,
		parentId: string,
		suppressSnack: boolean
	) => {
		if (!suppressSnack) {
			snack.info('Updating...');
		}

		console.log('editSubObjective objective', objective);
		const res = await api.fetch({
			path: `/api/v2/subObjective/${objective._id}`,
			method: 'PATCH',
			query,
			body: objective,
		});
		if (res.ok) {
			set((state) => {
				const updatedObjectives = state.objectives.map((obj) => {
					if (obj._id === parentId) {
						return {
							...obj,
							subObjectives: obj.subObjectives?.map((sub) =>
								sub._id === objective._id ? res.payload : sub
							),
						};
					} else {
						return obj;
					}
				});

				return { objectives: updatedObjectives };
			});
			if (!suppressSnack) {
				snack.success('Update completed 👍');
			}

			return res.payload;
		} else {
			console.log('error updating objective');
		}
	},
	fetchAccountTypes: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: '/distinctAccountTypes',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ accountTypes: res.payload });
		} else {
			console.log('error fetching objectives');
			set({ accountTypes: [] });
		}
	},
	fetchAllUsers: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: '/users',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ users: res.payload });
		} else {
			console.log('error fetching users');
			set({ users: [] });
		}
	},
	fetchProducts: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: '/wholesaler',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ products: res.payload });
		} else {
			console.log('error fetching users');
			set({ products: null });
		}
	},
	fetchCustomers: async (query: IGeneralQuery) => {
		const res = await api.fetch({
			path: '/buyersByAccountType',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ customers: res.payload });
		} else {
			console.log('error fetching users');
			set({ customers: null });
		}
	},
	fetchCustomersPaginated: async (query: IPaginatedQuery) => {
		const res = await api.fetch({
			path: '/buyersByAccountTypePaginated',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ filteredCustomers: res });
		} else {
			console.log('error fetching users');
			set({ filteredCustomers: null });
		}
	},
	fetchObjectivesReportTable: async (
		query: IQueryFavorite,
		callback?: () => void
	) => {
		set({ loading: true });
		const res = await api.fetch({
			path: '/api/v2/objectives',
			method: 'GET',
			query,
		});

		if (res.ok === true) {
			set({ objectives: res.payload, totalPages: res?.meta?.totalPages });
			if (callback) callback();
			set({ loading: false });
		} else {
			console.log('error fetching objectives');
			set({ objectives: [] });
			set({ loading: false });
		}
	},
	addAllBuyersToObjectiveWithFilters: async (
		query: IGeneralQuery,
		objectiveId: string,
		filters?: {
			accountType?: string[];
			premise?: string[];
		}
	) => {
		const res = await api.fetch({
			path: `/api/v2/objective/${objectiveId}/updateAccountsWithFilters`,
			method: 'POST',
			query,
			body: {
				objectiveId,
				filters,
			},
		});

		if (res.ok === true) {
			console.log('success');

			set((state) => {
				const objectiveIndex = state.objectives.findIndex(
					(o) => o._id === objectiveId
				);
				if (objectiveIndex === -1) {
					console.log('Objective not found in state', state.objectives);
					return {}; // No update to state needed
				}

				const updatedObjective = {
					...state.objectives[objectiveIndex],
					accountsFilters: filters,
				};

				// Clone the existing objectives array for immutability
				const updatedObjectives = [...state.objectives];
				updatedObjectives[objectiveIndex] = updatedObjective;

				console.log('updatedObjective', updatedObjective);
				return { objectives: updatedObjectives };
			});
			snack.success('Accounts added successfully');
		} else {
			console.log('error fetching users');
		}
	},
	runQueryForObjective: async (query: IQueryFavorite, objectiveId: string) => {
		const res = await api.fetch({
			path: `/runQueryForObjective/${objectiveId}`,
			method: 'GET',
			query,
		});

		if (res.ok) {
			snack.success('Action completed 👍');
			console.log('Action completed 👍', res.payload);
		} else {
			console.log('error');
		}
	},
}));
