import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { objectivesStoreZustand } from '../store/objectivesStore';
import { useState } from 'react';
import { Objective } from '../../usersManagement/types/objectiveTypes';
import useUsers from '../../usersManagement/hooks/useUsers';
import useReportObjectives from './useReportObjectives';
import useArchivedReportObjectives from './useArchivedReportObjectives';
import { ItemsList } from '../Item/ItemsList';
import ItemsManagementReportTable from '../Item/ItemManagementReportTable';

const useStyles = makeStyles((_) => ({
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'row',
		overflow: 'hidden',
		maxHeight: '96%',
	},
}));

export const ObjectivesManagementSummary = () => {
	const classes = useStyles();
	const { rows, columns, setRows } = objectivesStoreZustand();

	const [innerLoading, setInnerLoading] = useState(false);
	const [showArchived, setShowArchived] = useState(false);

	const {
		objectives,
		secondaryCurrentPage,
		setSecondaryCurrentPage,
		totalPages,
		loading,
	} = useReportObjectives();

	const {
		archivedObjectives,
		archivedSecondaryCurrentPage,
		setArchivedSecondaryCurrentPage,
		archivedTotalPages,
		loadingArchived,
	} = useArchivedReportObjectives();

	const { users } = useUsers();
	const [selectedObjectives, setSelectedObjectives] = useState<Objective[]>([]);

	const [shouldResetFilters, setShouldResetFilters] = useState(false);

	const resetFilters = () => {
		setShouldResetFilters(true);
	};

	const handleObjectiveSelect = (selectedObjectives: Objective[]) => {
		setInnerLoading(true);
		setSelectedObjectives(selectedObjectives);
		let currentRows: any[] = [];

		const processObjective = (objective, callback) => {
			const allObjectives = objective.subObjectives
				? [objective, ...objective.subObjectives]
				: [objective];

			allObjectives.forEach((currentObjective) => {
				if (currentObjective.users) {
					currentObjective.users.forEach((user) => {
						const currUser = users.find((u) => u.customId === user.user);
						currentRows.push({
							...user,
							userName: currUser?.name,
							route: currUser?.routeNumber.join(', '),
							userType: currUser?.role,
							objective: objective.name,
							subObjectives:
								currentObjective === objective
									? 'No Sub Objectives'
									: currentObjective.name,
							startDate: currentObjective.customPeriodStart,
							endDate: currentObjective.customPeriodEnd,
							type: currentObjective.type,
							quantity: user.totalItems,
							status: user.percentageAchieved || 0,
							//Target we need to modify for the multi
							target: currentObjective.target,
							payout: user.totalPayout,
							payoutMTD: user.totalAchieved,
							image: user.images,
							products: currentObjective.products,
							//Validation
							valueInput: currentObjective.valueInput,
							valueInputType: currentObjective.valueInputType,
							itemId: currentObjective._id,
							userId: user.user,
							isObjective:true,
						});
					});
				}
			});
			if (callback) callback();
		};
		const processObjectivesChunk = (objectives: Objective[], index = 0) => {
			if (index >= objectives.length) {
				setRows(currentRows);
				setInnerLoading(false);
				return;
			}
			processObjective(objectives[index], () => {
				setTimeout(() => processObjectivesChunk(objectives, index + 1), 0);
			});
		};
		processObjectivesChunk(selectedObjectives);
	};

	return (
		<Paper className={classes.container}>
			<ItemsList<Objective>
				itemType="Objectives"
				loading={showArchived ? loadingArchived : loading}
				items={showArchived ? archivedObjectives : objectives}
				currentPage={
					showArchived ? archivedSecondaryCurrentPage : secondaryCurrentPage
				}
				setCurrentPage={
					showArchived
						? setArchivedSecondaryCurrentPage
						: setSecondaryCurrentPage
				}
				totalPages={showArchived ? archivedTotalPages : totalPages}
				onItemSelect={handleObjectiveSelect}
				selectedItems={selectedObjectives}
				onFilterChange={resetFilters}
				showArchived={showArchived}
				setShowArchived={setShowArchived}
			/>
			<ItemsManagementReportTable
				itemType="objective"
				loading={innerLoading}
				columns={columns}
				rows={rows}
				selectedItem={selectedObjectives}
				shouldResetFilters={shouldResetFilters}
				setShouldResetFilters={setShouldResetFilters}
			/>
		</Paper>
	);
};
