import { incentivesStoreZustand } from './../../objectivesIncentives/store/incentivesStore';
import { objectivesStoreZustand } from '../../objectivesIncentives/store/objectivesStore';
import useQuery, { IGeneralQuery } from '../../../hooks/useQuery';

const useCustomAccounts = () => {
	const { query } = useQuery<IGeneralQuery>();
	const { wholesalerId } = query;

	const handleAddAccountType = async (
		itemId: string,
		filters: any,
		isObjective: boolean
	) => {
		if (isObjective) {
			return objectivesStoreZustand
				.getState()
				.addAllBuyersToObjectiveWithFilters({ wholesalerId }, itemId, filters);
		} else {
			incentivesStoreZustand
				.getState()
				.addAllBuyersToIncentiveWithFilters({ wholesalerId }, itemId, filters);
		}
	};

	return {
		handleAddAccountType,
	};
};

export default useCustomAccounts;
