import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	wrapper: {
		marginLeft: '10px',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 150,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	formControlWrapper: {
		display: 'flex',
		flexDirection: 'row',
	},
	selectedButton: {
		backgroundColor: '#d3d3d3',
		'&:hover': {
			backgroundColor: '#bcbcbc',
		},
	},
	secondSelectWrapper: {
		display: 'flex',
		flexDirection: 'column',
		cursor: 'pointer',
		height: '200px',
		overflow: 'auto',
		border: '1px solid #d3d3d3',
		position: 'relative',
		width: '23vh',
	},
	secondSelect: {
		display: 'flex',
		flexDirection: 'column',
		cursor: 'pointer',
		overflow: 'auto',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		width: '80%',
		maxWidth: '800px',
		overflowY: 'auto',
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	modalButtons: {
		display: 'flex',
		justifyContent: 'space-between'
	},
	searchContainer: {
		display: 'flex',
		alignItems: 'center',
		padding: '1.5rem 0'
	},
	searchBar: {
		flexGrow: 1,
		display: 'flex',
		alignItems: 'center',
		marginTop: '10px',
	},
	selectionControls: {
		flexShrink: 0,
		display: 'flex',
		justifyContent: 'center'
	}
}));
