import React, { useState } from 'react';
import { FormControl, FormLabel, Paper } from '@material-ui/core';
import { MeasureComponent } from './MeasureComponent';
import { MeasureState } from '../../types/objectiveTypes';
import { MeasureSymbol } from '../../types/commonObjIncTypes';
import { useStyles } from './QuantityForm.styles';
import { DateRange } from 'react-date-range';
import moment from 'moment-timezone';

interface QuantityFormProps {
	measures: MeasureState[];
	handleMeasureChange: (
		label: string,
		checked: boolean,
		startDate?: string,
		endDate?: string,
		trackingPeriodStart?: string,
		trackingPeriodEnd?: string,
		symbol?: MeasureSymbol
	) => void;
}

export const QuantityForm: React.FC<QuantityFormProps> = ({
	measures,
	handleMeasureChange,
}) => {
	const classes = useStyles();

	const [currentMeasure, setCurrentMeasure] = useState<MeasureState | null>(
		measures.find((measure) => measure.checked) || null
	);
	const [periodDates, setPeriodDates] = useState<any[]>([
		{
			startDate: currentMeasure?.startDate
				? new Date(currentMeasure.startDate)
				: new Date(),
			endDate: currentMeasure?.endDate
				? new Date(currentMeasure.endDate)
				: new Date(),
			key: 'selection',
		},
	]);

	const [trackingPeriodDates, setTrackingPeriodDates] = useState<any[]>([
		{
			startDate: currentMeasure?.trackingPeriodStart
				? new Date(currentMeasure.trackingPeriodStart)
				: new Date(),
			endDate: currentMeasure?.trackingPeriodEnd
				? new Date(currentMeasure.trackingPeriodEnd)
				: new Date(),
			key: 'selection',
		},
	]);

	const handlePeriodChange = (item: any) => {
		const { startDate, endDate } = item.selection;

		setPeriodDates([item.selection]);

		const startInGMT = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
		const endInGMT = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');

		if (currentMeasure) {
			const updatedMeasure: MeasureState = {
				...currentMeasure,
				startDate: startInGMT,
				endDate: endInGMT,
			};

			setCurrentMeasure(updatedMeasure);

			handleMeasureChange(
				currentMeasure.label,
				currentMeasure.checked,
				startInGMT,
				endInGMT,
				currentMeasure.trackingPeriodStart,
				currentMeasure.trackingPeriodEnd,
				currentMeasure.symbol
			);
		}
	};

	const handleTrackingPeriodChange = (item: any) => {
		const { startDate, endDate } = item.selection;

		setTrackingPeriodDates([item.selection]);

		const startInGMT = moment(startDate).format('YYYY-MM-DDTHH:mm:ss');
		const endInGMT = moment(endDate).format('YYYY-MM-DDTHH:mm:ss');

		if (currentMeasure) {
			const updatedMeasure: MeasureState = {
				...currentMeasure,
				trackingPeriodStart: startInGMT,
				trackingPeriodEnd: endInGMT,
			};

			setCurrentMeasure(updatedMeasure);

			handleMeasureChange(
				currentMeasure.label,
				currentMeasure.checked,
				currentMeasure.startDate,
				currentMeasure.endDate,
				startInGMT,
				endInGMT,
				currentMeasure.symbol
			);
		}
	};



	const handleMeasureComponentChange = (
		label: string,
		checked: boolean,
		symbol?: MeasureSymbol
	) => {
		const measure = measures.find((m) => m.label === label);
		if (measure) {
			measure.checked = checked;
			measure.symbol = symbol;
			handleMeasureChange(
				label,
				checked,
				currentMeasure?.startDate,
				currentMeasure?.endDate,
				currentMeasure?.trackingPeriodStart,
				currentMeasure?.trackingPeriodEnd,
				symbol
			);
			setCurrentMeasure(measure);
		}
	};

	return (
		<>
			<FormLabel className={classes.formLabel} required>Measure: </FormLabel>
			<div className={classes.container}>
				<FormControl component="fieldset" className={classes.formControl}>
					<Paper className={classes.measureContainer}>
						{measures.map((measure) => (
							<MeasureComponent
								key={measure.label}
								label={measure.label}
								checked={measure.checked}
								symbol={measure.symbol}
								onMeasureChange={handleMeasureComponentChange}
							/>
						))}
					</Paper>
				</FormControl>
				{currentMeasure?.label?.includes('Δ') && (
					<div className={classes.calendars}>
						<div>
							<FormLabel component="legend">Reference Period</FormLabel>
							<DateRange
								editableDateInputs={true}
								onChange={(item) => {
									handlePeriodChange(item);
								}}
								moveRangeOnFirstSelection={false}
								ranges={periodDates}
							/>
						</div>
						<div >
							<FormLabel component="legend">Tracking Period</FormLabel>
							<DateRange
								editableDateInputs={true}
								onChange={(item) => {
									handleTrackingPeriodChange(item);
								}}
								moveRangeOnFirstSelection={false}
								ranges={trackingPeriodDates}
							/>
						</div>
					</div>
				)}
			</div>
		</>
	);
};
