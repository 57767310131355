import React from "react";
import useQuery, { IProductsQuery } from "../../../../hooks/useQuery";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { getWholesaler } from "../../../../warehouse/wholesalerWarehouse";
import flatten from "lodash/flatten";
import uniq from "lodash/uniq"
import debounce from "lodash/debounce";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { MultiSelect, SingleSelect } from "../../../select/Select";
import SpacingBox from "../../../spacingBox/SpacingBoxView";
import { gpRanks, trends, volumeRanks } from "../../../../interfaces";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: 206,
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(),
  },
  inputWrapper: {
    minWidth: 185,
  },
  button: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

export default function ManualProductsControls() {
  const { query, setQuery } = useQuery<IProductsQuery>();
  const classes = useStyles();
  const filters = useSelector((state: RootState) => {
    let result = {
      brands: [],
      packageTypes: [],
      premises: [],
      sizes: [],
      suppliers: [],
      tags: [],
    };
    if (!state.wholesalerWarehouse.details) {
      return result;
    }
    const {
      productSuppliers,
      productPackageTypes,
      productPremises,
      productSizes,
      productTags,
    } = state.wholesalerWarehouse.details;
    return {
      brands: uniq(flatten(productSuppliers.map((s) => s.brands))),
      packageTypes: productPackageTypes,
      premises: productPremises,
      sizes: productSizes,
      suppliers: productSuppliers.map((s) => s.title),
      tags: productTags,
    };
  });
  const onSearch = debounce(
    (event) => setQuery({ searchPhrase: event.target.value?.toLowerCase() }),
    500
  );
  const dispatch = useDispatch();
  const { wholesalerId, db } = query;
  React.useEffect(() => {
    dispatch(getWholesaler({ wholesalerId, db }));
  }, [dispatch, wholesalerId, db]);
  const onSelect = (key: string) => (val: string[] | string | undefined) =>
    setQuery({ [key]: val });
  const isAdmin = useSelector(
    (state: RootState) => state.session.role === "admin"
  );
  return (
    <Box className={classes.container}>
      <TextField
        className={classes.inputWrapper}
        label="Search by title"
        size="small"
        variant="outlined"
        defaultValue={query.searchPhrase}
        onChange={onSearch}
      />
      <SpacingBox />
      <MultiSelect
        label="Include Brand Families"
        options={filters.brands}
        selected={query.brand}
        onSelect={onSelect("brand")}
      />
      <SpacingBox />
      <MultiSelect
        label="Exclude Brand Families"
        options={filters.brands}
        selected={query.noBrand}
        onSelect={onSelect("noBrand")}
      />
      <SpacingBox />
      <MultiSelect
        label="GP Rank"
        options={gpRanks}
        selected={query.gpRank}
        onSelect={onSelect("gpRank")}
      />
      <SpacingBox />
      <SingleSelect
        label="Package Type"
        options={filters.packageTypes}
        defaultValue={query.packageType}
        onSelect={onSelect("packageType")}
      />
      <SpacingBox />
      <SingleSelect
        label="Premise"
        options={filters.premises}
        defaultValue={query.premise}
        onSelect={onSelect("premise")}
      />
      <SpacingBox />
      <MultiSelect
        label="Size"
        options={filters.sizes}
        selected={query.size}
        onSelect={onSelect("size")}
      />
      <SpacingBox />
      <MultiSelect
        label="Include Supplier Families"
        options={filters.suppliers}
        selected={query.supplier}
        onSelect={onSelect("supplier")}
      />
      <SpacingBox />
      <MultiSelect
        label="Exclude Supplier Families"
        options={filters.suppliers}
        selected={query.noSupplier}
        onSelect={onSelect("noSupplier")}
      />
      <SpacingBox />
      <MultiSelect
        label="Tags"
        options={filters.tags}
        selected={query.tags}
        onSelect={onSelect("tags")}
      />
      <SpacingBox />
      <SingleSelect
        label="Trend"
        options={trends}
        defaultValue={query.trend}
        onSelect={onSelect("trend")}
      />
      <SpacingBox />
      <MultiSelect
        label="Volume Rank"
        options={volumeRanks}
        selected={query.volumeRank}
        onSelect={onSelect("volumeRank")}
      />
      <SpacingBox />
      {isAdmin && (
        <>
          <SingleSelect
            label="Sponsored"
            options={[
              { label: "Yes", key: "true" },
              { label: "No", key: "false" },
            ]}
            defaultValue={query.sponsored}
            onSelect={onSelect("sponsored")}
          />
          <SpacingBox />
          <SingleSelect
            label="Excluded"
            options={[
              { label: "Yes", key: "true" },
              { label: "No", key: "false" },
            ]}
            defaultValue={query.excluded}
            onSelect={onSelect("excluded")}
          />
          <SpacingBox />
        </>
      )}
      <Button
        className={classes.button}
        onClick={() =>
          setQuery({
            searchPhrase: undefined,
            brand: undefined,
            noBrand: undefined,
            packageType: undefined,
            premise: undefined,
            size: undefined,
            supplier: undefined,
            noSupplier: undefined,
            tags: undefined,
            trend: undefined,
            selectedProducts: undefined,
            page: undefined,
            sponsored: undefined,
            excluded: undefined,
            volumeRank: undefined,
            gpRank: undefined,
          })
        }
      >
        Reset
      </Button>
    </Box>
  );
}
