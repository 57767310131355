import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((_) => ({
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		overflow: 'hidden',
	},
	dialogContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '55vw',
		height: '80vh',
		overflow: 'auto',
	},
	listWrapper: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	itemList: {
		flex: 1,
		maxWidth: '50%',
		fontSize: 9,
		marginBottom: '1rem',
	},
	editModal: {
		flex: 1,
	},
	buttonContainer: {},
	button: {
		marginBottom: '1rem',
	},
	buttonAndSearchContainer: {
		display: 'flex',
		width: '50%',
		gap: '1vw',
		marginTop: '0.3rem',
		marginBottom: '0.3rem',
	},
	searchContainer: {},
}));
