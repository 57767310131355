import React, { useState } from 'react';
import { Button } from '@mui/material';

interface UserGroupSelectProps {
	onGroupSelected: (group: string) => void;
}

export const UserGroupSelect: React.FC<UserGroupSelectProps> = ({
	onGroupSelected,
}) => {
	const userGroups = ['Leadership', 'TeamLead', 'SalesRep'];
	const [selectedOption, setSelectedOption] = useState<string>('');

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				cursor: 'pointer',
				height: '200px',
				overflow: 'auto',
				border: '1px solid #d3d3d3',
			}}
		>
			{userGroups.map((group) => (
				<Button
					key={group}
					style={{
						justifyContent: 'flex-start',
						backgroundColor: selectedOption === group ? '#d3d3d3' : '',
						color: 'black',
						textTransform: 'none',
						borderRadius: '0',
					}}
					onClick={() => {
						onGroupSelected(group);
						setSelectedOption(group);
					}}
				>
					{group}
				</Button>
			))}
		</div>
	);
};
