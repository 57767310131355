import { useEffect, useState } from "react";
import useQuery, { IGeneralQuery } from "../../../hooks/useQuery";
import { incentivesStoreZustand } from "../../objectivesIncentives/store/incentivesStore";

const useArchivedIncentives = () => {
    const { query } = useQuery<IGeneralQuery>();

	const queryWholesalerId = query.wholesalerId;

	const [wholesalerId, setWholesalerId] = useState(queryWholesalerId);
	const [pageSize, setPageSize] = useState(5);

    const loadingArchived = incentivesStoreZustand((state) => state.loadingArchived);
    const archivedCurrentPage = incentivesStoreZustand((state) => state.archivedCurrentPage);
	const setArchivedCurrentPage = incentivesStoreZustand(	
		(state) => state.setArchivedCurrentPage
	);
    const archivedTotalPages = incentivesStoreZustand((state) => state.archivedTotalPages);

    const archivedIncentives = incentivesStoreZustand(
		(state) => state.archivedIncentives
	);

    useEffect(() => {
		if (queryWholesalerId && queryWholesalerId !== wholesalerId) {
			setWholesalerId(queryWholesalerId);
		}
	}, [queryWholesalerId, wholesalerId]);

    useEffect(() => {
		incentivesStoreZustand.getState().fetchArchivedIncentives(
			{ wholesalerId, page: archivedCurrentPage, limit: pageSize }
		);
	}, [wholesalerId, archivedCurrentPage, pageSize]);

    return {
		archivedIncentives,
		archivedCurrentPage,
		loadingArchived,
		archivedTotalPages,
		setArchivedCurrentPage,
		setPageSize,
	};
}

export default useArchivedIncentives;