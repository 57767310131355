import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import FilesView from '../files/FilesView';
import NotFoundView from '../notFound/NotFoundView';
import ExecutiveReportView from '../executiveReport/ExecutiveReportView';
import SuppliersView from '../suppliers/SuppliersView';
import BrandsView from '../suppliers/BrandsView';
import SalesRepsView from '../users/SalesRepsView';
import TeamLeads from '../users/TeamLeads';
import Leadership from '../users/Leadership';
import ProductsView from '../products/ProductsView';
import Buyers from '../buyers/Buyers';
import Analytics from '../analytics/AnalyticsView';
import WholesalerView from '../wholesaler/WholesalerView';
import DiffView from '../diff/DiffView';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import useQuery, { IGeneralQuery } from '../../hooks/useQuery';
import qs from 'qs';
import ProfileView from '../profile/ProfileView';
import UserManagementView from '../usersManagement/UserManagementView';
import { ObjectivesManagementSummary } from '../objectivesIncentives/objectives/ObjectivesManagementSummary';
import { IncentivesManagementSummary } from '../objectivesIncentives/incentives/IncentivesManagementSummary';

function App() {
	const { query } = useQuery<IGeneralQuery>();
	const isAdmin = useSelector(
		(state: RootState) => state.session.role === 'admin'
	);
	return (
		<Switch>
			<Route exact path="/">
				<Redirect
					to={
						isAdmin
							? {
									pathname: '/wholesaler',
									search: qs.stringify({
										wholesalerId: query.wholesalerId,
										db: query.db,
									}),
							  }
							: '/files'
					}
				/>
			</Route>
			<Route exact path="/files" component={FilesView} />
			<Route exact path="/profile" component={ProfileView} />
			<Route exact path="/archive" component={FilesView} />
			<Route exact path="/executiveReport" component={ExecutiveReportView} />
			<Route exact path="/suppliers" component={SuppliersView} />
			<Route exact path="/brands" component={BrandsView} />
			<Route exact path="/userManagement" component={UserManagementView} />
			<Route exact path="/objectives" component={ObjectivesManagementSummary} />
			<Route exact path="/incentives" component={IncentivesManagementSummary} />
			<Route exact path="/salesreps" component={SalesRepsView} />
			<Route exact path="/teamleads" component={TeamLeads} />
			<Route exact path="/leadership" component={Leadership} />
			<Route exact path="/products" component={ProductsView} />
			<Route exact path="/customers" component={Buyers} />
			<Route exact path="/analytics" component={Analytics} />
			<Route exact path="/wholesaler" component={WholesalerView} />
			<Route exact path="/diff" component={DiffView} />
			<Route path="*">
				<NotFoundView />
			</Route>
		</Switch>
	);
}

export default App;
