import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	TableCell,
	TableRow,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import StarIcon from '@material-ui/icons/Star';
import ArchiveIcon from '@mui/icons-material/Archive';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import { capitalizeFirstLetter } from '../../utils';
import { Item } from '../../types/commonObjIncTypes';
import { formatDate } from '../../../objectivesIncentives/utils/utils';
import { useStyles } from './ItemsList.styles';

interface ItemDisplayComponentProps {
	item: Item;
	showDeleteButton?: boolean;
	onDelete: (id: string) => void;
	onEdit: (id: string) => void;
	onDuplicate?: () => void;
	onFavorite?: (id: string) => void;
	onArchive: (id: string) => void;
	showActionQueryButton?:boolean;
	onAction: (id: string) => void;
}

export const ItemDisplayComponent: React.FC<ItemDisplayComponentProps> = ({
	item,
	showDeleteButton = true,
	onDelete,
	onEdit,
	onDuplicate,
	onFavorite,
	onArchive,
	showActionQueryButton,
	onAction,
}) => {
	const [isFavorite, setIsFavorite] = useState(item?.favorite || false);
	const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
	const [actionDialog, setActionDialog] = useState(false);
	const [archive, setArchive] = useState(false);
	const classes = useStyles();

	const toggleFavorite = () => {
		setIsFavorite(!isFavorite);
		if (item && item._id !== undefined && onFavorite) {
			onFavorite(item?._id);
		}
	};

	const handleAction = (action: 'open' | 'close' | 'confirm' | 'confirmArchive' | 'action'| 'openAction') => {
		switch (action) {
			case 'open':
				setDeleteConfirmationOpen(true);
				break;
			case 'close':
				setDeleteConfirmationOpen(false);
				setArchive(false);
				setActionDialog(false);
				break;
			case 'confirm':
				onDelete(item._id);
				setDeleteConfirmationOpen(false);
				break;
			case 'confirmArchive':
				onArchive(item._id);
				setDeleteConfirmationOpen(false);
				setArchive(false);
				break;
			case 'openAction':
				setActionDialog(true);
				break;
			case 'action':
				onAction(item._id);
				setActionDialog(false);
				break;
			default:
				break;
		}
	};

	const labelArchived:string= item?.archived ? 'Restore':'Archive';

	if (!item) {
		return null;
	}

	return (
		<TableRow className={classes.customRow}>
			<TableCell className={`${classes.customRow} ${classes.customRowName}`}>
				{capitalizeFirstLetter(item.name)}
			</TableCell>
			<TableCell className={classes.customRow}>
				{capitalizeFirstLetter(item.type)}
			</TableCell>
			<TableCell className={`${classes.customRow} ${classes.customRowPeriod}`}>
				{`${formatDate(item?.customPeriodStart || '')} -
					${formatDate(item?.customPeriodEnd || '')}`}
			</TableCell>
			<TableCell className={`${classes.customRow} ${classes.actionsButtons}`}>
				<IconButton
					size="small"
					color="primary"
					onClick={() => onEdit(item._id)}
				>
					<EditIcon />
				</IconButton>
				<IconButton size="small" color="primary" onClick={onDuplicate}>
					<FileCopyIcon />
				</IconButton>
				{showDeleteButton && (
					<IconButton size="small" onClick={() => handleAction('open')}>
						<DeleteIcon />
					</IconButton>
				)}
				<IconButton size="small" onClick={toggleFavorite}>
					{isFavorite ? (
						<StarIcon style={{ color: '#F0C146' }} />
					) : (
						<StarBorderIcon />
					)}
				</IconButton>
				<IconButton size='small' color='primary' onClick={() => {setArchive(true);handleAction('open')}}>
					<ArchiveIcon />
				</IconButton>
				{showActionQueryButton && (
					<IconButton size="small" color='primary' onClick={() => handleAction('openAction')}>
						<PlayCircleFilledIcon />
					</IconButton>
				)}
				<Dialog
					open={deleteConfirmationOpen|| actionDialog}
					onClose={() => handleAction('close')}
					aria-labelledby="form-dialog-title"
				>
					<DialogContent>
						<DialogTitle>{archive ? `Confirm ${labelArchived}` : actionDialog ? 'Confirm Action' : 'Confirm Delete'}</DialogTitle>
						<DialogContent>
							<DialogContentText>
								{`Are you sure you want to ${archive ? labelArchived : actionDialog ? 'execute' : 'delete'} ${item.name}?`}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={() => handleAction('close')}>Cancel</Button>
							<Button onClick={() => archive ? handleAction('confirmArchive') : actionDialog ? handleAction('action') : handleAction('confirm')} color="primary">
								{archive ? labelArchived : actionDialog ? 'Execute' : 'Delete'}
							</Button>
						</DialogActions>
					</DialogContent>
				</Dialog>
			</TableCell>
		</TableRow>
	);
};
