import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	quantityContainer: {
		marginTop: '1rem',
	},
	formControl: {
		display: 'flex',
	},
	measureContainer: {
		height: '200px',
		overflowY: 'auto',
		marginBottom: '20px',
		alignSelf: 'left',
		width: '46%',
		minWidth: '20.688rem',
		padding: '1rem',
		backgroundColor: theme.palette.background.paper,
	},
	radioGroup: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	textField: {
		width: '70px',
		marginRight: theme.spacing(1),
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '60px',
		maxWidth: '45rem'
	},
	calendars: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '20px',
		flexWrap: 'wrap',
		gap: '1.5rem',
	},
	formLabel: {
		marginTop: '1rem',
		marginBottom: '1rem',
	}
}));
